import React from 'react';
import * as icons from 'react-icons/fa';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import './Section_3.css';

const TermsOfService = (props) => {
  const history = useHistory();

  return (
    <div className='terms_page'>
      <icons.FaArrowLeft
        onClick={() =>
          props.isPage ? history.push('/') : props.handle_terms()
        }
        style={{
          position: 'fixed',
          left: '30px',
          top: '30px',
          fontSize: 30,
          zIndex: 3,
        }}
      ></icons.FaArrowLeft>
      <div className='term_text'>
        {' '}
        <h1>תקנון האתר</h1>
        <ol>
          <li>
            <h2>כללי</h2>
            <ol>
              <li>
                האתר MOMENTS (moments-events.net) (להלן: "האתר") מופעל על ידי
                WEB.BY (להלן: "החברה").
              </li>
              <li>
                השימוש באתר כפוף לתנאים המפורטים בתקנון זה. הגלישה והשימוש באתר
                מהווים הסכמה מלאה לכל התנאים המפורטים להלן.
              </li>
              <li>אם אינך מסכים לתנאי התקנון, נא הימנע משימוש באתר.</li>
            </ol>
          </li>
          <li>
            <h2>הגדרות</h2>
            <ol>
              <li>"משתמש" – כל אדם הגולש באתר או משתמש בשירותיו.</li>
              <li>
                "שירותים" – השירותים הניתנים על ידי האתר, כולל "אירוע" ו-"גלריית
                תמונות שיתופית".
              </li>
            </ol>
          </li>
          <li>
            <h2>הגנת פרטיות</h2>
            <ol>
              <li>
                החברה מתחייבת לשמור על פרטיות המשתמשים ולא להעביר את פרטיהם לצד
                שלישי למעט לצורך תפעול האתר ומתן השירותים.
              </li>
              <li>
                החברה אוספת פרטי משתמשים (הבוחרים להירשם לאתר) הכוללים שם ומייל
                לצורך זיהוי והתחברות למערכת.
              </li>
              <li>
                המשתמש מסכים לכך שהחברה תשתמש במידע שנמסר על ידו לצורך שיפור
                השירותים באתר.
              </li>
            </ol>
          </li>
          <li>
            <h2>שימוש בנתוני משתמשי Google</h2>
            <ol>
              <li>
                האתר אוסף מידע אישי, כולל מידע הנוגע לחשבונות Google שלהם, אך
                ורק לצורך שיפור חווית המשתמש ולתפעול תקין של האתר.
              </li>
              <li>
                אנו משתמשים במידע האישי שנאסף לצורך מתן שירותים מותאמים אישית,
                עיבוד תשלומים, ושיפור מתמיד של האתר.
              </li>
              <li>
                המידע לא יועבר לצדדים שלישיים ללא הסכמת המשתמש, למעט מקרים של
                תקלות טכניות או צורך חוקי.
              </li>
            </ol>
          </li>
          <li>
            <h2>שימוש בעוגיות</h2>
            <ol>
              <li>
                האתר יכול לעשות שימוש בעוגיות לצורך תפעול תקין ושיפור חווית
                המשתמש.
              </li>
              <li>המשתמש מסכים לשימוש בעוגיות כפי שמפורט במדיניות הפרטיות.</li>
            </ol>
          </li>
          <li>
            <h2>תנאי תשלום</h2>
            <ol>
              <li>
                התשלום עבור השירותים באתר יתבצע באמצעות כרטיס אשראי דרך פלטפורמת
                PayPal המאובטחת.
              </li>
              <li>המשתמש מתחייב לספק פרטי תשלום נכונים ומדויקים.</li>
              <li>
                החברה אינה אחראית לכל תקלה או בעיה שתתרחש במהלך התשלום, לרבות
                במקרה של שימוש לא מורשה בכרטיס האשראי.
              </li>
            </ol>
          </li>
          <li>
            <h2>מדיניות החזרים וביטולים</h2>
            <ol>
              <li>
                במקרה של תוכן דיגיטלי שנמסר ללקוח באופן מיידי לאחר התשלום, לא
                תתאפשר ביטול העסקה.{' '}
              </li>
              <li>
                הלקוח מאשר כי ידוע לו שקבלת התוכן הדיגיטלי מהווה את סיום העסקה
                ואין אפשרות לבטלה לאחר מכן.
              </li>
              <li>
                במקרה של רכש מוצר פיזי (כגון פוסטר), הלקוח רשאי לבטל את ההזמנה
                בתוך 14 ימים ממועד הזמנת השירות ובתנאי שלא נעשה שימוש כלל בשירות
                או לא התחיל תהליך ייצורו (הדפסת הפוסטר).
              </li>
              <li>
                במקרה של ביטול (בהתאם לסעיף 3) בתוך 14 ימים, החברה רשאית לגבות
                דמי ביטול בסך 5% ממחיר השירות או 100 ש"ח, הנמוך מביניהם.
              </li>
              <li>החזר כספי יינתן בתוך 14 ימים ממועד קבלת הודעת הביטול.</li>
            </ol>
          </li>
          <li>
            <h2>שירות לקוחות</h2>
            <ol>
              <li>
                שירות הלקוחות של האתר ניתן באמצעות דואר אלקטרוני בלבד:
                moments.events.cs@gmail.com
              </li>
              <li>
                החברה תשתדל להשיב לפניות בהקדם האפשרי, אך אינה מתחייבת לזמן
                תגובה מסוים.
              </li>
              <li>
                לביצוע ביטול עסקה ולקבלת החזר כספי יש לפנות לשירות הלקוחות במייל
                (moments.events.cs@gmail.com), יש לציין את פרטי ההזמנה וסיבת
                הביטול.
              </li>
            </ol>
          </li>
          <li>
            <h2>העלאת תמונות ושימוש בגלריה</h2>
            <ol>
              <li>
                המשתמשים רשאים להעלות תמונות לגלריה של האירוע, בכפוף לכך
                שהתמונות אינן מפרות זכויות יוצרים של צד שלישי.
              </li>
              <li>
                המשתמש מתחייב שכל תמונה שהועלתה לאתר היא בבעלותו המלאה או שהוא
                בעל רישיון מתאים לשימוש בה.
              </li>
              <li>
                המשתמש מסכים כי העלאת תמונות המפרות זכויות יוצרים אסורה, והוא
                לוקח על עצמו את האחריות המשפטית במקרה של הפרה.
              </li>
              <li>
                במקרה של קבלת דיווח על הפרת זכויות יוצרים, החברה רשאית להסיר את
                התמונה באופן מיידי וללא התראה מוקדמת.
              </li>
              <li>
                למשתמשים ניתנת האפשרות לדווח על תמונות שלדעתם מפרות זכויות
                יוצרים באמצעות פנייה לשירות הלקוחות בדואר אלקטרוני:
                moments.events.cs@gmail.com
              </li>
            </ol>
          </li>
          <li>
            <h2>קניין רוחני</h2>
            <ol>
              <li>
                כל התכנים באתר, לרבות טקסטים, תמונות, גרפיקה וסרטונים, הינם רכוש
                החברה או רכוש צדדים שלישיים שהעניקו לחברה רישיון להשתמש בהם.
              </li>
              <li>
                אין להעתיק, להפיץ, לשדר, לפרסם, או להשתמש בתכנים אלה ללא אישור
                מראש ובכתב מהחברה.
              </li>
            </ol>
          </li>
          <li>
            <h2>אחריות מוגבלת</h2>
            <ol>
              <li>
                החברה אינה אחראית לכל נזק ישיר או עקיף שנגרם למשתמש או לצד שלישי
                כתוצאה משימוש באתר או מהסתמכות על המידע המופיע בו.
              </li>
              <li>
                החברה אינה מתחייבת שהשירותים באתר יהיו זמינים בכל עת ולא תישא
                באחריות למקרים של הפסקות או תקלות.
              </li>
              <li>
                החברה אינה תישא באחריות לתקלות, אי זמינות והפסקות הגישה לאתר
                במידה ואלו נגרמו בעקבות תקלה של קבלני משנה או של תשתיות בהם
                החברה עושה שימוש (לדוגמא – FIREBASE מבית GOOGLE).
              </li>
            </ol>
          </li>
          <li>
            <h2>שינויים בתקנון</h2>
            <ol>
              <li>
                החברה שומרת לעצמה את הזכות לשנות את התקנון מעת לעת. על המשתמשים
                לעיין בתקנון באופן תקופתי.
              </li>
              <li>השינויים ייכנסו לתוקף מרגע פרסומם באתר.</li>
            </ol>
          </li>
          <li>
            <h2>דין וסמכות שיפוט</h2>
            <ol>
              <li>על תקנון זה יחולו דיני מדינת ישראל בלבד.</li>
              <li>
                כל סכסוך הנוגע לתקנון זה או לשימוש באתר יידון בבתי המשפט
                המוסמכים במחוז תל אביב בלבד.
              </li>
            </ol>
          </li>
          <li>
            <h2>תאריך עדכון אחרון</h2>
            <ol>
              <li>התקנון עודכן לאחרונה בתאריך 25.10.2024.</li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default TermsOfService;
