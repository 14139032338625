import React, { useEffect, useRef } from "react";
import "./Section_4.css";

import Card from "../Section_1/Card";
import img1 from "../../../images/img1.jpg";
import img2 from "../../../images/img2.jpg";
import img3 from "../../../images/img3.jpg";
import Scene from "../../Phone_card/Scene";
import { useHistory } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
const Section_4 = (props) => {
  const aref = useRef();
  const history = useHistory();
  useEffect(() => {
    if (window.innerWidth >= 900) {
      AOS.init({
        duration: 1000, // Duration of the animations in milliseconds
      });
    }
  }, []);
  return (
    <div className="section_1" ref={aref}>
      <div className="section_1_home_page">
        {window.innerWidth < 900 ? (
          <header className="section_1_header">
            <span className="section_1_header_span">למה אתם מחכים?</span>
            <span className="section_1_header_span">צרו אירוע</span>
          </header>
        ) : (
          <header className="section_1_header">
            <span className="section_1_header_span" data-aos="fade-left">
              למה אתם מחכים?
            </span>
            <span
              className="section_1_header_span"
              data-aos-delay="400"
              data-aos="fade-left"
            >
              צרו אירוע
            </span>
          </header>
        )}
        <main className="section_1_main">
          {" "}
          <span className="section_main_span">
            חוויה מיוחדת לאורחים, אלבום מלא רגעים עבורכם
          </span>
          <span className="section_main_span">
            הפלטפורמה שלנו מציעה פתרון פשוט, נוח ומשתלם בעלות של פחות מ90 אגורות
            לאורח
          </span>
          <span className="section_main_span">
            {" "}
            אז למה אתם מחכים? צרו אירוע והפכו אותו לבלתי נשכח כבר היום
          </span>
        </main>
      </div>
    </div>
  );
};

export default Section_4;
