import React, { useEffect, useRef, useState } from 'react';
import './Home_page.css';
import Section_1 from './Section_1/Section_1';
import Box from './Section_1/Box';
import { Canvas } from '@react-three/fiber';
import Card from './Section_1/Card';
import img3 from '../../images/img3.jpg';
import Section_2 from './Sectios_2/Section_2';
import Section_3 from './Section_3/Section_3';
import Section_4 from './Section_4/Section_4';
import phonee_bg from '../../images/phone_gallery.jpg';
import event_phone from '../../images/event_phone.jpg';
import gallery_passover from '../../images/gallery_passover.png';
import gallery_birthday from '../../images/gallery_birthday.png';

import wait from '../../images/wait.jpg';
import scan_camera from '../../images/scan_camera.PNG';
import scan_qr from '../../images/phone_scan_qr.PNG';
import capture from '../../images/PS_photo.png';
import event_phone_img from '../../images/event_phone_img.png';
import gallery_phone from '../../images/gallery_phone_img.png';
import all_events from '../../images/all_events.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import BackGround from './BackGround';
import Nav_bar from '../Nav_bar/Nav_bar';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  Link,
  Element,
  Events,
  scroller,
  animateScroll as scroll,
  scrollSpy,
} from 'react-scroll';
import poster from '../../images/scan_me_poster.png';
import {
  motion,
  useTransform,
  transform,
  useInView,
  useAnimation,
  useScroll,
} from 'framer-motion';
import { delay } from 'framer-motion';
import Scene from '../Phone_card/Scene';
import Scene_animated from '../Phone_animated/Scene_animated';
import Left_bar from '../Nav_bar/Left_bar';
import Logo_page from '../Logo_page/Logo_page';
import TimerComponent from '../TimerComponent/TimerComponent';
const Home_page = (props) => {
  const ref = useRef();
  const history = useHistory();
  const [section_index, set_section_index] = useState(1);
  const [old_section, set_old_section] = useState(1);
  const [isScrolling, setIsScrolling] = useState(false);
  const section_1_ref = useRef();
  const section_3_ref = useRef();
  const section_4_ref = useRef();
  const canvasRef = useRef();
  const isInView_1 = useInView(section_1_ref, { once: false });
  const isInView_3 = useInView(section_3_ref, { once: false });
  const isInView_4 = useInView(section_4_ref, { once: false });

  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();
  // const [showTimer, setTimer] = useState(localStorage.getItem('seeDis') === null)
  useEffect(() => {
    const handleAnimation = async (
      controls,
      isInView,
      sectionIndex,
      variants1,
      variants2
    ) => {
      if (isInView && section_index === sectionIndex) {
        await controls.start(variants1);
        await controls.start(variants2);
      } else {
        await controls.start('hidden1');
        await controls.start('hidden2');
      }
    };

    handleAnimation(controls1, isInView_1, 1, 'visible1', 'visible2');
    handleAnimation(controls2, isInView_3, 3, 'visible1', 'visible2');
    handleAnimation(controls3, isInView_4, 4, 'visible1', 'visible2');
  }, [
    isInView_1,
    isInView_3,
    isInView_4,
    section_index,
    controls1,
    controls2,
    controls3,
  ]);

  const section_1_animation_phone_1 = {
    hidden1: {
      left: '5%',
      transform: 'rotateZ(0deg) scale(0.3)',
      top: '5%',
      opacity: 0,
      transition: { duration: 0.3 },
    },
    hidden2: {
      opacity: 0,
      left: '5%',
      transform: 'rotateZ(0deg) scale(0)',
      // top: "50%",
      transition: { duration: 0.3 },
    },
    visible1: {
      top: '5%',
      transform: 'rotateZ(0deg) scale(0.25)',

      transition: { delay: 0.3, duration: 0.9 },
    },
    visible2: {
      opacity: 1,
      left: '-17%',
      top: '8.5%',

      transform: 'rotateZ(-13deg) scale(0.25)',
      transition: { delay: 0.3, duration: 0.5 },
    },
  };
  const section_1_animation_phone_2 = {
    hidden1: {
      opacity: 1,
      left: '5%',
      transform: 'rotateZ(0deg) scale(0.5)',
      top: '5%',
      transition: { duration: 0.3 },
    },
    hidden2: {
      opacity: 0,

      left: '5%',
      transform: 'rotateZ(0deg) scale(0)',
      // top: "50%",
      transition: { duration: 0.3 },
    },
    visible1: {
      opacity: 1,
      top: '5%',
      transform: 'rotateZ(0deg) scale(0.35)',

      transition: { delay: 0.3, duration: 0.9 },
    },
    visible2: {
      opacity: 1,
      top: '5%',
      left: '5%',
      transform: 'rotateZ(0deg) scale(0.35)',
      transition: { delay: 0.3, duration: 0.5 },
    },
  };
  const section_1_animation_phone_3 = {
    hidden1: {
      opacity: 0,
      left: '5%',
      transform: 'rotateZ(0deg) scale(0.25)',
      top: '5%',
      transition: { duration: 0.3 },
    },
    hidden2: {
      opacity: 0,
      left: '5%',
      transform: 'rotateZ(0deg) scale(0)',
      // top: "50%",
      transition: { duration: 0.3 },
    },
    visible1: {
      top: '5%',
      transform: 'rotateZ(0deg) scale(0.25)',

      transition: { delay: 0.3, duration: 0.9 },
    },
    visible2: {
      opacity: 1,

      left: '27%',
      top: '8.5%',

      transform: 'rotateZ(13deg) scale(0.25)',
      transition: { delay: 0.3, duration: 0.5 },
    },
  };
  const section_1_animation_phone_main = {
    hidden1: {
      opacity: 1,
      left: '5%',
      transform: 'rotateZ(0deg) scale(0.5)',
      top: '5%',
      transition: { duration: 0.3 },
    },
    hidden2: {
      opacity: 1,

      left: '5%',
      transform: 'rotateZ(0deg) scale(0)',
      // top: "50%",
      transition: { duration: 0.3 },
    },
    visible1: {
      opacity: 1,
      top: '5%',
      transform: 'rotateZ(0deg) scale(0.35)',

      transition: { delay: 0, duration: 0.6 },
    },
    visible2: {
      opacity: 1,
      top: '5%',
      left: '5%',
      transform: 'rotateZ(0deg) scale(0.35)',
      transition: { delay: 0, duration: 0.5 },
    },
  };
  const section_2_animation_phone_1 = {
    hidden1: {
      opacity: 0,
      left: '5%',
      transform: 'rotateZ(0deg) scale(0.25)',
      top: '5%',
      transition: { duration: 0.3 },
    },
    hidden2: {
      opacity: 0,
      left: '5%',
      transform: 'rotateZ(0deg) scale(0)',
      // top: "50%",
      transition: { duration: 0.3 },
    },
    visible1: {
      top: '5%',
      transform: 'rotateZ(0deg) scale(0.25)',

      transition: { delay: 0.1, duration: 0.9 },
    },
    visible2: {
      opacity: 1,
      left: '-30%',
      top: '6.5%',

      transform: 'rotateZ(-12deg) scale(0.25)',
      transition: { delay: 0.1, duration: 0.5 },
    },
  };

  const section_2_animation_phone_2 = {
    hidden1: {
      opacity: 0,
      left: '5%',
      transform: 'rotateZ(0deg) scale(0.1)',
      top: '5%',
      transition: { duration: 0.3 },
    },
    hidden2: {
      opacity: 0,
      left: '5%',
      transform: 'rotateZ(0deg) scale(0)',
      // top: "50%",
      transition: { duration: 0.3 },
    },
    visible1: {
      top: '0%',
      transform: 'rotateZ(0deg) scale(0.1)',

      transition: { delay: 0.1, duration: 0.9 },
    },
    visible2: {
      opacity: 1,
      left: '40%',
      top: '-2%',

      transform: 'rotateZ(12deg) scale(0.1)',
      transition: { delay: 0.1, duration: 0.5 },
    },
  };
  const section_2_animation_phone_3 = {
    hidden1: {
      opacity: 0,
      left: '5%',
      transform: 'rotateZ(0deg) scale(0.17)',
      top: '5%',
      transition: { duration: 0.3 },
    },
    hidden2: {
      opacity: 0,
      left: '5%',
      transform: 'rotateZ(0deg) scale(0)',
      // top: "50%",
      transition: { duration: 0.3 },
    },
    visible1: {
      top: '0%',
      transform: 'rotateZ(0deg) scale(0.17)',

      transition: { delay: 0.1, duration: 0.9 },
    },
    visible2: {
      opacity: 1,
      left: '40%',
      top: '20%',

      transform: 'rotateZ(-30deg) scale(0.17)',
      transition: { delay: 0.1, duration: 0.5 },
    },
  };
  const handle_personal_page = () => {
    history.push({ pathname: '/Personal_page' });
  };
  const [is_small_screen, set_is_small_screen] = useState(
    window.innerWidth < 900
  );
  useEffect(() => {
    if (is_small_screen) {
      setTimeout(() => {
        scroller.scrollTo(`section_${1}`, {
          duration: 400,
          smooth: true,
          offset: -100, // Adjust if you have a fixed header
        });
      }, 600);
      scroller.scrollTo(`section_${3}`, {
        duration: 400,
        smooth: true,
        offset: -100, // Adjust if you have a fixed header
      });
    }
    {
      // Disable default scrolling
      document.body.style.overflow = 'hidden';
      let touchStartY = 0;
      let touchEndY = 0;
      let isTouchScrolling = false;
      let shouldHandle = true;
      let lastScrollY = null;
      let lastScrollTimer = 0;
      function waitForScrollEnd(cb) {
        clearTimeout(lastScrollTimer);
        lastScrollY = window.scrollY;
        lastScrollTimer = setTimeout(poll, 50);

        function poll() {
          if (lastScrollY === window.scrollY) {
            lastScrollY = null;
            lastScrollTimer = 0; // This is entirely optional but makes it parallel with the `else` below
            cb();
          } else {
            lastScrollY = window.scrollY;
            lastScrollTimer = setTimeout(poll, 50);
          }
        }
      }
      const handleWheel = (event) => {
        if (shouldHandle) {
          handleScroll(event.deltaY);
          shouldHandle = false;
          waitForScrollEnd(() => {
            setTimeout(() => {
              shouldHandle = true;
            }, 200); // Or perhaps 700 on the basis that up to 50 was spent in `waitForScrollToEnd`
          });
        }
      };
      const handleTouchStart = (event) => {
        touchStartY = event.touches[0].clientY;
      };
      const handleTouchMove = (event) => {
        touchEndY = event.touches[0].clientY;
        if (!isTouchScrolling) {
          const deltaY = touchStartY - touchEndY;
          handleScroll(deltaY);
          isTouchScrolling = true;
        }
      };
      const handleTouchEnd = () => {
        isTouchScrolling = false; // Reset the scrolling flag after touch ends
      };
      const handleScroll = (deltaY) => {
        setIsScrolling((prevIsScrolling) => {
          if (prevIsScrolling) {
            return true;
          } else {
            set_section_index((prevSectionIndex) => {
              set_old_section(prevSectionIndex);
              let newSectionIndex = prevSectionIndex;
              if (deltaY > 0) {
                newSectionIndex = prevSectionIndex + 1;
              } else if (deltaY < -0) {
                newSectionIndex = prevSectionIndex - 1;
              }
              newSectionIndex = Math.max(1, newSectionIndex);
              newSectionIndex = Math.min(4, newSectionIndex);
              // Scroll to the new section
              scroller.scrollTo(`section_${newSectionIndex}`, {
                duration: is_small_screen ? 1000 : 1300,
                smooth: true,
                offset: -50, // Adjust if you have a fixed header
                delay: is_small_screen ? 750 : 0,
              });

              return newSectionIndex;
            });
            return true;
          }
        });
        setTimeout(() => {
          setIsScrolling(false);
          isTouchScrolling = false;
        }, 1500);
      };
      window.addEventListener('wheel', handleWheel);
      window.addEventListener('touchstart', handleTouchStart);
      window.addEventListener('touchmove', handleTouchMove, { passive: false }); // Add passive: false to prevent default behavior
      window.addEventListener('touchend', handleTouchEnd);
      // Cleandown function to reset the overflow property and remove the event listeners
      return () => {
        document.body.style.overflow = '';
        window.removeEventListener('wheel', handleWheel);
        window.removeEventListener('touchstart', handleTouchStart);
        window.removeEventListener('touchmove', handleTouchMove);
        window.removeEventListener('touchend', handleTouchEnd);
      };
    }
  }, []);
  return (
    <div className='home_page' ref={ref}>
      <Logo_page />
      <BackGround />
      <Nav_bar
        handle_personal_page={handle_personal_page}
        section_index={section_index}
        set_section_index={set_section_index}
        handle_terms={() => history.push('/Terms')}
      />
      <Left_bar
        handle_personal_page={handle_personal_page}
        section_index={section_index}
        set_section_index={set_section_index}
      />

      <main>
        <section className='home_page_section_container'>
          <Element name='section_1'>
            <div className='home_page_section_div'>
              <Section_1
                old_section={old_section}
                handle_add_event={() => history.push('/Add_event')}
              />
            </div>
          </Element>
          {is_small_screen ? (
            <div
              className='small_screen_home_page_section_div'
              ref={section_1_ref}
            >
              <motion.div
                initial='hidden2'
                animate={controls1}
                variants={section_1_animation_phone_1}
                className='section_1_event_phone_img'
              >
                {' '}
                <LazyLoadImage
                  src={gallery_phone}
                  alt='Gallery Phone'
                  effect='blur'
                  className='section_1_event_phone_image'
                ></LazyLoadImage>
              </motion.div>
            </div>
          ) : (
            ''
          )}
          {is_small_screen ? (
            <div className='small_screen_home_page_section_div'>
              <motion.div
                initial='hidden2'
                animate={controls1}
                variants={section_1_animation_phone_3}
                className='section_1_event_phone_img'
              >
                {' '}
                <LazyLoadImage
                  src={gallery_phone}
                  alt='Gallery Phone'
                  effect='blur'
                  className='section_1_event_phone_image'
                ></LazyLoadImage>
              </motion.div>
            </div>
          ) : (
            ''
          )}
          {is_small_screen ? (
            <div className='small_screen_home_page_section_div'>
              <motion.div
                initial='hidden2'
                animate={controls1}
                variants={section_1_animation_phone_2}
                className='section_1_event_phone_img'
              >
                {' '}
                <LazyLoadImage
                  src={event_phone_img}
                  alt='Gallery Phone'
                  effect='blur'
                  className='section_1_event_phone_image'
                ></LazyLoadImage>
              </motion.div>
            </div>
          ) : (
            ''
          )}
        </section>
        <section className='home_page_section_container'>
          <Element name='section_2'>
            {' '}
            <div
              ref={canvasRef}
              className='home_page_section_div'
              style={{ zIndex: '0' }}
            >
              <div className='section_1_container'>
                {' '}
                <Section_2
                  handle_add_event={() => history.push('/Add_event')}
                ></Section_2>
              </div>{' '}
            </div>{' '}
          </Element>
        </section>{' '}
        <section className='home_page_section_container'>
          <Element name='section_3'>
            <div className='home_page_section_div'>
              <Section_3 handle_add_event={() => history.push('/Add_event')} />
            </div>
          </Element>
          {is_small_screen ? (
            <div
              className='small_screen_home_page_section_div'
              ref={section_3_ref}
            >
              <motion.div
                initial='hidden2'
                animate={controls2}
                variants={section_2_animation_phone_2}
                className='section_1_gallery_phone_img'
              >
                {' '}
                <LazyLoadImage
                  src={gallery_phone}
                  alt='Gallery Phone'
                  effect='blur'
                  className='section_1_event_phone_image'
                ></LazyLoadImage>
              </motion.div>
            </div>
          ) : (
            ''
          )}
          {is_small_screen ? (
            <div className='small_screen_home_page_section_div'>
              <motion.div
                initial='hidden2'
                animate={controls2}
                variants={section_2_animation_phone_3}
                className='section_1_gallery_phone_img'
              >
                {' '}
                <LazyLoadImage
                  src={gallery_birthday}
                  alt='Gallery Phone'
                  effect='blur'
                  className='section_1_event_phone_image'
                ></LazyLoadImage>
              </motion.div>
            </div>
          ) : (
            ''
          )}
          {is_small_screen ? (
            <div className='small_screen_home_page_section_div'>
              <motion.div
                initial='hidden2'
                animate={controls2}
                variants={section_2_animation_phone_1}
                className='section_1_gallery_phone_img'
              >
                {' '}
                <LazyLoadImage
                  src={gallery_passover}
                  alt='Gallery Phone'
                  effect='blur'
                  className='section_1_event_phone_image'
                ></LazyLoadImage>
              </motion.div>
            </div>
          ) : (
            ''
          )}
          {is_small_screen ? (
            <div className='small_screen_home_page_section_div'>
              <motion.div
                initial='hidden2'
                animate={controls2}
                variants={section_1_animation_phone_main}
                className='section_1_gallery_phone_img'
              >
                {' '}
                <LazyLoadImage
                  src={all_events}
                  alt='Gallery Phone'
                  effect='blur'
                  className='section_1_event_phone_image'
                ></LazyLoadImage>
              </motion.div>
            </div>
          ) : (
            ''
          )}
        </section>
        <section className='home_page_section_container section_4_small'>
          <Element name='section_4'>
            <div className='home_page_section_div'>
              <Section_4 handle_add_event={() => history.push('/Add_event')} />
            </div>
          </Element>
          {is_small_screen ? (
            <div
              className='small_screen_home_page_section_div'
              ref={section_4_ref}
            >
              <motion.div
                initial='hidden2'
                animate={controls3}
                variants={section_1_animation_phone_1}
                className='section_1_event_phone_img'
              >
                {' '}
                <LazyLoadImage
                  src={gallery_phone}
                  alt='Gallery Phone'
                  effect='blur'
                  className='section_1_event_phone_image'
                ></LazyLoadImage>
              </motion.div>
            </div>
          ) : (
            ''
          )}
          {is_small_screen ? (
            <div className='small_screen_home_page_section_div'>
              <motion.div
                initial='hidden2'
                animate={controls3}
                variants={section_1_animation_phone_3}
                className='section_1_event_phone_img'
              >
                {' '}
                <LazyLoadImage
                  src={gallery_phone}
                  alt='Gallery Phone'
                  effect='blur'
                  className='section_1_event_phone_image'
                ></LazyLoadImage>
              </motion.div>
            </div>
          ) : (
            ''
          )}
          {is_small_screen ? (
            <div className='small_screen_home_page_section_div'>
              <motion.div
                initial='hidden2'
                animate={controls3}
                variants={section_1_animation_phone_2}
                className='section_1_event_phone_img'
              >
                {' '}
                <LazyLoadImage
                  src={event_phone_img}
                  alt='Gallery Phone'
                  effect='blur'
                  className='section_1_event_phone_image'
                ></LazyLoadImage>
              </motion.div>
            </div>
          ) : (
            ''
          )}
        </section>
      </main>

      <div className='card_3'>
        {/* <Card
          aref={props.aref}
          position={[-20.5, 5, -50]}
          rotation={[0, 0, 0]}
          url={img3}
          index={2}
          unenable_controls={true}
        ></Card> */}
        <Scene
          is_small_screen={is_small_screen}
          url1={scan_camera}
          url2={scan_qr}
          url3={wait}
          url4={event_phone}
          url5={capture}
          url6={phonee_bg}
          unenable_controls={true}
          aref={ref}
          // how_it_works={props.how_it_works}
          // old_section={props.old_section}
          canvasRef={canvasRef}
        ></Scene>
      </div>
      <footer className='section_1_footer'>
        {' '}
        <button
          className='section_1_create_event_button'
          onClick={() => history.push('/Add_event')}
        >
          <span>צרו אירוע</span>{' '}
        </button>
      </footer>
      {/* {showTimer&& < TimerComponent handleCloseDisc={() => {
              setTimer(false);
              localStorage.setItem('seeDis','true')
          } }></TimerComponent>} */}
    </div>
  );
};

export default Home_page;
