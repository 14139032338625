import React, { createContext, useState, useContext } from 'react';
import { upload_profile_image } from './firebase';

const UploadContext = createContext();

export const useUpload = () => useContext(UploadContext);

export const UploadProvider = ({ children }) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const uploadImage = async (
    image,
    path_name,
    event_id,
    user_id,
    id,
    filter
  ) => {
    setUploading(true);
    try {
      const result = await upload_profile_image(
        image,
        path_name,
        event_id,
        Number(user_id),
        id.split('_')[0],
        'none'
      );
      // Handle the uploaded data or update UI as needed

      return result; // Make sure to return a value or resolve a promise
    } catch (error) {
      console.error('Upload failed', error);
      throw error; // Throw the error to handle it outside the function
    } finally {
      setUploading(false);
    }
  };
  return (
    <UploadContext.Provider value={{ uploading, progress, uploadImage }}>
      {children}
    </UploadContext.Provider>
  );
};
