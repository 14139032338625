/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 public/models/Iphone.glb -o src/Iphone.js -k -K -r public 
Author: Navarion (https://sketchfab.com/Navarion)
License: CC-BY-NC-4.0 (http://creativecommons.org/licenses/by-nc/4.0/)
Source: https://sketchfab.com/3d-models/free-iphone15-pro-max-ultra-high-quality-1fb1717d5e204302bfe9969ea77293a4
Title: FREE - IPhone15 Pro Max - Ultra High Quality
*/

import React, { useEffect, useRef, useState } from 'react';
import { useGLTF, useTexture, Html, Text, Decal } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { MeshBasicMaterial, Vector3 } from 'three';
import FontPicker from 'font-picker-react';
import font from '../../../Fonts/Montserrat-VariableFont_wght.ttf';
import * as THREE from 'three';
import { OrbitControls, useDrag } from '@react-three/drei';
import * as ci from 'react-icons/ci';
import * as pi from 'react-icons/pi';
import * as go from 'react-icons/go';
import html2canvas from 'html2canvas';
import { CanvasTexture } from 'three/src/textures/CanvasTexture';

import {
  convert_y_position,
  convert_y_position_precentage,
} from '../../../Util/helpers';
import Drag_mesh from './Drag_mesh';
export function Iphone(props) {
  const { nodes, materials } = useGLTF('/models/Iphone.glb');
  const texture = useTexture(props.url ? props.url : 'deafult.png');
  const imageAspectRatio = texture.image.width / texture.image.height;
  const meshWidth = 4;
  const meshHeight = 15.48;
  const meshAspectRatio = meshWidth / meshHeight;

  let scale;
  if (imageAspectRatio > meshAspectRatio) {
    // Cover by height, crop width
    scale = [meshHeight * imageAspectRatio, meshHeight, 1];
  } else {
    // Cover by width, crop height
    scale = [meshWidth, meshWidth / imageAspectRatio, 1];
  }
  // const imageAspectRatio = texture.image.width / texture.image.height;
  // const meshWidth = 0.81;
  // const meshHeight = 1.35;
  // const meshAspectRatio = meshWidth / meshHeight;
  // let scale;
  // if (imageAspectRatio > meshAspectRatio) {
  //   // Fit to width
  //   scale = [meshWidth, meshWidth / imageAspectRatio, 1];
  // } else {
  //   // Fit to height
  //   scale = [meshHeight * imageAspectRatio, meshHeight, 1];
  // }
  // const finalScale = scale.map((val, index) => {
  //   return index === 0 ? Math.min(val, meshWidth) : Math.min(val, meshHeight);
  // });

  const camera = useTexture('camera_icon.png');
  const gallery = useTexture('gallery_icon.png');
  const white_camera = useTexture('camera_icon_white.png');
  const white_gallery = useTexture('gallery_icon_white.png');

  const phone_ref = useRef();
  const [clicked_ref, set_clicked_ref] = useState();
  useFrame((state, delta) => {
    phone_ref.current.rotation.y = Math.sin(state.clock.elapsedTime / 6) / 8;
    phone_ref.current.position.y =
      0.4 + Math.sin(state.clock.elapsedTime * 2) / 40 - 0.5;
  });
  const [drag_ref, set_drag_ref] = useState(0);

  const [dragging, setDragging] = useState(false);
  const [offset, setOffset] = useState(new Vector3());
  const [long_click, set_long_click] = useState(false);
  const [mouseDownTime, setMouseDownTime] = useState(0); // Store mouse down timestamp
  // Maximum X position
  const minY = -0.37; // Minimum Y position
  const maxY = 0.58; // Maximum Y position
  // Function to handle mouse down event on the mesh
  function handleMouseDown(e, ref) {
    if (clicked_ref) {
      e.stopPropagation();
      props.handle_is_dragging(true);
      const clickedMesh = ref.current;
      const intersectedMesh1 = e.intersections.find(
        (intersection) => intersection.object === clickedMesh
      );
      const intersectedMesh2 = e.intersections.find(
        (intersection) => intersection.object === date_ref.current
      );

      if (
        intersectedMesh1 &&
        intersectedMesh2 &&
        intersectedMesh1.distance < intersectedMesh2.distance
      ) {
        setDragging(true);
        set_drag_ref(1);
      } else if (intersectedMesh2) {
        setDragging(true);
        set_drag_ref(2);
      } else {
        setDragging(true);
        set_drag_ref(1);
      }
    }
  }
  // Function to handle mouse move event when dragging the mesh
  const handleMouseMove = (event, ref, meshIdentifier) => {
    if (clicked_ref && dragging && meshIdentifier === drag_ref) {
      const newPosition = ref.current.position.clone();

      const deltaY = event.movementY || event.mozMovementY || 0;
      newPosition.y -= deltaY * 0.002;
      newPosition.y =
        newPosition.y < minY
          ? minY
          : newPosition.y > maxY
          ? maxY
          : newPosition.y;
      ref.current.position.copy(newPosition);
      if (ref === event_name_ref) {
        props.handle_event_data({
          event_name: {
            ...props.event_name,
            y: convert_y_position(newPosition.y),
          },
        });
      } else {
        props.handle_event_data({
          date: {
            ...props.date,
            y: convert_y_position(newPosition.y),
          },
        });
      }
    }
  };

  // Function to handle mouse up event when dragging the mesh

  const event_name_ref = useRef();

  const date_ref = useRef();
  const material = new MeshBasicMaterial({
    color: props.button_color, // Specify your desired color here
    // Ensure flat shading for consistent appearance
  });
  const handleMouseUp = (e) => {
    // Your logic here for when the mouse is released anywhere on the page
    set_clicked_ref();
    if (dragging) {
      setDragging(false);
      props.handle_is_dragging(false);

      if (e.timeStamp - mouseDownTime > 100) set_long_click(true);
      else set_long_click(false);
    }
  };

  useEffect(() => {
    document.addEventListener('pointerup', handleMouseUp);
    return () => {
      document.removeEventListener('pointerup', handleMouseUp);
    };
  }, []);

  return (
    <group ref={phone_ref} {...props} dispose={null} scale={0.32}>
      <group name='Sketchfab_Scene'>
        <group name='Sketchfab_model' rotation={[-Math.PI / 2, 0, 0]}>
          <group name='Collada_visual_scene_group'>
            <group
              name='tracking_node_placeholder'
              rotation={[Math.PI / 2, 0, 0]}
            >
              <group name='oKjEgurgmnxkEdd'>
                <group name='fGJOGsieiMwuBMw'>
                  <group name='OcZfGJIDFXowYWx'>
                    <group name='LDSssWkPQxdZmBk'>
                      <group name='KPOVPRNZJrOjkcc'>
                        <group name='rFVycrpNmWtVMan'>
                          <mesh
                            name='EtFgaXCoUdBMdxZ_001-material'
                            geometry={
                              nodes['EtFgaXCoUdBMdxZ_001-material'].geometry
                            }
                            material={materials['EtFgaXCoUdBMdxZ.001']}
                          />
                        </group>
                        <group name='UjlWsrHFyUsMdxc'>
                          <group name='KCXoFnmboVjtnON'>
                            <group name='AagXTQXDnEhpnpS'>
                              <mesh
                                name='yfSRCfyMalEYgbO_001-material'
                                geometry={
                                  nodes['yfSRCfyMalEYgbO_001-material'].geometry
                                }
                                material={materials['yfSRCfyMalEYgbO.001']}
                              />
                            </group>
                            <group name='clwieXphOAJaTuR'>
                              <mesh
                                name='yfSRCfyMalEYgbO_001-material_1'
                                geometry={
                                  nodes['yfSRCfyMalEYgbO_001-material_1']
                                    .geometry
                                }
                                material={materials['yfSRCfyMalEYgbO.001']}
                              />
                            </group>
                            <group name='EsFMFxTtThybjMC'>
                              <mesh
                                name='aNKJdeKOByfZlDS_001-material'
                                geometry={
                                  nodes['aNKJdeKOByfZlDS_001-material'].geometry
                                }
                                material={materials['aNKJdeKOByfZlDS.001']}
                              />
                            </group>
                            <group name='EvBJWGDiNRJzboZ'>
                              <mesh
                                name='cxAXXzdnHJhRByK_001-material'
                                geometry={
                                  nodes['cxAXXzdnHJhRByK_001-material'].geometry
                                }
                                material={materials['cxAXXzdnHJhRByK.001']}
                              />
                            </group>
                            <group name='ixccjDvZoPDsMHA'>
                              <mesh
                                name='aDINyfsuELQulbd_001-material'
                                geometry={
                                  nodes['aDINyfsuELQulbd_001-material'].geometry
                                }
                                material={materials['aDINyfsuELQulbd.001']}
                              />
                            </group>
                            <group name='MOqYtwGgqvrTJFp'>
                              <mesh
                                name='EtFgaXCoUdBMdxZ_001-material_2'
                                geometry={
                                  nodes['EtFgaXCoUdBMdxZ_001-material_2']
                                    .geometry
                                }
                                material={materials['EtFgaXCoUdBMdxZ.001']}
                              />
                            </group>
                            <group name='oPxXulmznlLTeNo'>
                              <mesh
                                name='AxufFAzeZPglisV_001-material'
                                geometry={
                                  nodes['AxufFAzeZPglisV_001-material'].geometry
                                }
                                material={materials['AxufFAzeZPglisV.001']}
                              />
                            </group>
                            <group name='xvXwpvhQyzkwxns'>
                              <mesh
                                name='DkAqxRJaVVfbpgF_001-material'
                                geometry={
                                  nodes['DkAqxRJaVVfbpgF_001-material'].geometry
                                }
                                material={materials['DkAqxRJaVVfbpgF.001']}
                              />
                            </group>
                          </group>
                          <group name='LOVrIeiTzEHrAGH'>
                            <group name='DjZpMMcSUjyZkAu'>
                              <group name='AXowfgzbvDNVFYM'>
                                <mesh
                                  name='yfSRCfyMalEYgbO_001-material_2'
                                  geometry={
                                    nodes['yfSRCfyMalEYgbO_001-material_2']
                                      .geometry
                                  }
                                  material={materials['yfSRCfyMalEYgbO.001']}
                                />
                              </group>
                              <group name='FglrxJgiEDhbpuH'>
                                <mesh
                                  name='EtFgaXCoUdBMdxZ_001-material_9'
                                  geometry={
                                    nodes['EtFgaXCoUdBMdxZ_001-material_9']
                                      .geometry
                                  }
                                  material={materials['EtFgaXCoUdBMdxZ.001']}
                                />
                              </group>
                              <group name='gYupOnmjlHjDoTJ'>
                                <mesh
                                  name='yfSRCfyMalEYgbO_001-material_3'
                                  geometry={
                                    nodes['yfSRCfyMalEYgbO_001-material_3']
                                      .geometry
                                  }
                                  material={materials['yfSRCfyMalEYgbO.001']}
                                />
                              </group>
                              <group name='JlYZPCJbTesYgyR'>
                                <mesh
                                  name='EWuRzSMoXPhEpsh_001-material_1'
                                  geometry={
                                    nodes['EWuRzSMoXPhEpsh_001-material_1']
                                      .geometry
                                  }
                                  material={materials['EWuRzSMoXPhEpsh.001']}
                                />
                              </group>
                              <group name='JpDjVXmqukNTHyv'>
                                <mesh
                                  name='LDbhHgKqQwnfIdk_001-material_2'
                                  geometry={
                                    nodes['LDbhHgKqQwnfIdk_001-material_2']
                                      .geometry
                                  }
                                  material={materials['LDbhHgKqQwnfIdk.001']}
                                />
                              </group>
                              <group name='noqGTXCNMBRuAUL'>
                                <mesh
                                  name='NOlmOyvNOoXnkpD_001-material'
                                  geometry={
                                    nodes['NOlmOyvNOoXnkpD_001-material']
                                      .geometry
                                  }
                                  material={materials['NOlmOyvNOoXnkpD.001']}
                                />
                              </group>
                              <group name='QfLYzDizFfKhXes'>
                                <mesh
                                  name='LuFFEBDynGcfeVH_001-material'
                                  geometry={
                                    nodes['LuFFEBDynGcfeVH_001-material']
                                      .geometry
                                  }
                                  material={materials['LuFFEBDynGcfeVH.001']}
                                />
                              </group>
                              <group name='RtqkwruGdFohMoo'>
                                <mesh
                                  name='qYATvTCUqTRIxZo_001-material'
                                  geometry={
                                    nodes['qYATvTCUqTRIxZo_001-material']
                                      .geometry
                                  }
                                  material={materials['qYATvTCUqTRIxZo.001']}
                                />
                              </group>
                              <group name='wYCdDUbYvvUHUAE'>
                                <mesh
                                  name='saRNhuLSrFYuedf_001-material_1'
                                  geometry={
                                    nodes['saRNhuLSrFYuedf_001-material_1']
                                      .geometry
                                  }
                                  material={materials['saRNhuLSrFYuedf.001']}
                                />
                              </group>
                              <group name='yEiaGQsYPUfQGJu'>
                                <mesh
                                  name='xEINmDJHJtyYSZS_001-material_5'
                                  geometry={
                                    nodes['xEINmDJHJtyYSZS_001-material_5']
                                      .geometry
                                  }
                                  material={materials['xEINmDJHJtyYSZS.001']}
                                />
                              </group>
                              <group name='ZpgCReFVOvUDJpp'>
                                <mesh
                                  name='EtFgaXCoUdBMdxZ_001-material_10'
                                  geometry={
                                    nodes['EtFgaXCoUdBMdxZ_001-material_10']
                                      .geometry
                                  }
                                  material={materials['EtFgaXCoUdBMdxZ.001']}
                                />
                              </group>
                              <group name='ZQVtsPAFxDebNuL'>
                                <mesh
                                  name='LDbhHgKqQwnfIdk_001-material_3'
                                  geometry={
                                    nodes['LDbhHgKqQwnfIdk_001-material_3']
                                      .geometry
                                  }
                                  material={materials['LDbhHgKqQwnfIdk.001']}
                                />
                              </group>
                              <group name='zYZWzUEKpjcTrvk'>
                                <mesh
                                  name='EtFgaXCoUdBMdxZ_001-material_11'
                                  geometry={
                                    nodes['EtFgaXCoUdBMdxZ_001-material_11']
                                      .geometry
                                  }
                                  material={materials['EtFgaXCoUdBMdxZ.001']}
                                />
                              </group>
                            </group>
                            <group name='ivnjPtTfPfpvSbU'>
                              <group name='DnWAzaucFGrthpo'>
                                <mesh
                                  name='EtFgaXCoUdBMdxZ_001-material_12'
                                  geometry={
                                    nodes['EtFgaXCoUdBMdxZ_001-material_12']
                                      .geometry
                                  }
                                  material={materials['EtFgaXCoUdBMdxZ.001']}
                                />
                              </group>
                              <group name='dPLwWSxdcciyxmu'>
                                <mesh
                                  name='LuFFEBDynGcfeVH_001-material_1'
                                  geometry={
                                    nodes['LuFFEBDynGcfeVH_001-material_1']
                                      .geometry
                                  }
                                  material={materials['LuFFEBDynGcfeVH.001']}
                                />
                              </group>
                              <group name='elUnqBQrWytNdpf'>
                                <mesh
                                  name='LDbhHgKqQwnfIdk_001-material_4'
                                  geometry={
                                    nodes['LDbhHgKqQwnfIdk_001-material_4']
                                      .geometry
                                  }
                                  material={materials['LDbhHgKqQwnfIdk.001']}
                                />
                              </group>
                              <group name='iGYybdIjmErDUHC'>
                                <mesh
                                  name='EtFgaXCoUdBMdxZ_001-material_13'
                                  geometry={
                                    nodes['EtFgaXCoUdBMdxZ_001-material_13']
                                      .geometry
                                  }
                                  material={materials['EtFgaXCoUdBMdxZ.001']}
                                />
                              </group>
                              <group name='jGNQPlLRydSeyDk'>
                                <mesh
                                  name='LDbhHgKqQwnfIdk_001-material_5'
                                  geometry={
                                    nodes['LDbhHgKqQwnfIdk_001-material_5']
                                      .geometry
                                  }
                                  material={materials['LDbhHgKqQwnfIdk.001']}
                                />
                              </group>
                              <group name='kZpwowZNNgVNwLs'>
                                <mesh
                                  name='saRNhuLSrFYuedf_001-material_2'
                                  geometry={
                                    nodes['saRNhuLSrFYuedf_001-material_2']
                                      .geometry
                                  }
                                  material={materials['saRNhuLSrFYuedf.001']}
                                />
                              </group>
                              <group name='LcIinDmJMSOJcei'>
                                <mesh
                                  name='yfSRCfyMalEYgbO_001-material_4'
                                  geometry={
                                    nodes['yfSRCfyMalEYgbO_001-material_4']
                                      .geometry
                                  }
                                  material={materials['yfSRCfyMalEYgbO.001']}
                                />
                              </group>
                              <group name='pxczByPYPsLpISN'>
                                <mesh
                                  name='NOlmOyvNOoXnkpD_001-material_1'
                                  geometry={
                                    nodes['NOlmOyvNOoXnkpD_001-material_1']
                                      .geometry
                                  }
                                  material={materials['NOlmOyvNOoXnkpD.001']}
                                />
                              </group>
                              <group name='qgwQFTuOVZpjKqj'>
                                <mesh
                                  name='qYATvTCUqTRIxZo_001-material_1'
                                  geometry={
                                    nodes['qYATvTCUqTRIxZo_001-material_1']
                                      .geometry
                                  }
                                  material={materials['qYATvTCUqTRIxZo.001']}
                                />
                              </group>
                              <group name='QugujJfRokGBRvt'>
                                <mesh
                                  name='EWuRzSMoXPhEpsh_001-material_2'
                                  geometry={
                                    nodes['EWuRzSMoXPhEpsh_001-material_2']
                                      .geometry
                                  }
                                  material={materials['EWuRzSMoXPhEpsh.001']}
                                />
                              </group>
                              <group name='TwpJxeyQaZKhOIa'>
                                <mesh
                                  name='EtFgaXCoUdBMdxZ_001-material_14'
                                  geometry={
                                    nodes['EtFgaXCoUdBMdxZ_001-material_14']
                                      .geometry
                                  }
                                  material={materials['EtFgaXCoUdBMdxZ.001']}
                                />
                              </group>
                              <group name='UWAOtrCfzfMyJTC'>
                                <mesh
                                  name='xEINmDJHJtyYSZS_001-material_6'
                                  geometry={
                                    nodes['xEINmDJHJtyYSZS_001-material_6']
                                      .geometry
                                  }
                                  material={materials['xEINmDJHJtyYSZS.001']}
                                />
                              </group>
                              <group name='UWpcpqDYmPREwxB'>
                                <mesh
                                  name='yfSRCfyMalEYgbO_001-material_5'
                                  geometry={
                                    nodes['yfSRCfyMalEYgbO_001-material_5']
                                      .geometry
                                  }
                                  material={materials['yfSRCfyMalEYgbO.001']}
                                />
                              </group>
                            </group>
                            <group name='KsjIyrtgusmiEbo'>
                              <group name='KomCstxUReEAMAm'>
                                <mesh
                                  name='EtFgaXCoUdBMdxZ_001-material_15'
                                  geometry={
                                    nodes['EtFgaXCoUdBMdxZ_001-material_15']
                                      .geometry
                                  }
                                  material={materials['EtFgaXCoUdBMdxZ.001']}
                                />
                              </group>
                              <group name='MgYYCjCFEiRJHSx'>
                                <mesh
                                  name='PqdtqVEhAPzytes_001-material'
                                  geometry={
                                    nodes['PqdtqVEhAPzytes_001-material']
                                      .geometry
                                  }
                                  material={materials['PqdtqVEhAPzytes.001']}
                                />
                              </group>
                              <group name='WaKvOomqveGscfs'>
                                <mesh
                                  name='PqdtqVEhAPzytes_001-material_1'
                                  geometry={
                                    nodes['PqdtqVEhAPzytes_001-material_1']
                                      .geometry
                                  }
                                  material={materials['PqdtqVEhAPzytes.001']}
                                />
                              </group>
                            </group>
                            <group name='nSAJWaALmxJwOmE'>
                              <group name='bzLRygCDgcXaWuO'>
                                <mesh
                                  name='xwVmDXUVpcqUaxI_001-material'
                                  geometry={
                                    nodes['xwVmDXUVpcqUaxI_001-material']
                                      .geometry
                                  }
                                  material={materials['xwVmDXUVpcqUaxI.001']}
                                />
                              </group>
                              <group name='gGILXremswJnRzY'>
                                <mesh
                                  name='GvMXDHTSaTFmkSJ_001-material'
                                  geometry={
                                    nodes['GvMXDHTSaTFmkSJ_001-material']
                                      .geometry
                                  }
                                  material={materials['GvMXDHTSaTFmkSJ.001']}
                                />
                              </group>
                              <group name='ToqTqpNmMWOflVY'>
                                <mesh
                                  name='AwYbcqVpoWkGbAQ_001-material'
                                  geometry={
                                    nodes['AwYbcqVpoWkGbAQ_001-material']
                                      .geometry
                                  }
                                  material={materials['AwYbcqVpoWkGbAQ.001']}
                                />
                              </group>
                              <group name='XeLxZaPZvbWszxX'>
                                <mesh
                                  name='AwYbcqVpoWkGbAQ_001-material_1'
                                  geometry={
                                    nodes['AwYbcqVpoWkGbAQ_001-material_1']
                                      .geometry
                                  }
                                  material={materials['AwYbcqVpoWkGbAQ.001']}
                                />
                              </group>
                            </group>
                            <group name='onwlnwuBbkBjvcz'>
                              <group name='BtfNAzIMbxXMtnh'>
                                <mesh
                                  name='LDbhHgKqQwnfIdk_001-material_6'
                                  geometry={
                                    nodes['LDbhHgKqQwnfIdk_001-material_6']
                                      .geometry
                                  }
                                  material={materials['LDbhHgKqQwnfIdk.001']}
                                />
                              </group>
                              <group name='fQMDkHMpWDgvzKr'>
                                <mesh
                                  name='yfSRCfyMalEYgbO_001-material_6'
                                  geometry={
                                    nodes['yfSRCfyMalEYgbO_001-material_6']
                                      .geometry
                                  }
                                  material={materials['yfSRCfyMalEYgbO.001']}
                                />
                              </group>
                              <group name='hOUqKLAruTGUTLT'>
                                <mesh
                                  name='EtFgaXCoUdBMdxZ_001-material_16'
                                  geometry={
                                    nodes['EtFgaXCoUdBMdxZ_001-material_16']
                                      .geometry
                                  }
                                  material={materials['EtFgaXCoUdBMdxZ.001']}
                                />
                              </group>
                              <group name='KHNtgbfgcggVeiY'>
                                <mesh
                                  name='yfSRCfyMalEYgbO_001-material_7'
                                  geometry={
                                    nodes['yfSRCfyMalEYgbO_001-material_7']
                                      .geometry
                                  }
                                  material={materials['yfSRCfyMalEYgbO.001']}
                                />
                              </group>
                              <group name='KvXDfxXjuJBvVSm'>
                                <mesh
                                  name='LuFFEBDynGcfeVH_001-material_2'
                                  geometry={
                                    nodes['LuFFEBDynGcfeVH_001-material_2']
                                      .geometry
                                  }
                                  material={materials['LuFFEBDynGcfeVH.001']}
                                />
                              </group>
                              <group name='LRlFPIQzvNsOSMo'>
                                <mesh
                                  name='xEINmDJHJtyYSZS_001-material_7'
                                  geometry={
                                    nodes['xEINmDJHJtyYSZS_001-material_7']
                                      .geometry
                                  }
                                  material={materials['xEINmDJHJtyYSZS.001']}
                                />
                              </group>
                              <group name='mdmRieQUrrrzrBR'>
                                <mesh
                                  name='qYATvTCUqTRIxZo_001-material_2'
                                  geometry={
                                    nodes['qYATvTCUqTRIxZo_001-material_2']
                                      .geometry
                                  }
                                  material={materials['qYATvTCUqTRIxZo.001']}
                                />
                              </group>
                              <group name='ohSmopyQnlZcApi'>
                                <mesh
                                  name='EtFgaXCoUdBMdxZ_001-material_17'
                                  geometry={
                                    nodes['EtFgaXCoUdBMdxZ_001-material_17']
                                      .geometry
                                  }
                                  material={materials['EtFgaXCoUdBMdxZ.001']}
                                />
                              </group>
                              <group name='OyfjdyuTDqNRFgA'>
                                <mesh
                                  name='LDbhHgKqQwnfIdk_001-material_7'
                                  geometry={
                                    nodes['LDbhHgKqQwnfIdk_001-material_7']
                                      .geometry
                                  }
                                  material={materials['LDbhHgKqQwnfIdk.001']}
                                />
                              </group>
                              <group name='pAXmiFuNCvfvvot'>
                                <mesh
                                  name='NOlmOyvNOoXnkpD_001-material_2'
                                  geometry={
                                    nodes['NOlmOyvNOoXnkpD_001-material_2']
                                      .geometry
                                  }
                                  material={materials['NOlmOyvNOoXnkpD.001']}
                                />
                              </group>
                              <group name='pSvGltuRQTaTwru'>
                                <mesh
                                  name='EtFgaXCoUdBMdxZ_001-material_18'
                                  // geometry={
                                  //   nodes["EtFgaXCoUdBMdxZ_001-material_18"]
                                  //     .geometry
                                  // }
                                  material={materials['EtFgaXCoUdBMdxZ.001']}
                                />
                              </group>
                              <group name='RRBztvWgjOVDtio'>
                                <mesh
                                  name='saRNhuLSrFYuedf_001-material_3'
                                  geometry={
                                    nodes['saRNhuLSrFYuedf_001-material_3']
                                      .geometry
                                  }
                                  material={materials['saRNhuLSrFYuedf.001']}
                                />
                              </group>
                              <group name='UZzJivSmgyHnnoA'>
                                <mesh
                                  name='LDbhHgKqQwnfIdk_001-material_8'
                                  geometry={
                                    nodes['LDbhHgKqQwnfIdk_001-material_8']
                                      .geometry
                                  }
                                  material={materials['LDbhHgKqQwnfIdk.001']}
                                />
                              </group>
                              <group name='VMbWUIBJOeqTorB'>
                                <mesh
                                  name='NOlmOyvNOoXnkpD_001-material_3'
                                  geometry={
                                    nodes['NOlmOyvNOoXnkpD_001-material_3']
                                      .geometry
                                  }
                                  material={materials['NOlmOyvNOoXnkpD.001']}
                                />
                              </group>
                              <group name='wEroORzXEpeBMUr'>
                                <mesh
                                  name='EWuRzSMoXPhEpsh_001-material_3'
                                  geometry={
                                    nodes['EWuRzSMoXPhEpsh_001-material_3']
                                      .geometry
                                  }
                                  material={materials['EWuRzSMoXPhEpsh.001']}
                                />
                              </group>
                              <group name='ZGblmRAixwSYeaI'>
                                <mesh
                                  name='NOlmOyvNOoXnkpD_001-material_4'
                                  geometry={
                                    nodes['NOlmOyvNOoXnkpD_001-material_4']
                                      .geometry
                                  }
                                  material={materials['NOlmOyvNOoXnkpD.001']}
                                />
                              </group>
                            </group>
                          </group>
                          <group name='XJReQzSHujcYUen'>
                            <group name='nsHWlVFHOhVBScU'>
                              <mesh
                                name='DkAqxRJaVVfbpgF_001-material_1'
                                geometry={
                                  nodes['DkAqxRJaVVfbpgF_001-material_1']
                                    .geometry
                                }
                                material={materials['DkAqxRJaVVfbpgF.001']}
                              />
                            </group>
                            <group name='XmrjKHJiFgGtfgP'>
                              <mesh
                                name='ydaDdTqttaoNyqb_001-material'
                                geometry={
                                  nodes['ydaDdTqttaoNyqb_001-material'].geometry
                                }
                                material={materials['ydaDdTqttaoNyqb.001']}
                              />
                            </group>
                          </group>
                        </group>
                        <group name='vxFaaBrHaMtSazz'>
                          <group name='ckNCRqyUnZfmfqa'>
                            <mesh
                              name='ZREBupwLYjgDWEr_001-material'
                              geometry={
                                nodes['ZREBupwLYjgDWEr_001-material'].geometry
                              }
                              material={materials['ZREBupwLYjgDWEr.001']}
                            />
                          </group>
                          <group name='dyyhvtVPOrVvZrn'>
                            <mesh
                              name='McqOaNTsOxXDHPN_001-material'
                              geometry={
                                nodes['McqOaNTsOxXDHPN_001-material'].geometry
                              }
                              material={materials['McqOaNTsOxXDHPN.001']}
                            />
                          </group>
                          <group name='GjwIJnPKhwUCjPx'>
                            <mesh
                              name='EtFgaXCoUdBMdxZ_001-material_1'
                              geometry={
                                nodes['EtFgaXCoUdBMdxZ_001-material_1'].geometry
                              }
                              material={materials['EtFgaXCoUdBMdxZ.001']}
                            />
                          </group>
                          <group name='ikunlVfBKaSoRqC'>
                            <group name='AfMqeobIryTZXTq'>
                              <mesh
                                name='vVGvlgWlVeyuRGd_001-material'
                                geometry={
                                  nodes['vVGvlgWlVeyuRGd_001-material'].geometry
                                }
                                material={materials['vVGvlgWlVeyuRGd.001']}
                              />
                            </group>
                            <group name='blnbwndqwpyMKUH'>
                              <mesh
                                name='LYzqufVZtJPAOrK_001-material'
                                geometry={
                                  nodes['LYzqufVZtJPAOrK_001-material'].geometry
                                }
                                material={materials['LYzqufVZtJPAOrK.001']}
                              />
                            </group>
                            <group name='ordeALaXtVeferX'>
                              <mesh
                                name='pcJKaGFbWeBZXrD_001-material'
                                geometry={
                                  nodes['pcJKaGFbWeBZXrD_001-material'].geometry
                                }
                                material={materials['pcJKaGFbWeBZXrD.001']}
                              />
                            </group>
                            <group name='qmRaQcmiukWzkFl'>
                              <mesh
                                name='eayVjkFqRlYoCjo_001-material'
                                geometry={
                                  nodes['eayVjkFqRlYoCjo_001-material'].geometry
                                }
                                material={materials['eayVjkFqRlYoCjo.001']}
                              />
                            </group>
                            <group name='sMqKXdhmfqMCcaM'>
                              <mesh
                                name='EtFgaXCoUdBMdxZ_001-material_3'
                                geometry={
                                  nodes['EtFgaXCoUdBMdxZ_001-material_3']
                                    .geometry
                                }
                                material={materials['EtFgaXCoUdBMdxZ.001']}
                              />
                            </group>
                            <group name='smsbqZudYDccvCk'>
                              <mesh
                                name='RmoEuFpVgpnfYAR_001-material'
                                geometry={
                                  nodes['RmoEuFpVgpnfYAR_001-material'].geometry
                                }
                                material={materials['RmoEuFpVgpnfYAR.001']}
                              />
                            </group>
                            <group name='tveXszwBLpUZaFp'>
                              <mesh
                                name='LYzqufVZtJPAOrK_001-material_1'
                                geometry={
                                  nodes['LYzqufVZtJPAOrK_001-material_1']
                                    .geometry
                                }
                                material={materials['LYzqufVZtJPAOrK.001']}
                              />
                            </group>
                            <group name='uuaGIqCKQxugiGl'>
                              <mesh
                                name='eVzEOxVmiJRhSFf_001-material_1'
                                geometry={
                                  nodes['eVzEOxVmiJRhSFf_001-material_1']
                                    .geometry
                                }
                                material={materials['eVzEOxVmiJRhSFf.001']}
                              />
                            </group>
                            <group name='vqGoaFNyiGroenU'>
                              <mesh
                                name='xKVYQnHEpGkCkNs_001-material'
                                geometry={
                                  nodes['xKVYQnHEpGkCkNs_001-material'].geometry
                                }
                                material={materials['xKVYQnHEpGkCkNs.001']}
                              />
                            </group>
                            <group name='WwxLjjCOAeQOdYU'>
                              <mesh
                                name='LYzqufVZtJPAOrK_001-material_2'
                                geometry={
                                  nodes['LYzqufVZtJPAOrK_001-material_2']
                                    .geometry
                                }
                                material={materials['LYzqufVZtJPAOrK.001']}
                              />
                            </group>
                            <group name='yYVfOnKpHehHzSY'>
                              <mesh
                                name='LYzqufVZtJPAOrK_001-material_3'
                                geometry={
                                  nodes['LYzqufVZtJPAOrK_001-material_3']
                                    .geometry
                                }
                                material={materials['LYzqufVZtJPAOrK.001']}
                              />
                            </group>
                          </group>
                          <group name='jTaBqxCtBlEjGEM'>
                            <mesh
                              name='eVzEOxVmiJRhSFf_001-material'
                              geometry={
                                nodes['eVzEOxVmiJRhSFf_001-material'].geometry
                              }
                              material={materials['eVzEOxVmiJRhSFf.001']}
                            />
                          </group>
                          <group name='lkonPIiqDLmPvKN'>
                            <mesh
                              name='NRKgvZEGjdqRXhC_001-material'
                              geometry={
                                nodes['NRKgvZEGjdqRXhC_001-material'].geometry
                              }
                              material={materials['NRKgvZEGjdqRXhC.001']}
                            />
                          </group>
                          <group name='LZjJAYVJDAmktIj'>
                            <mesh
                              name='UpJorJMRrcMMGfL_001-material'
                              geometry={
                                nodes['UpJorJMRrcMMGfL_001-material'].geometry
                              }
                              material={materials['UpJorJMRrcMMGfL.001']}
                            />
                          </group>
                          <group name='MEdknsCyRNLALyc'>
                            <group name='aNVflhWEMCyvCyD'>
                              <mesh
                                name='xEINmDJHJtyYSZS_001-material'
                                geometry={
                                  nodes['xEINmDJHJtyYSZS_001-material'].geometry
                                }
                                material={materials['xEINmDJHJtyYSZS.001']}
                              />
                            </group>
                            <group name='bdNtpOAzGkhKgtR'>
                              <mesh
                                name='xEINmDJHJtyYSZS_001-material_1'
                                geometry={
                                  nodes['xEINmDJHJtyYSZS_001-material_1']
                                    .geometry
                                }
                                material={materials['xEINmDJHJtyYSZS.001']}
                              />
                            </group>
                            <group name='DmTWSqMrgRNJlJq'>
                              <mesh
                                name='UpJorJMRrcMMGfL_001-material_1'
                                geometry={
                                  nodes['UpJorJMRrcMMGfL_001-material_1']
                                    .geometry
                                }
                                material={materials['UpJorJMRrcMMGfL.001']}
                              />
                            </group>
                            <group name='DzAhorDdFkHfLCw'>
                              <group name='awdJnuUIRSsFpzr'>
                                <mesh
                                  name='UpJorJMRrcMMGfL_001-material_4'
                                  geometry={
                                    nodes['UpJorJMRrcMMGfL_001-material_4']
                                      .geometry
                                  }
                                  material={materials['UpJorJMRrcMMGfL.001']}
                                />
                              </group>
                              <group name='tEWoXcLTcKoZOXK'>
                                <mesh
                                  name='xEINmDJHJtyYSZS_001-material_8'
                                  geometry={
                                    nodes['xEINmDJHJtyYSZS_001-material_8']
                                      .geometry
                                  }
                                  material={materials['xEINmDJHJtyYSZS.001']}
                                />
                              </group>
                            </group>
                            <group name='hdQHPCNmtCJdgdh'>
                              <mesh
                                name='EtFgaXCoUdBMdxZ_001-material_4'
                                geometry={
                                  nodes['EtFgaXCoUdBMdxZ_001-material_4']
                                    .geometry
                                }
                                material={materials['EtFgaXCoUdBMdxZ.001']}
                              />
                            </group>
                            <group name='jrugfLznxfmnVOO'>
                              <mesh
                                name='UpJorJMRrcMMGfL_001-material_2'
                                geometry={
                                  nodes['UpJorJMRrcMMGfL_001-material_2']
                                    .geometry
                                }
                                material={materials['UpJorJMRrcMMGfL.001']}
                              />
                            </group>
                            <group name='LQArApnYMGhEARb'>
                              <mesh
                                name='UpJorJMRrcMMGfL_001-material_3'
                                geometry={
                                  nodes['UpJorJMRrcMMGfL_001-material_3']
                                    .geometry
                                }
                                material={materials['UpJorJMRrcMMGfL.001']}
                              />
                            </group>
                            <group name='OuKGxhNxqWxcwoz'>
                              <mesh
                                name='xEINmDJHJtyYSZS_001-material_2'
                                geometry={
                                  nodes['xEINmDJHJtyYSZS_001-material_2']
                                    .geometry
                                }
                                material={materials['xEINmDJHJtyYSZS.001']}
                              />
                            </group>
                          </group>
                          <group name='rpJxNkRnVJaJkBQ'>
                            <mesh
                              name='jCGtFgFjpibdIdA_001-material'
                              geometry={
                                nodes['jCGtFgFjpibdIdA_001-material'].geometry
                              }
                              material={materials['jCGtFgFjpibdIdA.001']}
                            />
                          </group>
                          <group name='VTpKnRwEsVjBfOM'>
                            <group name='BBfdlMxfuJdGbgq'>
                              <mesh
                                name='xEINmDJHJtyYSZS_001-material_3'
                                geometry={
                                  nodes['xEINmDJHJtyYSZS_001-material_3']
                                    .geometry
                                }
                                material={materials['xEINmDJHJtyYSZS.001']}
                              />
                            </group>
                            <group name='HmewNNGbtxpHXbp'>
                              <mesh
                                name='jCGtFgFjpibdIdA_001-material_1'
                                geometry={
                                  nodes['jCGtFgFjpibdIdA_001-material_1']
                                    .geometry
                                }
                                material={materials['jCGtFgFjpibdIdA.001']}
                              />
                            </group>
                            <group name='MjKoCOqQqbRCIpB'>
                              <mesh
                                name='jCGtFgFjpibdIdA_001-material_2'
                                geometry={
                                  nodes['jCGtFgFjpibdIdA_001-material_2']
                                    .geometry
                                }
                                material={materials['jCGtFgFjpibdIdA.001']}
                              />
                            </group>
                            <group name='NwxpiwXkRZhtiVF'>
                              <mesh
                                name='xKVYQnHEpGkCkNs_001-material_1'
                                geometry={
                                  nodes['xKVYQnHEpGkCkNs_001-material_1']
                                    .geometry
                                }
                                material={materials['xKVYQnHEpGkCkNs.001']}
                              />
                            </group>
                            <group name='OxMoLLXPgiFIODR'>
                              <mesh
                                name='xEINmDJHJtyYSZS_001-material_4'
                                geometry={
                                  nodes['xEINmDJHJtyYSZS_001-material_4']
                                    .geometry
                                }
                                material={materials['xEINmDJHJtyYSZS.001']}
                              />
                            </group>
                            <group name='qsCNRyAyHrwHSns'>
                              <mesh
                                name='awHiRXJGHDDsZAe_001-material'
                                geometry={
                                  nodes['awHiRXJGHDDsZAe_001-material'].geometry
                                }
                                material={materials['awHiRXJGHDDsZAe.001']}
                              />
                            </group>
                            <group name='zIGhbhvolNHdNKj'>
                              <mesh
                                name='saRNhuLSrFYuedf_001-material'
                                geometry={
                                  nodes['saRNhuLSrFYuedf_001-material'].geometry
                                }
                                material={materials['saRNhuLSrFYuedf.001']}
                              />
                            </group>
                          </group>
                        </group>
                        <group name='ZtLUNCGCyzhDwkv'>
                          <group name='ApkMXQOkcerDupM'>
                            <group name='DjojexCCBKxptBe'>
                              <mesh
                                name='OGleMDoIcQFdDNP_001-material_2'
                                geometry={
                                  nodes['OGleMDoIcQFdDNP_001-material_2']
                                    .geometry
                                }
                                material={materials['OGleMDoIcQFdDNP.001']}
                              />
                            </group>
                            <group name='DpVAWatdLYuGlPX'>
                              <mesh
                                name='EtFgaXCoUdBMdxZ_001-material_5'
                                geometry={
                                  nodes['EtFgaXCoUdBMdxZ_001-material_5']
                                    .geometry
                                }
                                material={materials['EtFgaXCoUdBMdxZ.001']}
                              />
                            </group>
                            <group name='gVeyhdlZhPkpdtv'>
                              <mesh
                                name='OGleMDoIcQFdDNP_001-material_3'
                                geometry={
                                  nodes['OGleMDoIcQFdDNP_001-material_3']
                                    .geometry
                                }
                                material={materials['OGleMDoIcQFdDNP.001']}
                              />
                            </group>
                            <group name='hWRqOtixdkWIKku'>
                              <mesh
                                name='OGleMDoIcQFdDNP_001-material_4'
                                geometry={
                                  nodes['OGleMDoIcQFdDNP_001-material_4']
                                    .geometry
                                }
                                material={materials['OGleMDoIcQFdDNP.001']}
                              />
                            </group>
                            <group name='JFKrYLDiNKctyOc'>
                              <mesh
                                name='EtFgaXCoUdBMdxZ_001-material_6'
                                geometry={
                                  nodes['EtFgaXCoUdBMdxZ_001-material_6']
                                    .geometry
                                }
                                material={materials['EtFgaXCoUdBMdxZ.001']}
                              />
                            </group>
                            <group name='MABsySGimfJjlaN'>
                              <mesh
                                name='OGleMDoIcQFdDNP_001-material_5'
                                geometry={
                                  nodes['OGleMDoIcQFdDNP_001-material_5']
                                    .geometry
                                }
                                material={materials['OGleMDoIcQFdDNP.001']}
                              />
                            </group>
                            <group name='MQGHZcYtzpmRCej'>
                              <mesh
                                name='OGleMDoIcQFdDNP_001-material_6'
                                geometry={
                                  nodes['OGleMDoIcQFdDNP_001-material_6']
                                    .geometry
                                }
                                material={materials['OGleMDoIcQFdDNP.001']}
                              />
                            </group>
                            <group name='RXcHLWDxUoNnsOH'>
                              <mesh
                                name='GmWBXngFrYhvbdO_001-material'
                                geometry={
                                  nodes['GmWBXngFrYhvbdO_001-material'].geometry
                                }
                                material={materials['GmWBXngFrYhvbdO.001']}
                              >
                                {' '}
                                <Decal
                                  position={[-1.5, 0.62, 0]}
                                  rotation={[0, 0.0, 0]}
                                  scale={scale}
                                  onPointerUp={(e) => {
                                    props.handle_is_dragging(false);
                                    if (e.timeStamp - mouseDownTime < 200)
                                      props.handleselect();
                                  }}
                                  onPointerDown={(e) => {
                                    e.stopPropagation();
                                    setMouseDownTime(e.timeStamp);
                                  }}
                                  onPointerOut={(e) => {
                                    props.handle_is_dragging(false);
                                  }}
                                >
                                  <meshBasicMaterial
                                    map={texture}
                                    polygonOffset
                                    polygonOffsetFactor={-1}
                                  />
                                </Decal>
                              </mesh>
                            </group>
                            <group name='XdktQtZljcAtNVS'>
                              <mesh
                                name='iWiAPFztgBDxNan_001-material'
                                geometry={
                                  nodes['iWiAPFztgBDxNan_001-material'].geometry
                                }
                                material={materials['iWiAPFztgBDxNan.001']}
                              />
                            </group>
                            <group name='xycqxOINGKvBrhK'>
                              <mesh
                                name='iWiAPFztgBDxNan_001-material_1'
                                geometry={
                                  nodes['iWiAPFztgBDxNan_001-material_1']
                                    .geometry
                                }
                                material={materials['iWiAPFztgBDxNan.001']}
                              />
                            </group>
                          </group>
                          <group name='CbwUsqydXEwwwXZ'>
                            <mesh
                              name='OGleMDoIcQFdDNP_001-material'
                              geometry={
                                nodes['OGleMDoIcQFdDNP_001-material'].geometry
                              }
                              material={materials['OGleMDoIcQFdDNP.001']}
                            />
                          </group>
                          <group name='gefbsxrRWnYAEqE'>
                            <mesh
                              name='tUIxbALjcklqMLh_001-material'
                              geometry={
                                nodes['tUIxbALjcklqMLh_001-material'].geometry
                              }
                              material={materials['tUIxbALjcklqMLh.001']}
                            />
                          </group>
                          <group name='mokhKQWVJBdSLne'>
                            <mesh
                              name='OGleMDoIcQFdDNP_001-material_1'
                              geometry={
                                nodes['OGleMDoIcQFdDNP_001-material_1'].geometry
                              }
                              material={materials['OGleMDoIcQFdDNP.001']}
                            />
                          </group>
                          <group name='yTFJUEJJRCIZltP'>
                            <group name='GXWqwtKSgtzjaYb'>
                              <mesh
                                name='LDbhHgKqQwnfIdk_001-material'
                                geometry={
                                  nodes['LDbhHgKqQwnfIdk_001-material'].geometry
                                }
                                material={materials['LDbhHgKqQwnfIdk.001']}
                              />
                            </group>
                            <group name='inEuMCtsCWGVqEG'>
                              <mesh
                                name='LDbhHgKqQwnfIdk_001-material_1'
                                geometry={
                                  nodes['LDbhHgKqQwnfIdk_001-material_1']
                                    .geometry
                                }
                                material={materials['LDbhHgKqQwnfIdk.001']}
                              />
                            </group>
                            <group name='MGVLUSWxMmioRDb'>
                              <mesh
                                name='EtFgaXCoUdBMdxZ_001-material_7'
                                geometry={
                                  nodes['EtFgaXCoUdBMdxZ_001-material_7']
                                    .geometry
                                }
                                material={materials['EtFgaXCoUdBMdxZ.001']}
                              />
                            </group>
                            <group name='niRCnFaBvDoTEhp'>
                              <mesh
                                name='EWuRzSMoXPhEpsh_001-material'
                                geometry={
                                  nodes['EWuRzSMoXPhEpsh_001-material'].geometry
                                }
                                material={materials['EWuRzSMoXPhEpsh.001']}
                              />
                            </group>
                            <group name='WzelDFwkrXNlzhL'>
                              <mesh
                                name='MeQJBtEiHnDpYBD_001-material'
                                geometry={
                                  nodes['MeQJBtEiHnDpYBD_001-material'].geometry
                                }
                                material={materials['MeQJBtEiHnDpYBD.001']}
                              />
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
            </group>
          </group>
        </group>
      </group>
      <group
        scale={10}
        position={[-1.45, 0, 0.4]}
        // onClick={(e) => {
        //   e.stopPropagation();
        // }}
        // onPointerDown={(e) =>
        //   handleMouseDown(e, clicked_ref === 1 ? event_name_ref : date_ref)
        // }
        // onPointerMove={(e) =>
        //   handleMouseMove(
        //     e,
        //     clicked_ref === 1 ? event_name_ref : date_ref,
        //     clicked_ref
        //   )
        // }
        // onPointerUp={(e) => {
        //   console.log("up");
        //   handleMouseUp(e);
        // }}
        // onPointerOut={(e) => {
        //   console.log("out");
        //   // handleMouseUp(e)
        // }}
      >
        <mesh scale={0.8} position={[-0.22, -0.58, -0.071]}>
          <circleGeometry args={[0.07, 32]} />
          <meshStandardMaterial transparent opacity={0} />
          <meshBasicMaterial
            map={props.is_dark_color ? white_gallery : gallery}
            color={props.button_color}
            scale={0.7}
          />
        </mesh>

        <mesh position={[0, -0.58, -0.071]} scale={0.8}>
          <circleGeometry args={[0.09, 32]} />
          <meshStandardMaterial transparent opacity={0} />
          <meshBasicMaterial
            map={props.is_dark_color ? white_camera : camera}
            color={props.button_color}
          />
        </mesh>
        <mesh scale={0.8} position={[0.22, -0.58, -0.071]}>
          <circleGeometry args={[0.07, 32]} />
          <meshBasicMaterial color={props.button_color} />
          <Text
            color={props.is_dark_color ? 'white' : 'black'}
            scale={0.065}
            font={font}
            fontWeight='bold'
            position={[-0, 0.018, 0.001]}
          >
            10
          </Text>
          <Text
            color={props.is_dark_color ? 'white' : 'black'}
            scale={0.03}
            position={[-0.0, -0.035, 0.002]}
            font={font}
            fontWeight='bold'
          >
            {props.is_en ? 'Left' : 'נותרו'}
          </Text>
        </mesh>

        <Drag_mesh
          data={props.event_name}
          button_color={props.button_color}
          position={[
            -0.007,
            props.event_name && props.event_name.y
              ? convert_y_position_precentage(props.event_name.y)
              : maxY,
            -0.075,
          ]}
          is_event_name={true}
          handle_event_data={props.handle_event_data}
          handle_is_dragging={props.handle_is_dragging}
          is_dragging={props.is_dragging}
        ></Drag_mesh>
        <Drag_mesh
          data={props.date}
          button_color={props.button_color}
          position={[
            -0.007,
            props.date && props.date.y
              ? convert_y_position_precentage(props.date.y)
              : minY,
            -0.074,
          ]}
          handle_event_data={props.handle_event_data}
          handle_is_dragging={props.handle_is_dragging}
          is_dragging={props.is_dragging}
        ></Drag_mesh>
      </group>
    </group>
  );
}

useGLTF.preload('/models/Iphone.glb');
