import React, { useEffect, useRef, useState } from 'react';
import './Edit_text.css';
import FontPicker from 'font-picker-react';
import Switch from 'react-switch';
// import { Slider } from 'antd';
import { Dropdown } from 'primereact/dropdown';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

// import required modules
import { Navigation } from 'swiper/modules';
import { Slider } from '@mui/material';
const Edit_text = (props) => {
  const color_ref = useRef();
  const [font_name, set_font_name] = useState('Tangerine');
  const [selected_font, set_selected_font] = useState(-1);
  const sizes_array = Array.from(
    { length: (72 - 20) / 2 },
    (_, i) => 20 + i * 2
  );
  const handlefontChange = (font, index) => {
    set_font_name(font.className.split(' ')[0]);
    set_selected_font(index);
    props.handle_text(
      props.is_event_name
        ? {
            event_name: {
              ...props.text,
              font: {
                name: font.className.split(' ')[0],
                font: font.value,
              },
            },
          }
        : {
            date: {
              ...props.text,
              font: {
                name: font.className.split(' ')[0],
                font: font.value,
              },
            },
          }
    );
  };
  const handleDropdownChange = (e) => {
    props.handle_text(
      props.is_event_name
        ? {
            event_name: {
              ...props.text,
              font_size: e.value,
            },
          }
        : {
            date: {
              ...props.text,
              font_size: e.value,
            },
          }
    );
  };
  return (
    <section className='set_event_name_container '>
      <input
        className='set_event_name_container_text_input bg_box'
        type='text'
        placeholder={props.is_event_name ? 'שם האירוע' : 'כותרת משנה'}
        value={props.text && props.text.name ? props.text.name : ''}
        maxLength={50}
        onChange={(e) => {
          props.handle_text(
            props.is_event_name
              ? {
                  event_name: {
                    ...props.text,
                    name: e.target.value,
                  },
                }
              : {
                  date: {
                    ...props.text,
                    name: e.target.value,
                  },
                }
          );
        }}
      ></input>
      <section className='edit_text_section bg_box'>
        <div className='text_config_section'>
          <div className='edit_text_buttons'>
            <div
              className='font_color_button'
              style={{ backgroundColor: props.text.font_color }}
            >
              {' '}
              <input
                ref={color_ref}
                type='color'
                className='color_input'
                style={{
                  position: 'absolute',
                  opacity: 0,
                  bottom: '0',
                  left: '-20%',
                  zIndex: '0',
                }}
                value={props.text.font_color}
                onChange={(e) => {
                  props.handle_text(
                    props.is_event_name
                      ? {
                          event_name: {
                            ...props.text,
                            font_color: e.target.value,
                          },
                        }
                      : { date: { ...props.text, font_color: e.target.value } }
                  );
                }}
              ></input>
            </div>
            <div>
              {' '}
              <Dropdown
                value={props.text.font_size}
                onChange={handleDropdownChange}
                options={sizes_array}
                placeholder='20'
                className={`w-full md:w-14rem dropdown bg_box `}
                panelClassName='bg_box panel'
              />
            </div>
            <div
              onClick={() => {
                props.handle_text(
                  props.is_event_name
                    ? {
                        event_name: {
                          ...props.text,
                          is_bold: !props.text.is_bold,
                        },
                      }
                    : { date: { ...props.text, is_bold: !props.text.is_bold } }
                );
              }}
              className={
                props.text.is_bold
                  ? 'text_bold_button bold_text bg_box'
                  : 'text_bold_button bg_box'
              }
            >
              <span>B</span>
            </div>
          </div>
          <div className='fonts_swiper_container bg_box'>
            {window.innerWidth < 900 ? (
              <div className='fonts_scroller'>
                {' '}
                {props.fonts_array.map((font, index) => {
                  return (
                    <span
                      key={index}
                      onClick={() => {
                        handlefontChange(font, index);
                      }}
                      className='font_swiper_slide'
                      style={{ width: 'fit-content' }}
                    >
                      <span
                        className={font.className.split(' ')[0]}
                        style={{
                          fontWeight: selected_font === index ? '800' : '400',
                          color: selected_font === index ? 'black' : 'gray',
                        }}
                      >
                        Aa
                      </span>
                    </span>
                  );
                })}
              </div>
            ) : (
              <Swiper
                // navigation={true}
                modules={[Navigation]}
                className='mySwiper '
                slidesPerView={'auto'}
                spaceBetween={10}
                initialSlide={props.fonts_array.length - 1}
              >
                {props.fonts_array.map((font, index) => {
                  return (
                    <SwiperSlide
                      key={index}
                      onClick={() => {
                        handlefontChange(font, index);
                      }}
                      className='font_swiper_slide'
                      style={{ width: 'fit-content' }}
                    >
                      <span className={font.className.split(' ')[0]}>Aa</span>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            )}
          </div>
        </div>
        <div className='text_color_section'>
          <div
            className='font_color_button'
            style={{ backgroundColor: props.text.font_bg_color }}
          >
            {' '}
            <input
              ref={color_ref}
              type='color'
              className='color_input'
              style={{
                position: 'absolute',
                opacity: 0,
                bottom: '0',
                left: '-20%',
                zIndex: '10',
              }}
              value={props.text.font_bg_color}
              onChange={(e) => {
                props.handle_text(
                  props.is_event_name
                    ? {
                        event_name: {
                          ...props.text,
                          font_bg_color: e.target.value,
                        },
                      }
                    : { date: { ...props.text, font_bg_color: e.target.value } }
                );
              }}
            ></input>
          </div>
          <div className='opacity_slider_container'>
            {/* <Slider
              min={0} // Minimum value
              max={100}
              step={1} // Step size for each movement
              style={{ width: "100%" }} // Inline styles
              railStyle={{ backgroundColor: "lightgray" }} // Style for the rail
              trackStyle={{ backgroundColor: "#066fa4c6" }} // Style for the track
              handleStyle={{ borderColor: "#066fa4c6" }}
              value={Math.round((parseInt(props.text.opacity, 16) / 255) * 100)}
              onChange={(new_val) => {
                const hex = Math.round((new_val / 100) * 255).toString(16);
                props.handle_text(
                  props.is_event_name
                    ? {
                        event_name: {
                          ...props.text,
                          opacity: hex.length === 1 ? "0" + hex : hex,
                        },
                      }
                    : {
                        date: {
                          ...props.text,
                          opacity: hex.length === 1 ? "0" + hex : hex,
                        },
                      }
                );
              }}
            /> */}
            <Slider
              aria-label='Default'
              value={Math.round(
                (parseInt(props.text.opacity || 'ff', 16) / 255) * 100
              )}
              onChange={(e) => {
                const new_val = e.target.value;
                const hex = Math.round((new_val / 100) * 255).toString(16);
                props.handle_text(
                  props.is_event_name
                    ? {
                        event_name: {
                          ...props.text,
                          opacity: hex.length === 1 ? '0' + hex : hex,
                        },
                      }
                    : {
                        date: {
                          ...props.text,
                          opacity: hex.length === 1 ? '0' + hex : hex,
                        },
                      }
                );
              }}
              valueLabelDisplay='auto'
            />
          </div>
          <span>צבע רקע</span>
        </div>
      </section>
    </section>
  );
};

export default Edit_text;
