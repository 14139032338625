import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Text } from "@react-three/drei";
import * as THREE from "three";

import {
  convert_y_position,
  convert_y_position_precentage,
} from "../../../Util/helpers";
const Drag_mesh = (props) => {
  const array = new Float32Array([0, 20, 0, 0, 0, 0, 20, 0, 0]);
  const meshRef = useRef();
  const parentRef = useRef();
  const [parentWidth, setParentWidth] = useState(2); // Default width

  const [textMaxWidth, setTextMaxWidth] = useState(parentWidth * 2); // Initial max width based on parent width

  const [initialY, setInitialY] = useState(
    props.data && props.data.y
      ? convert_y_position_precentage(props.data.y)
      : -0.3
  );
  const minY = -0.37; // Minimum Y position
  const maxY = 0.54; // Maximum Y position
  const handlePointerDown = (e) => {
    e.stopPropagation();
    props.handle_is_dragging(true);
    setInitialY(e.point.y - meshRef.current.position.y);
  };

  const handlePointerUp = (e) => {
    e.stopPropagation();
    props.handle_is_dragging(false);

    if (props.is_event_name) {
      props.handle_event_data({
        event_name: {
          ...props.data,
          y: convert_y_position(meshRef.current.position.y),
        },
      });
    } else {
      props.handle_event_data({
        date: {
          ...props.data,
          y: convert_y_position(meshRef.current.position.y),
        },
      });
    }
  };

  const handlePointerMove = (e) => {
    e.stopPropagation();
    if (props.is_dragging) {
      const newPosition = meshRef.current.position.clone();

      const deltaY = e.movementY || e.mozMovementY || 0;
      newPosition.y -= deltaY * 0.005;
      newPosition.y =
        newPosition.y < minY
          ? minY
          : newPosition.y > maxY
          ? maxY
          : newPosition.y;
      meshRef.current.position.y = newPosition.y;
    }
  };

  return (
    <group
      ref={meshRef}
      position={props.position} // Adjust position as needed
      //   onClick={(e) => {
      //     e.stopPropagation();
      //     console.log("hello");
      //     set_clicked_ref(2);
      //   }}
      // onPointerDown={(e) => handleMouseDown(e, date_ref)}
      // onPointerMove={(e) => handleMouseMove(e, date_ref, 2)}
      // onPointerUp={(e) => handleMouseUp(e)}
      // onPointerOut={(e) => handleMouseUp(e)}
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
      onPointerMove={handlePointerMove}
    >
      {props.data.name === "" ? (
        ""
      ) : (
        <mesh ref={parentRef}>
          <boxGeometry args={[0.7, 0.0024 * props.data.font_size, 0.003]} />
          <meshBasicMaterial
            color={props.data.font_bg_color}
            transparent={true}
            opacity={parseInt(props.data.opacity, 16) / 255}
          />
          <Text
            scale={[
              props.data.font_size * 0.0016,
              props.data.font_size * 0.0016,
              1,
            ]}
            position={[0, 0.005, 0.01]}
            color={props.data.font_color}
            font={props.data.font.font}
            textAlign="center" // Options: 'left', 'right', 'center', 'justify'
          >
            {props.data.name}
          </Text>
        </mesh>
      )}
    </group>
  );
};

export default Drag_mesh;
