import React, { useEffect, useState } from "react";
import "./Event_page.css";
import * as IconSet5 from "react-icons/cg";
import * as hi2 from "react-icons/hi2";
import * as io from "react-icons/io5";

const Menu_button = (props) => {
  return (
    <div
      className="event_menu_contianer"
      style={{
        color: props.is_dark_color ? "white" : "black",
        fontFamily: "Montserrat",
      }}
    >
      {" "}
      <IconSet5.CgMenuLeft
        className={
          props.is_menu_clicked
            ? "menu_icon  move_right"
            : "menu_icon move_left"
        }
        style={{ color: props.color }}
        onClick={() => {
          props.click_menu();
        }}
      ></IconSet5.CgMenuLeft>
      <div
        className={
          props.is_menu_clicked
            ? "event_menu_option move_left_div"
            : "event_menu_option move_right"
        }
        style={{ backgroundColor: props.color }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          props.move_to_gallery();
        }}
      >
              <span style={{ fontFamily: props.is_en ? '' : 'verale' }}
>{props.is_en ? 'Event Gallery' : 'גלריית האירוע'}</span> <io.IoImagesOutline></io.IoImagesOutline>
      </div>
      <div
        className={
          props.is_menu_clicked
            ? "event_menu_option move_left_div"
            : "event_menu_option move_right"
        }
        style={{ backgroundColor: props.color }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          props.barcode_clicked();
        }}
      >
              <span style={{ fontFamily: props.is_en ? '' : 'verale' }}
>{props.is_en?'Share':'שתפו'}</span> <hi2.HiOutlineShare></hi2.HiOutlineShare>
      </div>
    </div>
  );
};

export default Menu_button;
