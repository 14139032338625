import React from "react";
import "./Wait_page.css";
import { BeatLoader } from "react-spinners";

const Wait_page = () => {
  return (
    <div className="loading_data_icnon">
      <BeatLoader color="var(--blue)" size="5vw" />
    </div>
  );
};

export default Wait_page;
