import React, { useState } from 'react';
import './Nav_bar.css';
import logo_icon from '../../images/logo_icon.png';
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
} from 'react-scroll';
import { sendPasswordReset } from '../../firebase';

const Nav_bar = (props) => {
  return (
    <div className='nav_bar_container'>
      <section className='logo_icon_section'>
        <Link
          to='section_1'
          smooth={true}
          duration={Math.abs(props.section_index - 1) * 1500}
          offset={-25}
          // activeClass="active"
          spy={true}
        >
          <span
            style={{ color: 'black' }}
                      onClick={() => {

              props.set_section_index(1);
            }}
          >
            <span style={{}}>M</span>
            oments
          </span>
        </Link>
      </section>
      <section className='nav_bar_section'>
        {/* <button
          className="leng_button"
          onClick={() => {
            props.handle_en_he();
          }}
        >
          {" "}
          <span>EN/HE</span>{" "}
        </button> */}
        <button
          className='nav_bar_my_events'
          onClick={() => props.handle_terms()}
        >
          <span>תקנון</span>
        </button>
        <button
          className='nav_bar_my_events'
          onClick={() => props.handle_personal_page()}
        >
          <span>האירועים שלי</span>
        </button>
      </section>
    </div>
  );
};

export default Nav_bar;
