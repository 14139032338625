import React from "react";
import "./Section_1.css";
import "aos/dist/aos.css";
import AOS from "aos";
import Choose_section from "./Choose_data/Choose_section";
const Section_1 = (props) => {
  return (
    <section
      className={
        props.page === 1
          ? "ev_section_1 event_section_1_left fade_in"
          : "ev_section_1 event_section_1_left fade_out"
      }
    >
      <Choose_section
        is_en={props.is_en}
        button_color={props.button_color}
        handle_is_dark_color={(value) => {
          props.set_is_dark_color(value);
        }}
        handle_event_data={props.handle_event_data}
        event_name={props.event_name}
        date={props.date}
        is_name_font={props.is_name_font}
        is_date_font={props.is_date_font}
        handle_is_name_font={(value) => props.set_is_name_font(value)}
        handle_is_date_font={(value) => props.set_is_date_font(value)}
        fonts_array={props.fonts_array}
        config_data={props.config_data}
        is_dark_color={props.is_dark_color}
        set_is_dark_color={props.set_is_dark_color}
        set_bg_img={props.set_bg_img}
      ></Choose_section>
    </section>
  );
};

export default Section_1;
