/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 public/models/Iphone.glb -o src/Iphone.js -k -K -r public 
Author: Navarion (https://sketchfab.com/Navarion)
License: CC-BY-NC-4.0 (http://creativecommons.org/licenses/by-nc/4.0/)
Source: https://sketchfab.com/3d-models/free-iphone15-pro-max-ultra-high-quality-1fb1717d5e204302bfe9969ea77293a4
Title: FREE - IPhone15 Pro Max - Ultra High Quality
*/

import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import { useGLTF, useTexture, Html, Text, Decal } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { MeshBasicMaterial, Vector3 } from "three";
import FontPicker from "font-picker-react";
import gsap from "gsap";

export function Iphone(props) {
  const { nodes, materials } = useGLTF("/models/Iphone.glb");
  const texture1 = props.url1;
  const texture2 = props.url2;
  const texture3 = props.url3;
  const texture4 = props.url4;
  const texture5 = props.url5;
  const texture6 = props.url6;
  const tex = useTexture(props.url1);
  const imageAspectRatio = tex.image.width / tex.image.height;
  const meshWidth = 4;
  const meshHeight = 15.38;
  const meshAspectRatio = meshWidth / meshHeight;

  let scale;
  if (imageAspectRatio > meshAspectRatio) {
    // Cover by height, crop width
    scale = [meshHeight * imageAspectRatio, meshHeight, 1];
  } else {
    // Cover by width, crop height
    scale = [meshWidth, meshWidth / imageAspectRatio, 1];
  }
  // Call the preload function when the component mounts
  useEffect(() => {
    // preloadTextures();
  }, []);
  const ref = useRef();
  // useFrame((state, delta) => {
  //   {
  //     phone_ref.current.rotation.y =
  //       Math.sin(state.clock.elapsedTime / 6) / 4 - 0.1;
  //     phone_ref.current.position.y = Math.sin(state.clock.elapsedTime * 2) / 40;
  //   }
  // });
  const [animationTriggered, setAnimationTriggered] = useState(false);
  const [textureUrl, setTextureUrl] = useState(texture4);

  useLayoutEffect(() => {
    const animations = [];
    let isRunning = false;
    let first_time = props.is_small_screen;
    function first_time_animation() {
      animations.push(
        gsap.delayedCall(0, () => {
          setTextureUrl(texture1);
        })
      );
      animations.push(
        gsap.delayedCall(0.5, () => {
          setTextureUrl(texture2);
        })
      );
      animations.push(
        gsap.delayedCall(1, () => {
          setTextureUrl(texture3);
        })
      );

      animations.push(
        gsap.delayedCall(1.5, () => {
          setTextureUrl(texture4);
        })
      );
      animations.push(
        gsap.delayedCall(2, () => {
          setTextureUrl(texture5);
        })
      );

      animations.push(
        gsap.delayedCall(2.5, () => {
          setTextureUrl(texture6);
        })
      );

      animations.push(
        gsap.delayedCall(3, () => {
          setTextureUrl(texture4);
        })
      );
    }
    function startAnimations() {
      if (!isRunning) return;
      let delay = 1;
      let param = 1.3;
      animations.forEach((animation) => animation.kill());
      animations.length = 0;
      animations.push(
        gsap.delayedCall(0.5, () => {
          setTextureUrl(texture1);
        })
      );
      if (props.is_small_screen) {
        animations.push(
          gsap.delayedCall(0, () => {
            // If the canvas is in the viewport, trigger the animation
            gsap.to(ref.current.position, {
              z: 1,
              x: -2,

              y: -0.6,
              duration: 1,
              ease: "power2.out",
            });

            gsap.to(ref.current.rotation, {
              y: 6,
              z: 0,
              x: 0,
              duration: 1,
              ease: "power2.out",
            });
          })
        );
      }
      animations.push(
        gsap.delayedCall(delay * param, () => {
          // If the canvas is in the viewport, trigger the animation
          gsap.to(ref.current.position, {
            z: -1.5,
            x: 1.5,
            duration: 1,
            y: 0.5,
            ease: "power2.out",
          });

          gsap.to(ref.current.rotation, {
            y: ref.current.rotation.y + 0.2,

            duration: 1,
            ease: "power2.out",
          });
        })
      );
      animations.push(
        gsap.delayedCall(delay * param + 0.8, () => {
          setTextureUrl(texture2);
        })
      );
      delay += 2.5;

      animations.push(
        gsap.delayedCall(delay * param, () => {
          // If the canvas is in the viewport, trigger the animation
          gsap.to(ref.current.position, {
            z: 0,
            x: 0,
            duration: 1,
            y: -0.6,
            ease: "power2.out",
          });

          gsap.to(ref.current.rotation, {
            y: ref.current.rotation.y + 0.2,

            duration: 1,
            ease: "power2.out",
          });
        })
      );
      delay += 2;
      animations.push(
        gsap.delayedCall(delay * param, () => {
          setTextureUrl(texture3);
        })
      );
      delay += 0.8;
      animations.push(
        gsap.delayedCall(delay * param, () => {
          setTextureUrl(texture4);
        })
      );
      delay += 1;
      animations.push(
        gsap.delayedCall(delay * param, () => {
          gsap.to(ref.current.position, {
            x: 0,
            z: 0,
            duration: 1,
            ease: "power2.out",
          });
          gsap.to(ref.current.rotation, {
            x: 0,
            y: ref.current.rotation.y + 0.4,
            z: Math.PI / 2,
            duration: 1,
            ease: "power2.out",
          });
        })
      );
      delay += 1;
      animations.push(
        gsap.delayedCall(delay * param, () => {
          setTextureUrl(texture1);
        })
      );
      delay += 1;
      animations.push(
        gsap.delayedCall(delay * param, () => {
          setTextureUrl(texture5);
        })
      );
      delay += 1;
      animations.push(
        gsap.delayedCall(delay * param, () => {
          // If the canvas is in the viewport, trigger the animation
          gsap.to(ref.current.position, {
            z: 0,
            x: 0,
            duration: 1,
            y: -0.6,
            ease: "power2.out",
          });

          gsap.to(ref.current.rotation, {
            y: ref.current.rotation.y - 0.5,
            z: 0,
            duration: 1,
            ease: "power2.out",
          });
        })
      );

      delay += 0.8;
      animations.push(
        gsap.delayedCall(delay * param, () => {
          setTextureUrl(texture3);
        })
      );
      delay += 1;

      animations.push(
        gsap.delayedCall(delay * param, () => {
          setTextureUrl(texture6);
        })
      );
      delay += 1;
      animations.push(
        gsap.delayedCall(delay * param, () => {
          // If the canvas is in the viewport, trigger the animation
          gsap.to(ref.current.position, {
            z: 1,
            x: -2,

            y: -0.6,
            duration: 1,
            ease: "power2.out",
          });

          gsap.to(ref.current.rotation, {
            y: 6,
            z: 0,
            x: 0,
            duration: 1,
            ease: "power2.out",
          });
        })
      );
      delay += 1.5;
      animations.push(
        gsap.delayedCall(delay * param, () => {
          setTextureUrl(texture1);
        })
      );
      animations.push(
        gsap.delayedCall(delay * param + 0.5, () => {
          if (isRunning) {
            ref.current.needsUpdate = true;
            startAnimations();
          }
        })
      );
    }

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        if (first_time) {
          first_time = false;
        } else {
          if (!isRunning) {
            isRunning = true;
            startAnimations();
          }
        }
      } else {
        if (first_time) {
          first_time_animation();
        } else {
          isRunning = false;
          setTextureUrl(texture4);
          gsap.to(ref.current.rotation, {
            z: 0,
            x: 0,
            duration: 1,
            ease: "power2.out",
          });
          if (first_time === false)
            animations.forEach((animation) => animation.kill());
        }
      }
    });

    if (props.canvasRef.current) {
      observer.observe(props.canvasRef.current);
    }

    if (!props.is_small_screen) {
      const param = 2.15;

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: props.aref.current,
          start: "top center",
          end: "bottom center",
          scrub: true,
          onUpdate: (self) => {
            const scrollProgress = self.progress;
            const ranges = [
              {
                start: 0,
                end: 0.3,
                x: [0, -1],
                y: [-0.5, -0.6],
                z: [0, 1],
                ry: [-0.4, 2],
                rx: [0, 0],
              },
              {
                start: 0.3,
                end: 0.45,
                x: [-1, 0],
                y: [-0.6, -0.5],
                z: [1, 1.5],
                ry: [2, 4],
                rx: [0, 0],
              },
              {
                start: 0.45,
                end: 0.7,
                x: [0, -2],
                y: [-0.6, -0.6],
                z: [1.5, 1],
                ry: [4, 6.0],
              },
              {
                start: 0.7,
                end: 1.05,
                x: [-2, -1],
                y: [-0.6, -0.6],
                z: [1, 1],
                ry: [6, 8.3],
              },
              {
                start: 1.05,
                end: 1.2,
                x: [-1, 0.3],
                y: [-0.6, -0.6],
                z: [1, 0.5],
                ry: [8.3, 10.6],
              },
              {
                start: 1.2,
                end: 1.3,
                x: [0.3, 0.3],
                y: [-0.6, -0.5],
                z: [0.5, 0],
                ry: [10.6, 13.0],
              },
              {
                start: 1.3,
                end: 1.5,
                x: [0.3, -1],
                y: [-0.5, -0.6],
                z: [0, 1],
                ry: [13, 14.6],
              },
              {
                start: 1.5,
                end: 1.65,
                x: [-1, 0.3],
                y: [-0.6, -0.6],
                z: [1, 1],
                ry: [14.6, 15.9],
              },
              {
                start: 1.65,
                end: 1.9,
                x: [0.3, 0],
                y: [-0.6, -0.5],
                z: [1, 0],
                ry: [15.9, 19.4],
              },
            ];

            ranges.forEach(({ start, end, x, y, z, ry, rx }) => {
              if (
                scrollProgress >= start / param &&
                scrollProgress <= end / param
              ) {
                ref.current.position.x = gsap.utils.mapRange(
                  start / param,
                  end / param,
                  x[0],
                  x[1],
                  scrollProgress
                );
                ref.current.position.y = gsap.utils.mapRange(
                  start / param,
                  end / param,
                  y[0],
                  y[1],
                  scrollProgress
                );
                ref.current.position.z = gsap.utils.mapRange(
                  start / param,
                  end / param,
                  z[0],
                  z[1],
                  scrollProgress
                );
                ref.current.rotation.y = gsap.utils.mapRange(
                  start / param,
                  end / param,
                  ry[0],
                  ry[1],
                  scrollProgress
                );
                if (rx) {
                  ref.current.rotation.x = gsap.utils.mapRange(
                    start / param,
                    end / param,
                    rx[0],
                    rx[1],
                    scrollProgress
                  );
                }
              }
            });
          },
        },
      });

      return () => {
        tl.kill();
      };

      animations.forEach((animation) => animation.kill());
      observer.unobserve(props.canvasRef.current);
    }
  }, [props.aref]);
  return (
    <group ref={ref} {...props} dispose={null} scale={0.3}>
      <group name="Sketchfab_Scene">
        <group name="Sketchfab_model" rotation={[-Math.PI / 2, 0, 0]}>
          <group name="Collada_visual_scene_group">
            <group
              name="tracking_node_placeholder"
              rotation={[Math.PI / 2, 0, 0]}
            >
              <group name="oKjEgurgmnxkEdd">
                <group name="fGJOGsieiMwuBMw">
                  <group name="OcZfGJIDFXowYWx">
                    <group name="LDSssWkPQxdZmBk">
                      <group name="KPOVPRNZJrOjkcc">
                        <group name="rFVycrpNmWtVMan">
                          <mesh
                            name="EtFgaXCoUdBMdxZ_001-material"
                            geometry={
                              nodes["EtFgaXCoUdBMdxZ_001-material"].geometry
                            }
                            material={materials["EtFgaXCoUdBMdxZ.001"]}
                          />
                        </group>
                        <group name="UjlWsrHFyUsMdxc">
                          <group name="KCXoFnmboVjtnON">
                            <group name="AagXTQXDnEhpnpS">
                              <mesh
                                name="yfSRCfyMalEYgbO_001-material"
                                geometry={
                                  nodes["yfSRCfyMalEYgbO_001-material"].geometry
                                }
                                material={materials["yfSRCfyMalEYgbO.001"]}
                              />
                            </group>
                            <group name="clwieXphOAJaTuR">
                              <mesh
                                name="yfSRCfyMalEYgbO_001-material_1"
                                geometry={
                                  nodes["yfSRCfyMalEYgbO_001-material_1"]
                                    .geometry
                                }
                                material={materials["yfSRCfyMalEYgbO.001"]}
                              />
                            </group>
                            <group name="EsFMFxTtThybjMC">
                              <mesh
                                name="aNKJdeKOByfZlDS_001-material"
                                geometry={
                                  nodes["aNKJdeKOByfZlDS_001-material"].geometry
                                }
                                material={materials["aNKJdeKOByfZlDS.001"]}
                              />
                            </group>
                            <group name="EvBJWGDiNRJzboZ">
                              <mesh
                                name="cxAXXzdnHJhRByK_001-material"
                                geometry={
                                  nodes["cxAXXzdnHJhRByK_001-material"].geometry
                                }
                                material={materials["cxAXXzdnHJhRByK.001"]}
                              />
                            </group>
                            <group name="ixccjDvZoPDsMHA">
                              <mesh
                                name="aDINyfsuELQulbd_001-material"
                                geometry={
                                  nodes["aDINyfsuELQulbd_001-material"].geometry
                                }
                                material={materials["aDINyfsuELQulbd.001"]}
                              />
                            </group>
                            <group name="MOqYtwGgqvrTJFp">
                              <mesh
                                name="EtFgaXCoUdBMdxZ_001-material_2"
                                geometry={
                                  nodes["EtFgaXCoUdBMdxZ_001-material_2"]
                                    .geometry
                                }
                                material={materials["EtFgaXCoUdBMdxZ.001"]}
                              />
                            </group>
                            <group name="oPxXulmznlLTeNo">
                              <mesh
                                name="AxufFAzeZPglisV_001-material"
                                geometry={
                                  nodes["AxufFAzeZPglisV_001-material"].geometry
                                }
                                material={materials["AxufFAzeZPglisV.001"]}
                              />
                            </group>
                            <group name="xvXwpvhQyzkwxns">
                              <mesh
                                name="DkAqxRJaVVfbpgF_001-material"
                                geometry={
                                  nodes["DkAqxRJaVVfbpgF_001-material"].geometry
                                }
                                material={materials["DkAqxRJaVVfbpgF.001"]}
                              />
                            </group>
                          </group>
                          <group name="LOVrIeiTzEHrAGH">
                            <group name="DjZpMMcSUjyZkAu">
                              <group name="AXowfgzbvDNVFYM">
                                <mesh
                                  name="yfSRCfyMalEYgbO_001-material_2"
                                  geometry={
                                    nodes["yfSRCfyMalEYgbO_001-material_2"]
                                      .geometry
                                  }
                                  material={materials["yfSRCfyMalEYgbO.001"]}
                                />
                              </group>
                              <group name="FglrxJgiEDhbpuH">
                                <mesh
                                  name="EtFgaXCoUdBMdxZ_001-material_9"
                                  geometry={
                                    nodes["EtFgaXCoUdBMdxZ_001-material_9"]
                                      .geometry
                                  }
                                  material={materials["EtFgaXCoUdBMdxZ.001"]}
                                />
                              </group>
                              <group name="gYupOnmjlHjDoTJ">
                                <mesh
                                  name="yfSRCfyMalEYgbO_001-material_3"
                                  geometry={
                                    nodes["yfSRCfyMalEYgbO_001-material_3"]
                                      .geometry
                                  }
                                  material={materials["yfSRCfyMalEYgbO.001"]}
                                />
                              </group>
                              <group name="JlYZPCJbTesYgyR">
                                <mesh
                                  name="EWuRzSMoXPhEpsh_001-material_1"
                                  geometry={
                                    nodes["EWuRzSMoXPhEpsh_001-material_1"]
                                      .geometry
                                  }
                                  material={materials["EWuRzSMoXPhEpsh.001"]}
                                />
                              </group>
                              <group name="JpDjVXmqukNTHyv">
                                <mesh
                                  name="LDbhHgKqQwnfIdk_001-material_2"
                                  geometry={
                                    nodes["LDbhHgKqQwnfIdk_001-material_2"]
                                      .geometry
                                  }
                                  material={materials["LDbhHgKqQwnfIdk.001"]}
                                />
                              </group>
                              <group name="noqGTXCNMBRuAUL">
                                <mesh
                                  name="NOlmOyvNOoXnkpD_001-material"
                                  geometry={
                                    nodes["NOlmOyvNOoXnkpD_001-material"]
                                      .geometry
                                  }
                                  material={materials["NOlmOyvNOoXnkpD.001"]}
                                />
                              </group>
                              <group name="QfLYzDizFfKhXes">
                                <mesh
                                  name="LuFFEBDynGcfeVH_001-material"
                                  geometry={
                                    nodes["LuFFEBDynGcfeVH_001-material"]
                                      .geometry
                                  }
                                  material={materials["LuFFEBDynGcfeVH.001"]}
                                />
                              </group>
                              <group name="RtqkwruGdFohMoo">
                                <mesh
                                  name="qYATvTCUqTRIxZo_001-material"
                                  geometry={
                                    nodes["qYATvTCUqTRIxZo_001-material"]
                                      .geometry
                                  }
                                  material={materials["qYATvTCUqTRIxZo.001"]}
                                />
                              </group>
                              <group name="wYCdDUbYvvUHUAE">
                                <mesh
                                  name="saRNhuLSrFYuedf_001-material_1"
                                  geometry={
                                    nodes["saRNhuLSrFYuedf_001-material_1"]
                                      .geometry
                                  }
                                  material={materials["saRNhuLSrFYuedf.001"]}
                                />
                              </group>
                              <group name="yEiaGQsYPUfQGJu">
                                <mesh
                                  name="xEINmDJHJtyYSZS_001-material_5"
                                  geometry={
                                    nodes["xEINmDJHJtyYSZS_001-material_5"]
                                      .geometry
                                  }
                                  material={materials["xEINmDJHJtyYSZS.001"]}
                                />
                              </group>
                              <group name="ZpgCReFVOvUDJpp">
                                <mesh
                                  name="EtFgaXCoUdBMdxZ_001-material_10"
                                  geometry={
                                    nodes["EtFgaXCoUdBMdxZ_001-material_10"]
                                      .geometry
                                  }
                                  material={materials["EtFgaXCoUdBMdxZ.001"]}
                                />
                              </group>
                              <group name="ZQVtsPAFxDebNuL">
                                <mesh
                                  name="LDbhHgKqQwnfIdk_001-material_3"
                                  geometry={
                                    nodes["LDbhHgKqQwnfIdk_001-material_3"]
                                      .geometry
                                  }
                                  material={materials["LDbhHgKqQwnfIdk.001"]}
                                />
                              </group>
                              <group name="zYZWzUEKpjcTrvk">
                                <mesh
                                  name="EtFgaXCoUdBMdxZ_001-material_11"
                                  geometry={
                                    nodes["EtFgaXCoUdBMdxZ_001-material_11"]
                                      .geometry
                                  }
                                  material={materials["EtFgaXCoUdBMdxZ.001"]}
                                />
                              </group>
                            </group>
                            <group name="ivnjPtTfPfpvSbU">
                              <group name="DnWAzaucFGrthpo">
                                <mesh
                                  name="EtFgaXCoUdBMdxZ_001-material_12"
                                  geometry={
                                    nodes["EtFgaXCoUdBMdxZ_001-material_12"]
                                      .geometry
                                  }
                                  material={materials["EtFgaXCoUdBMdxZ.001"]}
                                />
                              </group>
                              <group name="dPLwWSxdcciyxmu">
                                <mesh
                                  name="LuFFEBDynGcfeVH_001-material_1"
                                  geometry={
                                    nodes["LuFFEBDynGcfeVH_001-material_1"]
                                      .geometry
                                  }
                                  material={materials["LuFFEBDynGcfeVH.001"]}
                                />
                              </group>
                              <group name="elUnqBQrWytNdpf">
                                <mesh
                                  name="LDbhHgKqQwnfIdk_001-material_4"
                                  geometry={
                                    nodes["LDbhHgKqQwnfIdk_001-material_4"]
                                      .geometry
                                  }
                                  material={materials["LDbhHgKqQwnfIdk.001"]}
                                />
                              </group>
                              <group name="iGYybdIjmErDUHC">
                                <mesh
                                  name="EtFgaXCoUdBMdxZ_001-material_13"
                                  geometry={
                                    nodes["EtFgaXCoUdBMdxZ_001-material_13"]
                                      .geometry
                                  }
                                  material={materials["EtFgaXCoUdBMdxZ.001"]}
                                />
                              </group>
                              <group name="jGNQPlLRydSeyDk">
                                <mesh
                                  name="LDbhHgKqQwnfIdk_001-material_5"
                                  geometry={
                                    nodes["LDbhHgKqQwnfIdk_001-material_5"]
                                      .geometry
                                  }
                                  material={materials["LDbhHgKqQwnfIdk.001"]}
                                />
                              </group>
                              <group name="kZpwowZNNgVNwLs">
                                <mesh
                                  name="saRNhuLSrFYuedf_001-material_2"
                                  geometry={
                                    nodes["saRNhuLSrFYuedf_001-material_2"]
                                      .geometry
                                  }
                                  material={materials["saRNhuLSrFYuedf.001"]}
                                />
                              </group>
                              <group name="LcIinDmJMSOJcei">
                                <mesh
                                  name="yfSRCfyMalEYgbO_001-material_4"
                                  geometry={
                                    nodes["yfSRCfyMalEYgbO_001-material_4"]
                                      .geometry
                                  }
                                  material={materials["yfSRCfyMalEYgbO.001"]}
                                />
                              </group>
                              <group name="pxczByPYPsLpISN">
                                <mesh
                                  name="NOlmOyvNOoXnkpD_001-material_1"
                                  geometry={
                                    nodes["NOlmOyvNOoXnkpD_001-material_1"]
                                      .geometry
                                  }
                                  material={materials["NOlmOyvNOoXnkpD.001"]}
                                />
                              </group>
                              <group name="qgwQFTuOVZpjKqj">
                                <mesh
                                  name="qYATvTCUqTRIxZo_001-material_1"
                                  geometry={
                                    nodes["qYATvTCUqTRIxZo_001-material_1"]
                                      .geometry
                                  }
                                  material={materials["qYATvTCUqTRIxZo.001"]}
                                />
                              </group>
                              <group name="QugujJfRokGBRvt">
                                <mesh
                                  name="EWuRzSMoXPhEpsh_001-material_2"
                                  geometry={
                                    nodes["EWuRzSMoXPhEpsh_001-material_2"]
                                      .geometry
                                  }
                                  material={materials["EWuRzSMoXPhEpsh.001"]}
                                />
                              </group>
                              <group name="TwpJxeyQaZKhOIa">
                                <mesh
                                  name="EtFgaXCoUdBMdxZ_001-material_14"
                                  geometry={
                                    nodes["EtFgaXCoUdBMdxZ_001-material_14"]
                                      .geometry
                                  }
                                  material={materials["EtFgaXCoUdBMdxZ.001"]}
                                />
                              </group>
                              <group name="UWAOtrCfzfMyJTC">
                                <mesh
                                  name="xEINmDJHJtyYSZS_001-material_6"
                                  geometry={
                                    nodes["xEINmDJHJtyYSZS_001-material_6"]
                                      .geometry
                                  }
                                  material={materials["xEINmDJHJtyYSZS.001"]}
                                />
                              </group>
                              <group name="UWpcpqDYmPREwxB">
                                <mesh
                                  name="yfSRCfyMalEYgbO_001-material_5"
                                  geometry={
                                    nodes["yfSRCfyMalEYgbO_001-material_5"]
                                      .geometry
                                  }
                                  material={materials["yfSRCfyMalEYgbO.001"]}
                                />
                              </group>
                            </group>
                            <group name="KsjIyrtgusmiEbo">
                              <group name="KomCstxUReEAMAm">
                                <mesh
                                  name="EtFgaXCoUdBMdxZ_001-material_15"
                                  geometry={
                                    nodes["EtFgaXCoUdBMdxZ_001-material_15"]
                                      .geometry
                                  }
                                  material={materials["EtFgaXCoUdBMdxZ.001"]}
                                />
                              </group>
                              <group name="MgYYCjCFEiRJHSx">
                                <mesh
                                  name="PqdtqVEhAPzytes_001-material"
                                  geometry={
                                    nodes["PqdtqVEhAPzytes_001-material"]
                                      .geometry
                                  }
                                  material={materials["PqdtqVEhAPzytes.001"]}
                                />
                              </group>
                              <group name="WaKvOomqveGscfs">
                                <mesh
                                  name="PqdtqVEhAPzytes_001-material_1"
                                  geometry={
                                    nodes["PqdtqVEhAPzytes_001-material_1"]
                                      .geometry
                                  }
                                  material={materials["PqdtqVEhAPzytes.001"]}
                                />
                              </group>
                            </group>
                            <group name="nSAJWaALmxJwOmE">
                              <group name="bzLRygCDgcXaWuO">
                                <mesh
                                  name="xwVmDXUVpcqUaxI_001-material"
                                  geometry={
                                    nodes["xwVmDXUVpcqUaxI_001-material"]
                                      .geometry
                                  }
                                  material={materials["xwVmDXUVpcqUaxI.001"]}
                                />
                              </group>
                              <group name="gGILXremswJnRzY">
                                <mesh
                                  name="GvMXDHTSaTFmkSJ_001-material"
                                  geometry={
                                    nodes["GvMXDHTSaTFmkSJ_001-material"]
                                      .geometry
                                  }
                                  material={materials["GvMXDHTSaTFmkSJ.001"]}
                                />
                              </group>
                              <group name="ToqTqpNmMWOflVY">
                                <mesh
                                  name="AwYbcqVpoWkGbAQ_001-material"
                                  geometry={
                                    nodes["AwYbcqVpoWkGbAQ_001-material"]
                                      .geometry
                                  }
                                  material={materials["AwYbcqVpoWkGbAQ.001"]}
                                />
                              </group>
                              <group name="XeLxZaPZvbWszxX">
                                <mesh
                                  name="AwYbcqVpoWkGbAQ_001-material_1"
                                  geometry={
                                    nodes["AwYbcqVpoWkGbAQ_001-material_1"]
                                      .geometry
                                  }
                                  material={materials["AwYbcqVpoWkGbAQ.001"]}
                                />
                              </group>
                            </group>
                            <group name="onwlnwuBbkBjvcz">
                              <group name="BtfNAzIMbxXMtnh">
                                <mesh
                                  name="LDbhHgKqQwnfIdk_001-material_6"
                                  geometry={
                                    nodes["LDbhHgKqQwnfIdk_001-material_6"]
                                      .geometry
                                  }
                                  material={materials["LDbhHgKqQwnfIdk.001"]}
                                />
                              </group>
                              <group name="fQMDkHMpWDgvzKr">
                                <mesh
                                  name="yfSRCfyMalEYgbO_001-material_6"
                                  geometry={
                                    nodes["yfSRCfyMalEYgbO_001-material_6"]
                                      .geometry
                                  }
                                  material={materials["yfSRCfyMalEYgbO.001"]}
                                />
                              </group>
                              <group name="hOUqKLAruTGUTLT">
                                <mesh
                                  name="EtFgaXCoUdBMdxZ_001-material_16"
                                  geometry={
                                    nodes["EtFgaXCoUdBMdxZ_001-material_16"]
                                      .geometry
                                  }
                                  material={materials["EtFgaXCoUdBMdxZ.001"]}
                                />
                              </group>
                              <group name="KHNtgbfgcggVeiY">
                                <mesh
                                  name="yfSRCfyMalEYgbO_001-material_7"
                                  geometry={
                                    nodes["yfSRCfyMalEYgbO_001-material_7"]
                                      .geometry
                                  }
                                  material={materials["yfSRCfyMalEYgbO.001"]}
                                />
                              </group>
                              <group name="KvXDfxXjuJBvVSm">
                                <mesh
                                  name="LuFFEBDynGcfeVH_001-material_2"
                                  geometry={
                                    nodes["LuFFEBDynGcfeVH_001-material_2"]
                                      .geometry
                                  }
                                  material={materials["LuFFEBDynGcfeVH.001"]}
                                />
                              </group>
                              <group name="LRlFPIQzvNsOSMo">
                                <mesh
                                  name="xEINmDJHJtyYSZS_001-material_7"
                                  geometry={
                                    nodes["xEINmDJHJtyYSZS_001-material_7"]
                                      .geometry
                                  }
                                  material={materials["xEINmDJHJtyYSZS.001"]}
                                />
                              </group>
                              <group name="mdmRieQUrrrzrBR">
                                <mesh
                                  name="qYATvTCUqTRIxZo_001-material_2"
                                  geometry={
                                    nodes["qYATvTCUqTRIxZo_001-material_2"]
                                      .geometry
                                  }
                                  material={materials["qYATvTCUqTRIxZo.001"]}
                                />
                              </group>
                              <group name="ohSmopyQnlZcApi">
                                <mesh
                                  name="EtFgaXCoUdBMdxZ_001-material_17"
                                  geometry={
                                    nodes["EtFgaXCoUdBMdxZ_001-material_17"]
                                      .geometry
                                  }
                                  material={materials["EtFgaXCoUdBMdxZ.001"]}
                                />
                              </group>
                              <group name="OyfjdyuTDqNRFgA">
                                <mesh
                                  name="LDbhHgKqQwnfIdk_001-material_7"
                                  geometry={
                                    nodes["LDbhHgKqQwnfIdk_001-material_7"]
                                      .geometry
                                  }
                                  material={materials["LDbhHgKqQwnfIdk.001"]}
                                />
                              </group>
                              <group name="pAXmiFuNCvfvvot">
                                <mesh
                                  name="NOlmOyvNOoXnkpD_001-material_2"
                                  geometry={
                                    nodes["NOlmOyvNOoXnkpD_001-material_2"]
                                      .geometry
                                  }
                                  material={materials["NOlmOyvNOoXnkpD.001"]}
                                />
                              </group>
                              <group name="pSvGltuRQTaTwru">
                                <mesh
                                  name="EtFgaXCoUdBMdxZ_001-material_18"
                                  // geometry={
                                  //   nodes["EtFgaXCoUdBMdxZ_001-material_18"]
                                  //     .geometry
                                  // }
                                  material={materials["EtFgaXCoUdBMdxZ.001"]}
                                />
                              </group>
                              <group name="RRBztvWgjOVDtio">
                                <mesh
                                  name="saRNhuLSrFYuedf_001-material_3"
                                  geometry={
                                    nodes["saRNhuLSrFYuedf_001-material_3"]
                                      .geometry
                                  }
                                  material={materials["saRNhuLSrFYuedf.001"]}
                                />
                              </group>
                              <group name="UZzJivSmgyHnnoA">
                                <mesh
                                  name="LDbhHgKqQwnfIdk_001-material_8"
                                  geometry={
                                    nodes["LDbhHgKqQwnfIdk_001-material_8"]
                                      .geometry
                                  }
                                  material={materials["LDbhHgKqQwnfIdk.001"]}
                                />
                              </group>
                              <group name="VMbWUIBJOeqTorB">
                                <mesh
                                  name="NOlmOyvNOoXnkpD_001-material_3"
                                  geometry={
                                    nodes["NOlmOyvNOoXnkpD_001-material_3"]
                                      .geometry
                                  }
                                  material={materials["NOlmOyvNOoXnkpD.001"]}
                                />
                              </group>
                              <group name="wEroORzXEpeBMUr">
                                <mesh
                                  name="EWuRzSMoXPhEpsh_001-material_3"
                                  geometry={
                                    nodes["EWuRzSMoXPhEpsh_001-material_3"]
                                      .geometry
                                  }
                                  material={materials["EWuRzSMoXPhEpsh.001"]}
                                />
                              </group>
                              <group name="ZGblmRAixwSYeaI">
                                <mesh
                                  name="NOlmOyvNOoXnkpD_001-material_4"
                                  geometry={
                                    nodes["NOlmOyvNOoXnkpD_001-material_4"]
                                      .geometry
                                  }
                                  material={materials["NOlmOyvNOoXnkpD.001"]}
                                />
                              </group>
                            </group>
                          </group>
                          <group name="XJReQzSHujcYUen">
                            <group name="nsHWlVFHOhVBScU">
                              <mesh
                                name="DkAqxRJaVVfbpgF_001-material_1"
                                geometry={
                                  nodes["DkAqxRJaVVfbpgF_001-material_1"]
                                    .geometry
                                }
                                material={materials["DkAqxRJaVVfbpgF.001"]}
                              />
                            </group>
                            <group name="XmrjKHJiFgGtfgP">
                              <mesh
                                name="ydaDdTqttaoNyqb_001-material"
                                geometry={
                                  nodes["ydaDdTqttaoNyqb_001-material"].geometry
                                }
                                material={materials["ydaDdTqttaoNyqb.001"]}
                              />
                            </group>
                          </group>
                        </group>
                        <group name="vxFaaBrHaMtSazz">
                          <group name="ckNCRqyUnZfmfqa">
                            <mesh
                              name="ZREBupwLYjgDWEr_001-material"
                              geometry={
                                nodes["ZREBupwLYjgDWEr_001-material"].geometry
                              }
                              material={materials["ZREBupwLYjgDWEr.001"]}
                            />
                          </group>
                          <group name="dyyhvtVPOrVvZrn">
                            <mesh
                              name="McqOaNTsOxXDHPN_001-material"
                              geometry={
                                nodes["McqOaNTsOxXDHPN_001-material"].geometry
                              }
                              material={materials["McqOaNTsOxXDHPN.001"]}
                            />
                          </group>
                          <group name="GjwIJnPKhwUCjPx">
                            <mesh
                              name="EtFgaXCoUdBMdxZ_001-material_1"
                              geometry={
                                nodes["EtFgaXCoUdBMdxZ_001-material_1"].geometry
                              }
                              material={materials["EtFgaXCoUdBMdxZ.001"]}
                            />
                          </group>
                          <group name="ikunlVfBKaSoRqC">
                            <group name="AfMqeobIryTZXTq">
                              <mesh
                                name="vVGvlgWlVeyuRGd_001-material"
                                geometry={
                                  nodes["vVGvlgWlVeyuRGd_001-material"].geometry
                                }
                                material={materials["vVGvlgWlVeyuRGd.001"]}
                              />
                            </group>
                            <group name="blnbwndqwpyMKUH">
                              <mesh
                                name="LYzqufVZtJPAOrK_001-material"
                                geometry={
                                  nodes["LYzqufVZtJPAOrK_001-material"].geometry
                                }
                                material={materials["LYzqufVZtJPAOrK.001"]}
                              />
                            </group>
                            <group name="ordeALaXtVeferX">
                              <mesh
                                name="pcJKaGFbWeBZXrD_001-material"
                                geometry={
                                  nodes["pcJKaGFbWeBZXrD_001-material"].geometry
                                }
                                material={materials["pcJKaGFbWeBZXrD.001"]}
                              />
                            </group>
                            <group name="qmRaQcmiukWzkFl">
                              <mesh
                                name="eayVjkFqRlYoCjo_001-material"
                                geometry={
                                  nodes["eayVjkFqRlYoCjo_001-material"].geometry
                                }
                                material={materials["eayVjkFqRlYoCjo.001"]}
                              />
                            </group>
                            <group name="sMqKXdhmfqMCcaM">
                              <mesh
                                name="EtFgaXCoUdBMdxZ_001-material_3"
                                geometry={
                                  nodes["EtFgaXCoUdBMdxZ_001-material_3"]
                                    .geometry
                                }
                                material={materials["EtFgaXCoUdBMdxZ.001"]}
                              />
                            </group>
                            <group name="smsbqZudYDccvCk">
                              <mesh
                                name="RmoEuFpVgpnfYAR_001-material"
                                geometry={
                                  nodes["RmoEuFpVgpnfYAR_001-material"].geometry
                                }
                                material={materials["RmoEuFpVgpnfYAR.001"]}
                              />
                            </group>
                            <group name="tveXszwBLpUZaFp">
                              <mesh
                                name="LYzqufVZtJPAOrK_001-material_1"
                                geometry={
                                  nodes["LYzqufVZtJPAOrK_001-material_1"]
                                    .geometry
                                }
                                material={materials["LYzqufVZtJPAOrK.001"]}
                              />
                            </group>
                            <group name="uuaGIqCKQxugiGl">
                              <mesh
                                name="eVzEOxVmiJRhSFf_001-material_1"
                                geometry={
                                  nodes["eVzEOxVmiJRhSFf_001-material_1"]
                                    .geometry
                                }
                                material={materials["eVzEOxVmiJRhSFf.001"]}
                              />
                            </group>
                            <group name="vqGoaFNyiGroenU">
                              <mesh
                                name="xKVYQnHEpGkCkNs_001-material"
                                geometry={
                                  nodes["xKVYQnHEpGkCkNs_001-material"].geometry
                                }
                                material={materials["xKVYQnHEpGkCkNs.001"]}
                              />
                            </group>
                            <group name="WwxLjjCOAeQOdYU">
                              <mesh
                                name="LYzqufVZtJPAOrK_001-material_2"
                                geometry={
                                  nodes["LYzqufVZtJPAOrK_001-material_2"]
                                    .geometry
                                }
                                material={materials["LYzqufVZtJPAOrK.001"]}
                              />
                            </group>
                            <group name="yYVfOnKpHehHzSY">
                              <mesh
                                name="LYzqufVZtJPAOrK_001-material_3"
                                geometry={
                                  nodes["LYzqufVZtJPAOrK_001-material_3"]
                                    .geometry
                                }
                                material={materials["LYzqufVZtJPAOrK.001"]}
                              />
                            </group>
                          </group>
                          <group name="jTaBqxCtBlEjGEM">
                            <mesh
                              name="eVzEOxVmiJRhSFf_001-material"
                              geometry={
                                nodes["eVzEOxVmiJRhSFf_001-material"].geometry
                              }
                              material={materials["eVzEOxVmiJRhSFf.001"]}
                            />
                          </group>
                          <group name="lkonPIiqDLmPvKN">
                            <mesh
                              name="NRKgvZEGjdqRXhC_001-material"
                              geometry={
                                nodes["NRKgvZEGjdqRXhC_001-material"].geometry
                              }
                              material={materials["NRKgvZEGjdqRXhC.001"]}
                            />
                          </group>
                          <group name="LZjJAYVJDAmktIj">
                            <mesh
                              name="UpJorJMRrcMMGfL_001-material"
                              geometry={
                                nodes["UpJorJMRrcMMGfL_001-material"].geometry
                              }
                              material={materials["UpJorJMRrcMMGfL.001"]}
                            />
                          </group>
                          <group name="MEdknsCyRNLALyc">
                            <group name="aNVflhWEMCyvCyD">
                              <mesh
                                name="xEINmDJHJtyYSZS_001-material"
                                geometry={
                                  nodes["xEINmDJHJtyYSZS_001-material"].geometry
                                }
                                material={materials["xEINmDJHJtyYSZS.001"]}
                              />
                            </group>
                            <group name="bdNtpOAzGkhKgtR">
                              <mesh
                                name="xEINmDJHJtyYSZS_001-material_1"
                                geometry={
                                  nodes["xEINmDJHJtyYSZS_001-material_1"]
                                    .geometry
                                }
                                material={materials["xEINmDJHJtyYSZS.001"]}
                              />
                            </group>
                            <group name="DmTWSqMrgRNJlJq">
                              <mesh
                                name="UpJorJMRrcMMGfL_001-material_1"
                                geometry={
                                  nodes["UpJorJMRrcMMGfL_001-material_1"]
                                    .geometry
                                }
                                material={materials["UpJorJMRrcMMGfL.001"]}
                              />
                            </group>
                            <group name="DzAhorDdFkHfLCw">
                              <group name="awdJnuUIRSsFpzr">
                                <mesh
                                  name="UpJorJMRrcMMGfL_001-material_4"
                                  geometry={
                                    nodes["UpJorJMRrcMMGfL_001-material_4"]
                                      .geometry
                                  }
                                  material={materials["UpJorJMRrcMMGfL.001"]}
                                />
                              </group>
                              <group name="tEWoXcLTcKoZOXK">
                                <mesh
                                  name="xEINmDJHJtyYSZS_001-material_8"
                                  geometry={
                                    nodes["xEINmDJHJtyYSZS_001-material_8"]
                                      .geometry
                                  }
                                  material={materials["xEINmDJHJtyYSZS.001"]}
                                />
                              </group>
                            </group>
                            <group name="hdQHPCNmtCJdgdh">
                              <mesh
                                name="EtFgaXCoUdBMdxZ_001-material_4"
                                geometry={
                                  nodes["EtFgaXCoUdBMdxZ_001-material_4"]
                                    .geometry
                                }
                                material={materials["EtFgaXCoUdBMdxZ.001"]}
                              />
                            </group>
                            <group name="jrugfLznxfmnVOO">
                              <mesh
                                name="UpJorJMRrcMMGfL_001-material_2"
                                geometry={
                                  nodes["UpJorJMRrcMMGfL_001-material_2"]
                                    .geometry
                                }
                                material={materials["UpJorJMRrcMMGfL.001"]}
                              />
                            </group>
                            <group name="LQArApnYMGhEARb">
                              <mesh
                                name="UpJorJMRrcMMGfL_001-material_3"
                                geometry={
                                  nodes["UpJorJMRrcMMGfL_001-material_3"]
                                    .geometry
                                }
                                material={materials["UpJorJMRrcMMGfL.001"]}
                              />
                            </group>
                            <group name="OuKGxhNxqWxcwoz">
                              <mesh
                                name="xEINmDJHJtyYSZS_001-material_2"
                                geometry={
                                  nodes["xEINmDJHJtyYSZS_001-material_2"]
                                    .geometry
                                }
                                material={materials["xEINmDJHJtyYSZS.001"]}
                              />
                            </group>
                          </group>
                          <group name="rpJxNkRnVJaJkBQ">
                            <mesh
                              name="jCGtFgFjpibdIdA_001-material"
                              geometry={
                                nodes["jCGtFgFjpibdIdA_001-material"].geometry
                              }
                              material={materials["jCGtFgFjpibdIdA.001"]}
                            />
                          </group>
                          <group name="VTpKnRwEsVjBfOM">
                            <group name="BBfdlMxfuJdGbgq">
                              <mesh
                                name="xEINmDJHJtyYSZS_001-material_3"
                                geometry={
                                  nodes["xEINmDJHJtyYSZS_001-material_3"]
                                    .geometry
                                }
                                material={materials["xEINmDJHJtyYSZS.001"]}
                              />
                            </group>
                            <group name="HmewNNGbtxpHXbp">
                              <mesh
                                name="jCGtFgFjpibdIdA_001-material_1"
                                geometry={
                                  nodes["jCGtFgFjpibdIdA_001-material_1"]
                                    .geometry
                                }
                                material={materials["jCGtFgFjpibdIdA.001"]}
                              />
                            </group>
                            <group name="MjKoCOqQqbRCIpB">
                              <mesh
                                name="jCGtFgFjpibdIdA_001-material_2"
                                geometry={
                                  nodes["jCGtFgFjpibdIdA_001-material_2"]
                                    .geometry
                                }
                                material={materials["jCGtFgFjpibdIdA.001"]}
                              />
                            </group>
                            <group name="NwxpiwXkRZhtiVF">
                              <mesh
                                name="xKVYQnHEpGkCkNs_001-material_1"
                                geometry={
                                  nodes["xKVYQnHEpGkCkNs_001-material_1"]
                                    .geometry
                                }
                                material={materials["xKVYQnHEpGkCkNs.001"]}
                              />
                            </group>
                            <group name="OxMoLLXPgiFIODR">
                              <mesh
                                name="xEINmDJHJtyYSZS_001-material_4"
                                geometry={
                                  nodes["xEINmDJHJtyYSZS_001-material_4"]
                                    .geometry
                                }
                                material={materials["xEINmDJHJtyYSZS.001"]}
                              />
                            </group>
                            <group name="qsCNRyAyHrwHSns">
                              <mesh
                                name="awHiRXJGHDDsZAe_001-material"
                                geometry={
                                  nodes["awHiRXJGHDDsZAe_001-material"].geometry
                                }
                                material={materials["awHiRXJGHDDsZAe.001"]}
                              />
                            </group>
                            <group name="zIGhbhvolNHdNKj">
                              <mesh
                                name="saRNhuLSrFYuedf_001-material"
                                geometry={
                                  nodes["saRNhuLSrFYuedf_001-material"].geometry
                                }
                                material={materials["saRNhuLSrFYuedf.001"]}
                              />
                            </group>
                          </group>
                        </group>
                        {/* /////////////
                      //////// */}
                        <group name="ZtLUNCGCyzhDwkv">
                          {/* /////////////
                      //////// */}
                          <group name="ApkMXQOkcerDupM">
                            <group name="DjojexCCBKxptBe">
                              <mesh
                                name="OGleMDoIcQFdDNP_001-material_2"
                                geometry={
                                  nodes["OGleMDoIcQFdDNP_001-material_2"]
                                    .geometry
                                }
                                material={materials["OGleMDoIcQFdDNP.001"]}
                              />
                            </group>
                            <group name="DpVAWatdLYuGlPX">
                              <mesh
                                name="EtFgaXCoUdBMdxZ_001-material_5"
                                geometry={
                                  nodes["EtFgaXCoUdBMdxZ_001-material_5"]
                                    .geometry
                                }
                                material={materials["EtFgaXCoUdBMdxZ.001"]}
                              />
                            </group>
                            <group name="gVeyhdlZhPkpdtv">
                              <mesh
                                name="OGleMDoIcQFdDNP_001-material_3"
                                geometry={
                                  nodes["OGleMDoIcQFdDNP_001-material_3"]
                                    .geometry
                                }
                                material={materials["OGleMDoIcQFdDNP.001"]}
                              />
                            </group>
                            <group name="hWRqOtixdkWIKku">
                              <mesh
                                name="OGleMDoIcQFdDNP_001-material_4"
                                geometry={
                                  nodes["OGleMDoIcQFdDNP_001-material_4"]
                                    .geometry
                                }
                                material={materials["OGleMDoIcQFdDNP.001"]}
                              />
                            </group>
                            <group name="JFKrYLDiNKctyOc">
                              <mesh
                                name="EtFgaXCoUdBMdxZ_001-material_6"
                                geometry={
                                  nodes["EtFgaXCoUdBMdxZ_001-material_6"]
                                    .geometry
                                }
                                material={materials["EtFgaXCoUdBMdxZ.001"]}
                              />
                            </group>
                            <group name="MABsySGimfJjlaN">
                              <mesh
                                name="OGleMDoIcQFdDNP_001-material_5"
                                geometry={
                                  nodes["OGleMDoIcQFdDNP_001-material_5"]
                                    .geometry
                                }
                                material={materials["OGleMDoIcQFdDNP.001"]}
                              />
                            </group>
                            <group name="MQGHZcYtzpmRCej">
                              <mesh
                                name="OGleMDoIcQFdDNP_001-material_6"
                                geometry={
                                  nodes["OGleMDoIcQFdDNP_001-material_6"]
                                    .geometry
                                }
                                material={materials["OGleMDoIcQFdDNP.001"]}
                              />
                            </group>
                            {/* /////////////
                      //////// */}
                            <group name="RXcHLWDxUoNnsOH">
                              <mesh
                                name="GmWBXngFrYhvbdO_001-material"
                                geometry={
                                  nodes["GmWBXngFrYhvbdO_001-material"].geometry
                                }
                                material={materials["GmWBXngFrYhvbdO.001"]}
                              >
                                {" "}
                                <Decal
                                  position={[-1.5, 0.62, 0]}
                                  rotation={[0, 0.0, 0]}
                                  scale={scale}
                                >
                                  <meshBasicMaterial
                                    map={useTexture(textureUrl)}
                                    polygonOffset
                                    polygonOffsetFactor={-1}
                                    toneMapped={false}
                                    // onUpdate={(self) =>
                                    //   (self.needsUpdate = true)
                                    // }
                                  />
                                </Decal>
                              </mesh>
                            </group>
                            {/* //////
                        ///// */}
                            <group name="XdktQtZljcAtNVS">
                              <mesh
                                name="iWiAPFztgBDxNan_001-material"
                                geometry={
                                  nodes["iWiAPFztgBDxNan_001-material"].geometry
                                }
                                material={materials["iWiAPFztgBDxNan.001"]}
                              ></mesh>
                            </group>
                            <group name="xycqxOINGKvBrhK">
                              <mesh
                                name="iWiAPFztgBDxNan_001-material_1"
                                geometry={
                                  nodes["iWiAPFztgBDxNan_001-material_1"]
                                    .geometry
                                }
                                material={materials["iWiAPFztgBDxNan.001"]}
                              />
                            </group>
                          </group>
                          {/* /////////////
                      //////// */}
                          <group name="CbwUsqydXEwwwXZ">
                            <mesh
                              name="OGleMDoIcQFdDNP_001-material"
                              geometry={
                                nodes["OGleMDoIcQFdDNP_001-material"].geometry
                              }
                              material={materials["OGleMDoIcQFdDNP.001"]}
                            />
                          </group>
                          <group name="gefbsxrRWnYAEqE">
                            <mesh
                              name="tUIxbALjcklqMLh_001-material"
                              geometry={
                                nodes["tUIxbALjcklqMLh_001-material"].geometry
                              }
                              material={materials["tUIxbALjcklqMLh.001"]}
                            />
                          </group>
                          <group name="mokhKQWVJBdSLne">
                            <mesh
                              name="OGleMDoIcQFdDNP_001-material_1"
                              geometry={
                                nodes["OGleMDoIcQFdDNP_001-material_1"].geometry
                              }
                              material={materials["OGleMDoIcQFdDNP.001"]}
                            />
                          </group>
                          <group name="yTFJUEJJRCIZltP">
                            <group name="GXWqwtKSgtzjaYb">
                              <mesh
                                name="LDbhHgKqQwnfIdk_001-material"
                                geometry={
                                  nodes["LDbhHgKqQwnfIdk_001-material"].geometry
                                }
                                material={materials["LDbhHgKqQwnfIdk.001"]}
                              />
                            </group>
                            <group name="inEuMCtsCWGVqEG">
                              <mesh
                                name="LDbhHgKqQwnfIdk_001-material_1"
                                geometry={
                                  nodes["LDbhHgKqQwnfIdk_001-material_1"]
                                    .geometry
                                }
                                material={materials["LDbhHgKqQwnfIdk.001"]}
                              />
                            </group>
                            <group name="MGVLUSWxMmioRDb">
                              <mesh
                                name="EtFgaXCoUdBMdxZ_001-material_7"
                                geometry={
                                  nodes["EtFgaXCoUdBMdxZ_001-material_7"]
                                    .geometry
                                }
                                material={materials["EtFgaXCoUdBMdxZ.001"]}
                              />
                            </group>
                            <group name="niRCnFaBvDoTEhp">
                              <mesh
                                name="EWuRzSMoXPhEpsh_001-material"
                                geometry={
                                  nodes["EWuRzSMoXPhEpsh_001-material"].geometry
                                }
                                material={materials["EWuRzSMoXPhEpsh.001"]}
                              />
                            </group>
                            <group name="WzelDFwkrXNlzhL">
                              <mesh
                                name="MeQJBtEiHnDpYBD_001-material"
                                geometry={
                                  nodes["MeQJBtEiHnDpYBD_001-material"].geometry
                                }
                                material={materials["MeQJBtEiHnDpYBD.001"]}
                              />
                            </group>
                          </group>
                        </group>
                        {/* //////////////
                        ///////// */}
                      </group>
                    </group>
                  </group>
                </group>
              </group>
            </group>
          </group>
        </group>
      </group>
      {/* <mesh material={materials.mat17} position={[-1.5, 0.7, -0.3]} scale={10}>
        <boxGeometry attach="geometry" args={[0.7, 1.35, 0]} />
        <meshBasicMaterial map={textureUrl} />
      </mesh> */}
    </group>
  );
}

useGLTF.preload("/models/Iphone.glb");
