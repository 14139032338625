/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 public/models/Phone.glb -o src/phone.js -k -K -r public 
*/

import React, { useRef, useState } from "react";
import { useGLTF, useTexture, Html, Text } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";
import { Vector3, MeshBasicMaterial } from "three";
import FontPicker from "font-picker-react";

export function Phone(props) {
  const { nodes, materials } = useGLTF("/models/Phone.glb");

  const texture = useTexture(props.url);

  const phone_ref = useRef();
  useFrame((state, delta) => {
    {
      phone_ref.current.rotation.y =
        Math.PI + Math.sin(state.clock.elapsedTime / 6) / 4 - 0.1;
      phone_ref.current.position.y = Math.sin(state.clock.elapsedTime * 2) / 40;
    }
  });

  return (
    <group ref={phone_ref} {...props} dispose={null} scale={3.6}>
      <group>
        <group name="group817659832">
          <mesh
            name="mesh817659832"
            geometry={nodes.mesh817659832.geometry}
            material={materials.mat16}
          />
          <mesh
            name="mesh817659832_1"
            geometry={nodes.mesh817659832_1.geometry}
            material={materials.mat23}
          />
          <mesh
            name="mesh817659832_2"
            geometry={nodes.mesh817659832_2.geometry}
            material={materials.mat17}
          />
        </group>
        <mesh
          name="group40103501"
          geometry={nodes.group40103501.geometry}
          material={materials.mat23}
        />
        <mesh
          name="group465359774"
          geometry={nodes.group465359774.geometry}
          material={materials.mat17}
        />
        <mesh
          name="group1120752718"
          geometry={nodes.group1120752718.geometry}
          material={materials.mat17}
        />
        <mesh
          name="group1884584545"
          geometry={nodes.group1884584545.geometry}
          material={materials.mat23}
        />
        <group name="group641155211">
          <mesh
            name="mesh641155211"
            geometry={nodes.mesh641155211.geometry}
            material={materials.mat16}
          />
          <mesh
            name="mesh641155211_1"
            geometry={nodes.mesh641155211_1.geometry}
            material={materials.mat8}
          />
        </group>
        <mesh
          name="group1224932749"
          geometry={nodes.group1224932749.geometry}
          material={materials.mat17}
        />
        <mesh
          name="group849457402"
          geometry={nodes.group849457402.geometry}
          material={materials.mat17}
        />
        <mesh
          name="group1152579848"
          geometry={nodes.group1152579848.geometry}
          material={materials.mat17}
        />
        <mesh
          name="group1637273763"
          geometry={nodes.group1637273763.geometry}
          material={materials.mat17}
        />
        <mesh
          name="group660411554"
          geometry={nodes.group660411554.geometry}
          material={materials.mat23}
        />
        <group name="group1674681057">
          <mesh
            name="mesh1674681057"
            geometry={nodes.mesh1674681057.geometry}
            material={materials.mat24}
          />
          <mesh
            name="mesh1674681057_1"
            geometry={nodes.mesh1674681057_1.geometry}
            material={materials.mat25}
          />
          <mesh
            name="mesh1674681057_2"
            geometry={nodes.mesh1674681057_2.geometry}
            material={materials.mat5}
          />
        </group>
        <mesh
          name="group31295272"
          geometry={nodes.group31295272.geometry}
          material={materials.mat23}
        />
        <mesh
          name="group1982349354"
          geometry={nodes.group1982349354.geometry}
          material={materials.mat25}
        />
        <mesh
          name="group2136648518"
          geometry={nodes.group2136648518.geometry}
          material={materials.mat24}
        />
        <mesh
          name="group1909018237"
          geometry={nodes.group1909018237.geometry}
          material={materials.mat24}
        />
        <mesh
          name="group1638188736"
          geometry={nodes.group1638188736.geometry}
          material={materials.mat23}
        />
        <mesh
          name="group1013736083"
          geometry={nodes.group1013736083.geometry}
          material={materials.mat15}
        />
        <mesh
          name="group1124957205"
          geometry={nodes.group1124957205.geometry}
          material={materials.mat15}
        />
        <mesh
          name="group1113113092"
          geometry={nodes.group1113113092.geometry}
          material={materials.mat23}
        />
        <mesh
          name="group15167646"
          geometry={nodes.group15167646.geometry}
          material={materials.mat23}
        />
        <mesh
          name="group291280508"
          geometry={nodes.group291280508.geometry}
          material={materials.mat23}
        />
        <mesh
          name="group261416222"
          geometry={nodes.group261416222.geometry}
          material={materials.mat23}
        />
        <mesh
          name="group1910001921"
          geometry={nodes.group1910001921.geometry}
          material={materials.mat23}
        />
        <mesh
          name="group1251928019"
          geometry={nodes.group1251928019.geometry}
          material={materials.mat23}
        />
        <mesh
          name="group1821786415"
          geometry={nodes.group1821786415.geometry}
          material={materials.mat23}
        />
        <mesh
          name="group1896686979"
          geometry={nodes.group1896686979.geometry}
          material={materials.mat23}
        />
      </group>
      <mesh material={materials.mat17} position={[-0.005, 0.05, -0.073]}>
        <boxGeometry attach="geometry" args={[0.7, 1.2, 0]} />
        <meshBasicMaterial map={texture} />
      </mesh>
    </group>
  );
}

useGLTF.preload("/models/Phone.glb");
