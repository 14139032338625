import React, { useEffect, useState } from "react";
import "./Section_3.css";
import Sign_in from "./Sign_in";
import Log_in from "./Log_in";
import { getEmailFromId } from "../../../firebase";
import TermsOfService from "./TermsOfService";
const Section_3 = (props) => {
  const [animation, set_animation] = useState(true);
  const [email, set_email] = useState("");
  const [show_terms, set_show_terms] = useState(false);

  useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    if (user_id !== null) {
      getEmailFromId(user_id, (result) => {
        if (result) {
          set_email(result);
          props.handle_user(user_id);
        }
      });
    }
  }, []);
  return (
    <section
      className={
        props.page === 3
          ? "event_section_3_left fade_in"
          : "event_section_3_left fade_out"
      }
    >
      <div
        className={
          animation
            ? "log_sign_container zoom_in"
            : "log_sign_container zoom_out"
        }
      >
        {/* <div className="log_sign_container">
          <div
            className="sign_in_cntainer"
            style={{ textAlign: "start", width: "100%", maxWidth: "400px" }}
          >
            <span>אתם מחוברים אל המשתמש {email}</span>
            <button className="change_user" onClick={() => set_email("")}>
              <span>האם תרצו להחליף משתמש?</span>
            </button>
          </div>
        </div> */}

        <Sign_in
          handle_user={props.handle_user}
          handle_terms={props.handle_terms}
        ></Sign_in>
      </div>
    </section>
  );
};

export default Section_3;
