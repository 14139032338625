import AlexBrush from './AlexBrush-Regular.ttf';
import AntiDidone from './AnticDidone-Regular.ttf';
import Arapey from './Arapey-Regular.ttf';
import DancingScript from './DancingScript-VariableFont_wght.ttf';
import LibreBodoni from './LibreBodoni-VariableFont_wght.ttf';
import Montserrat from './Montserrat-VariableFont_wght.ttf';
import NanumMyeongjo from './NanumMyeongjo-Regular.ttf';
import PinyonScript from './PinyonScript-Regular.ttf';
import Tangerine from './Tangerine-Regular.ttf';
import Mallikhaswash from './Mallikhabrush-7B4ME.ttf';
import Breathing from './Breathing.ttf';

import Solitreo from './Solitreo-Regular.ttf';
import Miriam from './MiriamLibre-Regular.ttf';
import Tinos from './Tinos-Italic.ttf';
import AmaticSC from './AmaticSC-Regular.ttf';

export const fonts_array = [
  {
    label: 'Alex Brush',
    value: AlexBrush,
    className: 'AlexBrush font_option',
  },
  {
    label: 'Anti Didone',
    value: AntiDidone,
    className: 'AntiDidone font_option',
  },
  { label: 'Arapey', value: Arapey, className: 'Arapey font_option' },

  {
    label: 'Libre Bodoni',
    value: LibreBodoni,
    className: 'LibreBodoni font_option',
  },
  {
    label: 'Montserrat',
    value: Montserrat,
    className: 'Montserrat font_option',
  },
  {
    label: 'Nanum Myeongjo',
    value: NanumMyeongjo,
    className: 'NanumMyeongjo font_option',
  },
  {
    label: 'Pinyon Script',
    value: PinyonScript,
    className: 'PinyonScript font_option',
  },
  {
    label: 'Tangerine',
    value: Tangerine,
    className: 'Tangerine font_option',
  },
  {
    label: 'Mallikhaswash',
    value: Mallikhaswash,
    className: 'Mallikhaswash font_option',
  },

  {
    label: 'Solitreo',
    value: Solitreo,
    className: 'Solitreo font_option',
  },
  {
    label: 'Miriam',
    value: Miriam,
    className: 'Miriam font_option',
  },
  {
    label: 'Tinos',
    value: Tinos,
    className: 'Tinos font_option',
  },
  {
    label: 'AmaticSC',
    value: AmaticSC,
    className: 'AmaticSC font_option',
  },
];
