import React from "react";
import "./Home_page.css";
import "../App.css";
import { Canvas } from "@react-three/fiber";
import { Float, OrbitControls, Sphere } from "@react-three/drei";
const BackGround = () => {
  const Ball = (props) => {
    const color = "#066fa4";
    return (
      <mesh position={props.position}>
        <Sphere args={props.args}>
          {" "}
          <meshStandardMaterial
            color={props.color}
            transparent={true}
            opacity={0.8}
          ></meshStandardMaterial>
        </Sphere>
      </mesh>
    );
  };
  return (
    <div className="home_page_bg">
      <div className="bg_shape_1"></div>
      <div className="bg_shape_2"></div>

      <div className="bg_shape_3"></div>

      <div className="bg_shape_4"></div>

      <div className="bg_shape_5"></div>
    </div>
  );
};

export default BackGround;
