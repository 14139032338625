import React, { useEffect, useRef, useState } from 'react';
import './Event_page.css';
import * as Iconstet from 'react-icons/fa';
import imageCompression from 'browser-image-compression';
import ImageFilter from 'react-image-filter'; // Import the react-image-filter component
import Aos from 'aos';
import 'aos/dist/aos.css';
import { RWebShare } from 'react-web-share';
import QRCode from 'react-qr-code';
import * as hi2 from 'react-icons/hi2';
import * as pi from 'react-icons/pi';
import * as ci from 'react-icons/ci';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import * as go from 'react-icons/go';
import * as md from 'react-icons/md';
import * as bs from 'react-icons/bs';
import Spinner from 'react-bootstrap/Spinner';
import { Helmet } from 'react-helmet';

import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import {
  get_data_by_id,
  get_event_by_id,
  incrementCounter,
  upload_profile_image,
} from '../../firebase';
import Wait_page from '../Wait_page/Wait_page';
import Camera from '../Camera/Camera';
import Filter_page from './Filter_page';
import Menu_button from './Menu_button';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { BeatLoader } from 'react-spinners';
import Gallery_page from '../Gallery_page/Gallery_page';

import { useUpload } from '../../UploadContext';
import TermsOfService from '../Add_Event_Page/Section_3/TermsOfService';
import { addImageToDb, deleteImageFromDb } from '../../IndexDb';
import { lastCodeModify } from '../../Util/helpers';

const Event_page = (props) => {
  const { event_id } = useParams();
  const user_id = '';
  const [event_data, set_event_data] = useState();
  const [photos_left, set_photos_left] = useState(5);
  const [user, set_user] = useState(-1);
  const [is_waiting, set_is_waiting] = useState(false);
  const [open_camera, set_open_camera] = useState(false);
  const [open_filter, set_open_filter] = useState(false);
  const [image_file, set_image_file] = useState();
  const [image_src, set_image_src] = useState();
  const [is_menu_clicked, set_is_menu_clicked] = useState(true);
  const [barcode_clicked, set_barcode_clicked] = useState(false);
  const [too_many, set_too_many] = useState(false);
  const [is_first_time, set_is_first_time] = useState(false);
  const [is_not_all_uploaded, set_is_not_all_uploaded] = useState(false);
  const [is_dark_color, set_is_dark_color] = useState(false);
  const input_img_ref = useRef(null);
  const input_gallery_ref = useRef(null);
  const [eventCounter, setEventCounter] = useState(0);
  const [tooManyGuests, setTooManyGuests] = useState(false);
  const [isTooLate, setIsTooLate] = useState(false);

  const history = useHistory();
  const [rerender, set_rerender] = useState(false);
  const [show_terms, set_show_terms] = useState(false);
  const [containerHeight, setContainerHeight] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);
  const { uploadImage, uploading } = useUpload();
  const options = {
    maxSizeMB: 1.6,
    maxWidthOrHeight: 1024,
    useWebWorker: false,
  };
  useEffect(() => {
    get_event_by_id(event_id, (data) => {
      set_event_data(data);
      console.log(data.event_name.font_size / 4);
      const [r, g, b] = data.button_color
        .match(/\w\w/g)
        .map((hex) => parseInt(hex, 16));
      const brightness = (r * 299 + g * 587 + b * 114) / 1000;
      if (brightness < 128) set_is_dark_color(true);
      else set_is_dark_color(false);

      const left = localStorage.getItem(`${event_id}_left`);
      if (!left) {
        set_is_first_time(true);
        set_photos_left(data.config_data.photos);
        localStorage.setItem(`${event_id}_left`, data.config_data.photos);
      } else set_photos_left(left);
      const user_count = localStorage.getItem(`${event_id}_user`);

      if (!user_count) {
        // incrementCounter(`${user_id}:${event_id}`, (count) => {
        //   localStorage.setItem(`${user_id}/${event_id}_user`, count);
        //   set_user(count);
        // });
      } else set_user(user_count);
      get_data_by_id('counters', event_id, (counterData) => {
        setEventCounter(
          user_count === null && counterData.counter >= data.config_data.guests
        );
      });
    });
  }, [rerender]);

  const compressFiles = async (files, my_user_id) => {
    const compressedFiles = [];
    const localNames = [];
    for (const file of files) {
      const date = new Date();
      const timestamp = date.getTime();
      const compressedBlob = await imageCompression(file, options);
      const fileName = `${9999999999999 - timestamp}_${my_user_id}`;
      localNames.push(fileName);
      compressedFiles.push({ file: compressedBlob, name: fileName });
      addImageToDb(`${event_id}_loading`, {
        name: fileName,
        file: compressedBlob,
      });
      await props.handleSetLocalArray(fileName, true);
    }
    return { compressedFiles, localNames };
  };
  const handle_files_images = async (e) => {
    let files = Array.from(e.target.files); // Convert FileList to an array
    if (!files || files.length === 0) {
      // set_is_waiting(false);
      props.handle_uploading_progress(false);

      return;
    }
    if (files.length > 5) {
      set_too_many(true);
      // set_is_waiting(false);
      props.handle_uploading_progress(false);

      return;
    }
    if (files.length > photos_left) {
      files = files.slice(0, photos_left);
    }
    set_photos_left(photos_left - files.length);
    localStorage.setItem(`${event_id}_left`, photos_left - files.length);
    try {
      let my_user_id = user;

      if (user === -1) {
        await incrementCounter(`${event_id}`, (count) => {
          localStorage.setItem(`${event_id}_user`, count);
          set_user(count);
          my_user_id = count;
        });
      }
      const { compressedFiles, localNames } = await compressFiles(
        files,
        my_user_id
      );
      history.push({
        pathname: `/gallery/${event_id}`, // The path you want to navigate to
        state: {
          event: event_data,
          user: my_user_id,
          localArray: localNames,
        }, // The state you want to pass
      });

      const uploadPromises = compressedFiles.map((file, index) =>
        handle_add_event_image(
          file.file,
          file.name,
          '',
          false,
          false,
          index === files.length - 1
        )
      );

      const results = await Promise.all(uploadPromises); // Wait for all uploads to complete
      const successfulUploads = results.filter(
        (result) => result && result.success
      );

      localStorage.setItem(
        `${event_id}_left`,
        photos_left - successfulUploads.length
      );
    } catch (error) {
      // try {
      //   let successfulUploads = 0;
      //   for (let i = 0; i < files.length; i++) {
      //     const file = files[i];
      //     const result = await handle_add_event_image(
      //       file,
      //       "",
      //       false,
      //       false,
      //       i === files.length - 1
      //     );
      //     if (result && result.success) {
      //       // Update the state based on the previous state value
      //       set_photos_left((prev) => {
      //         const newPhotosLeft = prev - 1;
      //         console.log(prev - 1);
      //         return newPhotosLeft;
      //       });
      //       // Update the parent component with the remaining images count
      //       props.handle_left_images(files.length - i - 1);
      //     } else {
      //       set_is_not_all_uploaded(true);
      //     }
      //   }
      // }
      console.error('Error uploading files:', error);
      // set_is_waiting(false);
      props.handle_uploading_progress(false);
    }
    set_rerender(!rerender);
  };

  async function handle_add_event_image(
    file,
    fileName,
    selectedFilter,
    need_rotation,
    is_single,
    is_last_file
  ) {
    try {
      let my_user_id = user;

      if (user === -1) {
        await incrementCounter(`${event_id}`, (count) => {
          localStorage.setItem(`${event_id}_user`, count);
          set_user(count);
          my_user_id = count;
        });
      }

      if (!file) return { success: false, error: 'No file provided' };

      // Directly use the file as Blob

      // Upload the compressed image
      const date = new Date();
      const timestamp = date.getTime();
      //change here in the future

      const result = await uploadImage(
        file,
        `${event_id}`,
        event_id,
        my_user_id,
        fileName,
        selectedFilter
      );
      // Handle the uploaded data or update UI as needed

      if (result.success) {
        props.handle_uploading_progress(false);
        if (is_single) {
          // set_is_waiting(false);
          props.handle_uploading_progress(false);
        }

        deleteImageFromDb(`${event_id}_loading`, fileName);
        props.handleSetLocalArray(fileName, false);
      } else {
        if (is_single) {
          // set_is_waiting(false);
          props.handle_uploading_progress(false);
        }
      }
      if (is_last_file) {
        // set_is_waiting(false);
        props.handle_uploading_progress(false);
      }
      return result;
    } catch (error) {
      console.log(error);
      // set_is_waiting(false);
      props.handle_uploading_progress(false);
    }
  }
  async function applyFilterToImage(blob, filter, need_rotation) {
    const image = new Image();
    image.src = URL.createObjectURL(blob);
    image.crossOrigin = 'Anonymous';
    return new Promise((resolve, reject) => {
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Set canvas dimensions to match image dimensions
        canvas.width = image.width;
        canvas.height = image.height;

        // Apply filter to the image
        ctx.filter = filter;
        if (need_rotation) {
          ctx.transform(-1, 0, 0, 1, image.width, 0);
        }
        ctx.drawImage(image, 0, 0, image.width, image.height);
        // Convert canvas to Blob
        canvas.toBlob((resultBlob) => {
          resolve(resultBlob);
        }, 'image/png');
      };

      image.onerror = (error) => {
        reject(error);
      };
    });
  }

  const handle_file_image = async (e) => {
    let file = e.target.files?.[0];
    if (!file) return;
    set_photos_left(photos_left - 1);
    localStorage.setItem(`${event_id}_left`, photos_left - 1);

    // Compress the image using the image compression library
    // const filteredBlob = await applyFilterToImage(
    //   file,
    //   selectedFilter,
    //   need_rotation
    // );
    const compressedBlob = await imageCompression(file, options);
    const date = new Date();
    const timestamp = date.getTime();
    let my_user_id = user;

    if (user === -1) {
      await incrementCounter(`${event_id}`, (count) => {
        localStorage.setItem(`${event_id}_user`, count);
        set_user(count);
        my_user_id = count;
      });
    }
    const fileName = `${9999999999999 - timestamp}_${my_user_id}`;

    addImageToDb(`${event_id}_loading`, {
      name: fileName,
      file: compressedBlob,
    });
    props.handleSetLocalArray(fileName, true);
    history.push({
      pathname: `/gallery/${event_id}`, // The path you want to navigate to
      state: {
        event: event_data,
        user: my_user_id,
        localArray: [fileName],
      }, // The state you want to pass
    });
    handle_add_event_image(compressedBlob, fileName, '', false, true, true);
  };
  const containerRef = useRef();

  return (
    <div className='event_page_all_container'>
      {event_data && !is_waiting ? (
        <div
          style={{ color: is_dark_color ? 'white' : 'black' }}
          className='event_page'
          onClick={() => {
            // set_is_menu_clicked(false);
          }}
        >
          <div style={{ height: '10%' }}></div>
          <main className='event_page_main' ref={containerRef}>
            {event_data.event_name.name === '' ? (
              ''
            ) : (
              <div
                className='event_page_event_name'
                style={{
                  zIndex: '0',
                  top:
                    event_data.event_name.y < 50
                      ? ''
                      : `${100 - event_data.event_name.y}%`,
                  bottom:
                    event_data.event_name.y >= 50
                      ? ''
                      : `${event_data.event_name.y}%`,
                  backgroundColor:
                    event_data.event_name.font_bg_color +
                    event_data.event_name.opacity,
                }}
              >
                <span
                  className={`event_text_span ${event_data.event_name.font.name}`}
                  style={{
                    fontSize: `${event_data.event_name.font_size / 4}vw`,
                    color: event_data.event_name.font_color,
                  }}
                >
                  {event_data.event_name.name}
                </span>
              </div>
            )}
            {event_data.date.name === '' ? (
              ''
            ) : (
              <div
                className='event_page_event_name'
                style={{
                  zIndex: '1',
                  top:
                    event_data.lastModify &&
                    event_data.lastModify > lastCodeModify
                      ? `${
                          100 -
                          event_data.date.y +
                          (event_data.date.y > 50
                            ? event_data.event_name.font_size / 4
                            : 0)
                        }%`
                      : event_data.date.y < 50
                      ? ''
                      : `${100 - event_data.date.y}%`,

                  bottom:
                    !(
                      event_data.lastModify &&
                      event_data.lastModify > lastCodeModify
                    ) && event_data.date.y >= 50
                      ? ''
                      : `${event_data.date.y}%`,
                  backgroundColor:
                    event_data.date.font_bg_color + event_data.date.opacity,
                }}
              >
                <span
                  className={`event_text_span ${event_data.date.font.name}`}
                  style={{
                    fontSize: `${event_data.date.font_size / 4}vw`,
                    color: event_data.date.font_color,
                  }}
                >
                  {event_data.date.name}
                </span>
              </div>
            )}
          </main>
          <footer className='event_name_footer'>
            {' '}
            <div
              onClick={() => {
                if (event_id !== 'llnPvmHk9WMOm9EmLOyr') {
                  const eventDate = event_data?.eventDate;
                  let lastDate = new Date(eventDate);
                  lastDate.setDate(lastDate.getDate() + 8);
                  if (lastDate < new Date()) {
                    setIsTooLate(true);
                    return;
                  }
                }

                if (eventCounter) {
                  setTooManyGuests(true);
                } else if (photos_left > 0) input_gallery_ref.current.click();
              }}
              className='circle_3 event_circle'
              style={{ backgroundColor: event_data.button_color }}
            >
              <ci.CiImageOn></ci.CiImageOn>
              <input
                type='file'
                ref={input_gallery_ref}
                onChange={(e) => {
                  // set_is_waiting(true);
                  props.handle_uploading_progress(true);

                  // handle_add_event_image(e);
                  handle_files_images(e);
                }}
                // capture="filesystem"
                accept='image/*'
                multiple
                style={{ display: 'none' }}
              />
            </div>
            <div
              className='circle_2 event_circle'
              style={{ backgroundColor: event_data.button_color }}
              onClick={() => {
                if (event_id !== 'llnPvmHk9WMOm9EmLOyr') {
                  const eventDate = event_data?.eventDate;
                  let lastDate = new Date(eventDate);
                  lastDate.setDate(lastDate.getDate() + 8);
                  if (lastDate < new Date()) {
                    setIsTooLate(true);
                    return;
                  }
                }

                // set_open_camera(true);
                if (eventCounter) {
                  setTooManyGuests(true);
                } else if (photos_left > 0) input_img_ref.current.click();
              }}
            >
              <pi.PiCameraLight></pi.PiCameraLight>
              <input
                type='file'
                ref={input_img_ref}
                onChange={(e) => {
                  props.handle_uploading_progress(true);

                  // set_is_waiting(true);

                  // handle_add_event_image(e);

                  handle_file_image(e);
                }}
                accept='image/*'
                capture='camera'
                style={{ display: 'none' }}
              />
            </div>
            <div
              className='circle_1 event_circle'
              style={{
                backgroundColor: event_data.button_color,
                marginBottom: '-2px',
              }}
            >
              <span>{photos_left}</span>
              <span
                style={{ fontFamily: event_data.is_en ? '' : 'verale' }}
                className='left_circle'
              >
                {event_data.is_en ? 'Left' : 'נותרו'}
              </span>
            </div>
          </footer>
          <LazyLoadImage
            key={event_data.id}
            src={event_data.img_url}
            className='event_page_bg'
            // style={{ backgroundImage: `url(${event_data.img_url})` }}
          ></LazyLoadImage>
        </div>
      ) : (
        <Wait_page></Wait_page>
      )}

      {event_data ? (
        <Menu_button
          is_menu_clicked={is_menu_clicked}
          is_dark_color={is_dark_color}
          is_en={event_data.is_en}
          click_menu={() => set_is_menu_clicked(!is_menu_clicked)}
          color={event_data.button_color}
          barcode_clicked={() => set_barcode_clicked(true)}
          move_to_gallery={() => {
            history.push({
              pathname: `/gallery/${event_id}`,
              state: {
                event: event_data,
                user: user,
              },
            });
          }}
        ></Menu_button>
      ) : (
        ''
      )}
      {barcode_clicked ? (
        <div
          className='barcode_page'
          onClick={() => {
            set_barcode_clicked(false);
          }}
        >
          {' '}
          <go.GoX className='barcode_page_x'></go.GoX>
          <div
            data-aos='zoom-out'
            data-aos-delay='400'
            // className="barcode_container"
            onClick={(e) => {
              e.stopPropagation();
            }}
            // style={{ backgroundColor: event_data.button_color }}
          >
            {' '}
            <RWebShare
              data={{
                text: `שתפו את הרגעים שלכם מהאירוע`,
                url: `https://our-moments.web.app/event/${event_data.id}`,
                title: 'Moments',
              }}
            >
              <div
                className='barcode_container'
                style={{ backgroundColor: event_data.button_color }}
              >
                <QRCode
                  style={{
                    width: '45vw',
                    height: '45vw',
                    maxHeight: '45vh',
                    maxWidth: '45vh',
                  }}
                  bgColor='#FFFFFF00'
                  value={`https://our-moments.web.app/event/${event_data.id}`}
                ></QRCode>

                <div className='share_barcode_container'>
                  <span
                    style={{ fontFamily: event_data.is_en ? '' : 'verale' }}
                  >
                    {event_data.is_en ? 'SHARE' : 'שתפו'}
                  </span>{' '}
                  <hi2.HiOutlineShare></hi2.HiOutlineShare>
                </div>
              </div>
            </RWebShare>
          </div>
        </div>
      ) : (
        ''
      )}
      {too_many && (
        <div className='delete_image_page'>
          <div
            className='delete_image_box'
            data-aos='zoom-out'
            data-aos-delay='400'
            style={{ borderRadius: '10px' }}
          >
            <go.GoX
              className='close_delete_box'
              onClick={() => {
                set_too_many(false);
              }}
            ></go.GoX>
            <md.MdOutlineReportGmailerrorred className='trash_icon'></md.MdOutlineReportGmailerrorred>
            <span className='delete_image_box_span'>יותר מידי תמונות </span>
            <p>
              ניתן להעלות עד 5 תמונות בכל פעם, אם ברצונכם להעלות מעל 5 תמונות
              אתם יכולים לעשות זאת בכמה פעמים
            </p>
            <footer className='delete_image_box_footer'>
              <button className='delete_image_box_footer_delete'>
                {' '}
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    set_too_many(false);
                  }}
                >
                  אישור
                </span>
              </button>
            </footer>
          </div>
        </div>
      )}
      {tooManyGuests && (
        <div className='delete_image_page'>
          <div
            className='delete_image_box'
            data-aos='zoom-out'
            data-aos-delay='400'
            style={{ borderRadius: '10px' }}
          >
            <go.GoX
              className='close_delete_box'
              onClick={() => {
                setTooManyGuests(false);
              }}
            ></go.GoX>
            <md.MdOutlineReportGmailerrorred className='trash_icon'></md.MdOutlineReportGmailerrorred>
            <span className='delete_image_box_span'>
              יותר מידי אורחים רשומים{' '}
            </span>
            <p>
              כל אורחי האירוע כבר הספיקו להעלות את התמונות שלהם אל הגלרייה, כעת
              אתם מוזמנים לצפות בגלרייה המהממת שנוצרה
            </p>
            <footer className='delete_image_box_footer'>
              <button className='delete_image_box_footer_delete'>
                {' '}
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setTooManyGuests(false);
                  }}
                >
                  אישור
                </span>
              </button>
            </footer>
          </div>
        </div>
      )}
      {isTooLate && (
        <div className='delete_image_page'>
          <div
            className='delete_image_box'
            data-aos='zoom-out'
            data-aos-delay='400'
            style={{ borderRadius: '10px' }}
          >
            <go.GoX
              className='close_delete_box'
              onClick={() => {
                setIsTooLate(false);
              }}
            ></go.GoX>
            <md.MdOutlineReportGmailerrorred
              className='trash_icon'
              style={{ color: 'var(--blue)' }}
            ></md.MdOutlineReportGmailerrorred>
            <span className='delete_image_box_span'>תאריך האירוע כבר עבר </span>
            <p>האירוע הסתיים, כעת אתם מוזמנים לצפות בגלרייה המהממת שנוצרה</p>
            <footer className='delete_image_box_footer'>
              <button
                className='delete_image_box_footer_delete'
                style={{ backgroundColor: 'var(--blue)' }}
              >
                {' '}
                <span
                  style={{ color: 'white' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setIsTooLate(false);
                  }}
                >
                  אישור
                </span>
              </button>
            </footer>
          </div>
        </div>
      )}
      {is_not_all_uploaded ? (
        <div className='delete_image_page'>
          <div
            className='delete_image_box'
            data-aos='zoom-out'
            data-aos-delay='400'
            style={{ borderRadius: '10px' }}
          >
            <go.GoX
              className='close_delete_box'
              onClick={() => {
                set_is_not_all_uploaded(false);
              }}
            ></go.GoX>
            <md.MdOutlineReportGmailerrorred className='trash_icon'></md.MdOutlineReportGmailerrorred>
            <span className='delete_image_box_span'>תקלה</span>
            <p>
              חלק מהתמונות שהעליתם לא עלו אל הגלרייה כתוצאה מחיבור אינטרנט חלש,
              אנו נסו שנית
            </p>
            <footer className='delete_image_box_footer'>
              <button className='delete_image_box_footer_delete'>
                {' '}
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    set_is_not_all_uploaded(false);
                  }}
                >
                  אישור
                </span>
              </button>
            </footer>
          </div>
        </div>
      ) : (
        ''
      )}
      {event_data && is_first_time ? (
        <div className='delete_image_page'>
          <div
            className='delete_image_box'
            data-aos='zoom-out'
            data-aos-delay='400'
            style={{ borderRadius: '10px' }}
          >
            {/* <go.GoX
              className='close_delete_box'
              onClick={() => {
                set_is_first_time(false);
              }}
            ></go.GoX> */}
            <pi.PiScrollThin
              className='trash_icon'
              style={{ color: 'var(--blue)' }}
            ></pi.PiScrollThin>
            <span className='delete_image_box_span'>ברוכים הבאים !</span>
            <div>
              <p>כאן תוכלו לשתף את הרגעים המיוחדים שלכם מהאירוע.</p>
              <p>
                יש ברשותכם{' '}
                <span style={{ fontWeight: 700 }}>
                  {event_data.config_data.photos}
                </span>{' '}
                תמונות שתוכלו לצלם או להעלות מהגלרייה שלכם.
              </p>
              <p>מקווים שתהנו מהאירוע ומחכים לראות את הרגעים שלכם.</p>
              <p style={{ fontWeight: '700' }}>
                בלחיצה על אישור אתם מאשרים את{' '}
                <span
                  style={{
                    borderBottom: '1px solid black',
                    paddingBottom: '3px',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    set_show_terms(true);
                  }}
                >
                  התקנון
                </span>
              </p>
            </div>
            <footer className='delete_image_box_footer'>
              <button
                className='delete_image_box_footer_delete'
                style={{ backgroundColor: 'var(--blue)', color: 'white' }}
              >
                {' '}
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    set_is_first_time(false);
                  }}
                >
                  אישור
                </span>
              </button>
            </footer>
          </div>
        </div>
      ) : (
        ''
      )}
      {show_terms ? (
        <div className='terms_page'>
          <TermsOfService
            handle_terms={() => set_show_terms(false)}
          ></TermsOfService>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Event_page;
