import React, { useEffect, useRef } from 'react';
import './Section_2.css';

import img1 from '../../../images/img1.jpg';
import img2 from '../../../images/img2.jpg';
import img3 from '../../../images/img3.jpg';
import Card from '../Section_1/Card';
import AOS from 'aos';
import 'aos/dist/aos.css';
import * as md from 'react-icons/md';
import Scene_animated from '../../Phone_animated/Scene_animated';
import poster from '../../../images/scan_me_poster.png';
const Section_2 = (props) => {
  const aref = useRef();

  useEffect(() => {
    if (window.innerWidth >= 900) {
      AOS.init({
        duration: 1000, // Duration of the animations in milliseconds
      });
    }
  }, []);
  return (
    <div className='section_1'>
      <div className='poster_container'>
        <div className='board_container'>
          <img src={poster}></img>
        </div>
      </div>
      <div className='section_1_home_page'>
        {window.innerWidth < 900 ? (
          <div className='section_1_header'>
            <span className='section_1_header_span'>
              אז,<span style={{ fontWeight: '700' }}> איך זה עובד</span>?
            </span>
          </div>
        ) : (
          <div className='section_1_header' data-aos='fade-left'>
            <span className='section_1_header_span'>
              אז,<span style={{ fontWeight: '700' }}> איך זה עובד</span>?
            </span>
          </div>
        )}
        <main className='section_1_main'>
          <div className='sectin_2_main_div'>
            <div className='section_2_arrow'>
              <md.MdOutlineArrowBackIos className='section_2_arrow_icon'></md.MdOutlineArrowBackIos>
            </div>
            <section className='section_2_main_text'>
              <span style={{ fontWeight: '700' }}>סורקים</span>
              <span style={{ fontSize: 'var(--small)' }}>או נכנסים לקישור</span>
            </section>
          </div>
          <div className='sectin_2_main_div'>
            <div className='section_2_arrow'>
              <md.MdOutlineArrowBackIos className='section_2_arrow_icon'></md.MdOutlineArrowBackIos>
            </div>
            <section className='section_2_main_text'>
              <span style={{ fontWeight: '700' }}>מצלמים</span>
              <span style={{ fontSize: 'var(--small)' }}>
                ומשתפים "רגעים" מהאירוע
              </span>
            </section>
          </div>
          <div className='sectin_2_main_div'>
            <div className='section_2_arrow'>
              <md.MdOutlineArrowBackIos className='section_2_arrow_icon'></md.MdOutlineArrowBackIos>
            </div>
            <section className='section_2_main_text'>
              <span style={{ fontWeight: '700' }}>נהנים</span>
              <span style={{ fontSize: 'var(--small)' }}>
                מאלבום זכרונות משותף
              </span>
            </section>
          </div>
        </main>
        {window.innerWidth < 900 ? (
          <span className='no_need_span'>
            !לא נדרשת התקנת אפליקציה! עובד בכל סמארטפון{' '}
          </span>
        ) : (
          <span
            className='no_need_span'
            data-aos='fade-left'
            data-aos-delay='400'
          >
            !לא נדרשת התקנת אפליקציה! עובד בכל סמארטפון{' '}
          </span>
        )}
      </div>
    </div>
  );
};

export default Section_2;
