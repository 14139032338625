export function get_time(date) {
  const hours = date.getHours().toString().padStart(2, '0'); // Ensure two digits for hours
  const minutes = date.getMinutes().toString().padStart(2, '0'); // Ensure two digits for minutes

  return `${hours}:${minutes}`;
}
export function combineDates(date1, date2) {
  // Extract date components from date1
  const year = date1.getFullYear();
  const month = date1.getMonth();
  const day = date1.getDate();

  // Extract time components from date2
  const hours = date2.getHours();
  const minutes = date2.getMinutes();
  const seconds = date2.getSeconds();
  const milliseconds = date2.getMilliseconds();

  // Create a new Date object with the combined date and time components
  const combinedDate = new Date(
    year,
    month,
    day,
    hours,
    minutes,
    seconds,
    milliseconds
  );

  return combinedDate;
}
export function convert_y_position_precentage(y_precentage) {
  return (y_precentage * 0.91) / 100 - 0.37;
}
export function convert_y_position(y_position) {
  return Math.floor(((y_position + 0.37) / 0.91) * 100);
}
export function calculateScaledFontSize(
  originalFontSize,
  originalWidth,
  originalHeight,
  phoneWidth,
  phoneHeight
) {
  return (
    calculateFontSize(
      originalFontSize,
      originalWidth,
      originalHeight,
      phoneWidth,
      phoneHeight
    ) + 'vw'
  );
}
export function calculateFontSize(
  originalFontSize,
  originalWidth,
  originalHeight,
  phoneWidth,
  phoneHeight
) {
  // Calculate the width and height scale factors
  const widthScale = phoneWidth / originalWidth;
  const heightScale = phoneHeight / originalHeight;
  // Use the smaller scale factor to maintain aspect ratio
  const scaleFactor = Math.min(widthScale, heightScale);
  // Calculate the new font size
  const newFontSize = originalFontSize * scaleFactor;
  return newFontSize * scaleFactor;
}
export function calculateDeafultScaledFontSize(
  originalFontSize,
  originalWidth,
  originalHeight
) {
  // Calculate the width and height scale factors
  const widthScale = 430 / originalWidth;
  const heightScale = 932 / originalHeight;

  // Use the smaller scale factor to maintain aspect ratio
  const scaleFactor = Math.min(widthScale, heightScale);

  // Calculate the new font size
  const newFontSize = originalFontSize * scaleFactor;

  return newFontSize;
}
export const lastCodeModify = new Date('2024-09-09').getTime();
