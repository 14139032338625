import { initializeApp } from 'firebase/app';
import {
  startAfter,
  initializeFirestore,
  persistentMultipleTabManager,
  persistentLocalCache,
  disableNetwork,
  CACHE_SIZE_UNLIMITED,
} from 'firebase/firestore';

import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  doc,
  deleteDoc,
  onSnapshot,
  query,
  where,
  orderBy,
  setDoc,
  updateDoc,
  serverTimestamp,
  limit,
  Timestamp,
  collectionGroup,
  arrayUnion,
  arrayRemove,
} from 'firebase/firestore';
import {
  getAuth,
  confirmPasswordReset,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  list,
  listAll,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import { v4 } from 'uuid';
////// import { getMessaging, getToken, onMessage } from "firebase/messaging";

export { Timestamp } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// import axios from "axios";

// import { getMessaging, getToken, onMessage } from "firebase/messaging";
const app = initializeApp(firebaseConfig);
const db = initializeFirestore(app, {
  localCache: persistentLocalCache({}),
});

const auth = getAuth();
auth.languageCode = 'he';
// const messaging = getMessaging(app);
////// const messaging = getMessaging(app);

const imagedb = getStorage(); // enableIndexedDbPersistence(db).catch((err) => {
//   if (err.code === 'failed-precondition') {
//     console.log('Persistence can only be enabled in one tab at a time.');
//   } else if (err.code === 'unimplemented') {
//     console.log(
//       'The current browser does not support all of the features required to enable persistence.'
//     );
//   }
// });
// enableIndexedDbPersistence(db).catch((err) => {
//   if (err.code === 'failed-precondition') {
//     console.log('Persistence can only be enabled in one tab at a time.');
//   } else if (err.code === 'unimplemented') {
//     console.log(
//       'The current browser does not support all of the features required to enable persistence.'
//     );
//   }
// });
export const sendPasswordReset = (userEmail) => {
  sendPasswordResetEmail(auth, userEmail)
    .then(() => {
      // Password reset email sent!
      console.log('Password reset email sent.');
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // Handle errors here
      console.error('Error: ', errorCode, errorMessage);
    });
};
export const resetPassword = (newPassword, code) => {
  confirmPasswordReset(auth, code, newPassword)
    .then(() => {
      // Password has been reset successfully
      console.log('Password has been reset.');
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // Handle errors here
      console.error('Error: ', errorCode, errorMessage);
    });
};
export const handleSignInWithGoogle = async () => {
  try {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });
    const result = await signInWithPopup(auth, provider);

    // Check if the result is null or missing user information
    if (!result || !result.user) {
      throw new Error('Sign-in process canceled or failed.');
    }

    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    const user = result.user;

    // Additional logic with result, credential, token, and user if needed
    return result; // Return the result if necessary
  } catch (error) {
    // Handle Errors here.
    console.log(error);
    const errorCode = error.code;
    const errorMessage = error.message;
    const email = error.customData?.email; // Use optional chaining to avoid errors
    const credential = GoogleAuthProvider.credentialFromError(error);
    // Additional error handling logic if needed
    // Rethrow the error if necessary
  }
};
export function date_and_time() {
  const current_date = new Date();
  let hours, minutes;
  if (current_date.getHours() < 10) hours = '0' + current_date.getHours();
  else hours = current_date.getHours();

  if (current_date.getMinutes() < 10) minutes = '0' + current_date.getMinutes();
  else minutes = current_date.getMinutes();

  const time = hours + ':' + minutes;
  const date =
    current_date.getDate() +
    '/' +
    (current_date.getMonth() + 1) +
    '/' +
    current_date.getFullYear();
  return [date, time];
}
export function get_data(collection_name, callback) {
  let data = [];

  const collectionRef = collection(db, collection_name);
  getDocs(collectionRef)
    .then((snapshot) => {
      snapshot.docs.map((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      callback(data);
    })
    .catch((err) => {
      callback(404);
    });
}

export function get_data_orderd(collection_name, callback) {
  let data = [];

  const collectionRef = collection(db, collection_name);
  const q = query(collectionRef, orderBy('createdAt', 'desc'));
  getDocs(q)
    .then((snapshot) => {
      snapshot.docs.map((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      callback(data);
    })
    .catch((err) => {
      console.log(err);
      callback([]);
    });
}
export function get_data_by_id(collection_name, id, callback) {
  const docRef = doc(db, collection_name, id);
  getDoc(docRef)
    .then((snapshot) => {
      const data = snapshot.data();

      callback(data);
    })
    .catch((err) => {});
}

export function add_data(collection_name, object, callback) {
  const collectionRef = collection(db, collection_name);

  addDoc(collectionRef, { ...object })
    .then((data) => {
      callback(data.id);
    })
    .catch((err) => {
      console.log(err);
      callback(404);
    });
}
export function add_group(group_data, user_data, user_id, callback) {
  const collectionRef = collection(db, 'group');

  addDoc(collectionRef, group_data)
    .then((data) => {
      const groupRef = doc(db, 'group', data.id);

      const memberdoc = doc(groupRef, 'member', user_id);
      setDoc(memberdoc, user_data);

      const chatref = doc(db, 'chat', data.id);

      setDoc(chatref, {
        chat_users: [
          {
            id: user_id,
            name: user_data.name,
            profile_image: user_data.profile_image,
          },
        ],
        group_data: {
          id: data.id,
          name: group_data.group_name,
          profile_image: group_data.profile_image,
        },
        users_in_chat: [],
        latest_mess: serverTimestamp(),
      }).then(() => {
        const lastenter = doc(db, 'last_enter', `${data.id}_${user_id}`);

        setDoc(lastenter, {
          chat_id: data.id,
          user_id: user_id,
          last_enter: serverTimestamp(),
        }).then(() => {});
      });
      callback(data.id);
    })
    .catch((err) => {
      console.log(err);
      callback(404);
    });
}
export function get_my_groups(user_id, callback) {
  let data = [];

  const collectionRef = collectionGroup(db, 'member');
  const q = query(collectionRef, where('user_id', '==', user_id));

  getDocs(q, collectionRef)
    .then((snapshot) => {
      snapshot.forEach((memberdoc) => {
        getDoc(memberdoc.ref.parent.parent).then((group) => {
          data.push({ ...group.data(), id: group.id });
          callback(data);
        });
      });
    })
    .catch((err) => {
      console.log(err);
      callback([]);
    });
}

export function get_group_members(group_id, callback) {
  const messcollection = collection(doc(db, 'group', group_id), 'member');

  const q = query(
    messcollection,

    orderBy('is_admin', 'desc')
  );
  return onSnapshot(q, (snapshot) => {
    let data = [];

    snapshot.docs.map((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    callback(data);
    data = [];
  });
}
export function add_member(group_id, member_data, user_id, callback) {
  const groupRef = doc(db, 'group', group_id);

  const memberdoc = doc(groupRef, 'member', user_id);

  setDoc(memberdoc, member_data)
    .then((data) => {
      const chatRef = doc(db, 'chat', group_id);
      updateDoc(chatRef, {
        chat_users: arrayUnion({
          id: user_id,
          name: member_data.name,
          profile_image: member_data.profile_image,
        }),
      });
      const lastenter = doc(db, 'last_enter', `${group_id}_${user_id}`);

      setDoc(lastenter, {
        chat_id: group_id,
        user_id: user_id,
        last_enter: serverTimestamp(),
      });
      callback(data.id);
    })
    .catch((err) => {
      console.log(err);
      callback(404);
    });
}

export function remove_member(group_id, user_id, callback) {
  const groupRef = doc(db, 'group', group_id);

  const memberdoc = doc(groupRef, 'member', user_id);
  getDoc(memberdoc)
    .then((snapshot) => {
      const chatRef = doc(db, 'chat', group_id);
      const member_data = snapshot.data();
      updateDoc(chatRef, {
        chat_users: arrayRemove({
          id: snapshot.id,
          name: member_data.name,
          profile_image: member_data.profile_image,
        }),
      }).then(() => {
        deleteDoc(memberdoc).then(() => {
          delete_data('last_enter', `${group_id}_${user_id}`, () => {
            callback(200);
          });
        });
      });
    })

    .catch((err) => {
      console.log(err);
      callback(404);
    });
}
export function get_member_data(group_id, user_id, callback) {
  const groupRef = doc(db, 'group', group_id);

  const memberdoc = doc(groupRef, 'member', user_id);
  getDoc(memberdoc)
    .then((snapshot) => {
      callback(snapshot.data());
    })

    .catch((err) => {
      console.log(err);
      callback(404);
    });
}
export function set_member(group_id, user_id, is_to_make_admin, callback) {
  const groupRef = doc(db, 'group', group_id);

  const memberdoc = doc(groupRef, 'member', user_id);
  updateDoc(memberdoc, { is_admin: is_to_make_admin })
    .then(() => {
      callback(200);
    })
    .catch((err) => {
      console.log(err);
      callback(404);
    });
}
export function add_chat(user_1, user_2, callback) {
  const collectionRef = collection(db, 'chat');

  addDoc(collectionRef, {
    chat_users: [user_1, user_2],
    users_in_chat: [],
    latest_mess: serverTimestamp(),
  })
    .then((data) => {
      console.log(data.id);
      // const messcollection = collection(doc(db, "chat", data.id), "message");
      // addDoc(messcollection, {});
    })
    .catch((err) => {
      console.log(err);
      callback(404);
    });
}
export function get_user_status(user_id, callback) {
  const userRef = doc(db, 'login_status', user_id);

  // const q = query(messcollection, where("send_user.id", "==", user_id));
  onSnapshot(userRef, (snapshot) => {
    let data = [];
    if (snapshot.exists) {
      callback(snapshot.data());
    }
  });
}
export function user_enter_chat(user_id, chat_id, callback) {
  const chatRef = doc(db, 'chat', chat_id);
  updateDoc(chatRef, {
    users_in_chat: arrayUnion(user_id),
  })
    .then(() => {
      callback(200);
    })
    .catch((err) => {
      console.log(err);
      callback(404);
    });
}
export function user_leave_chat(user_id, chat_id, callback) {
  const chatRef = doc(db, 'chat', chat_id);

  updateDoc(chatRef, {
    users_in_chat: arrayRemove(user_id),
  })
    .then(() => {
      callback(200);
    })
    .catch((err) => {
      console.log(err);
      callback(404);
    });
}

export function get_chat_messages(chat_id, join_time, callback) {
  const messcollection = collection(doc(db, 'chat', chat_id), 'message');
  let q;
  join_time === 0
    ? (q = query(messcollection, orderBy('createdAt', 'asc'), limit(1)))
    : (q = query(
        messcollection,
        where('createdAt', '>=', join_time),
        orderBy('createdAt', 'asc'),
        limit(1)
      ));
  return onSnapshot(q, (snapshot) => {
    let data = [];

    snapshot.docs.map((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    callback(data);
    data = [];
  });
}
//  function processComment(comments_array,data) {
//   comments_array.forEach((comment) => {
//     data.push(comment)
//     if (comment.replies.length > 0)

//     {

//       processComment(comment.replies, data)
//     }
//   })
// }
export function get_all_post_comments(post_id, callback) {
  const messcollection = collection(doc(db, 'post', post_id), 'comment');
  let q = query(
    messcollection,

    orderBy('createdAt', 'desc')
  );

  let data = [];
  getDocs(q, messcollection)
    .then((snapshot) => {
      snapshot.docs.map((doc) => {
        data.push({ ...doc.data(), id: doc.id });

        // doc.data().replies.map((reply, index) => {
        //   data.push({ ...reply, id: `${doc.id}_${index}` });
        // });
      });

      callback(data);
    })
    .catch((err) => {
      console.log(err);
      callback([]);
    });
}

export function get_all_chat_messages(chat_id, user_id, join_time, callback) {
  let data = [];
  const messcollection = collection(doc(db, 'chat', chat_id), 'message');
  let q;
  join_time === 0
    ? (q = query(
        messcollection,
        // or(where("send_user.id", "==", user_id), where("is_seen", "==", true)),
        orderBy('createdAt', 'asc')
      ))
    : (q = query(
        messcollection,
        where('createdAt', '>=', join_time),
        orderBy('createdAt', 'asc')
      ));

  getDocs(q, messcollection)
    .then((snapshot) => {
      snapshot.docs.map((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });

      callback(data);
    })
    .catch((err) => {
      console.log(err);
      callback([]);
    });
}
export function get_real_time_messages_number(
  chat_id,
  other_user_id,
  user_in_chat,

  callback
) {
  const messcollection = collection(doc(db, 'chat', chat_id), 'message');
  let q = '';
  let time = new Date();
  q = user_in_chat
    ? query(
        messcollection,

        where('createdAt', '>=', time),

        orderBy('createdAt', 'asc')
      )
    : query(
        messcollection,
        where('send_user.id', '==', other_user_id),
        where('is_seen', '==', false),

        orderBy('createdAt', 'asc')
      );
  return onSnapshot(q, (snapshot) => {
    let data = [];
    snapshot.docs.map((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    callback(data);
    data = [];
  });
}
export function get_real_time_unseen_group_messages(
  chat_id,

  join_time,
  user_id,
  last_enter,
  is_user_in_chat,
  callback
) {
  const messcollection = collection(doc(db, 'chat', chat_id), 'message');
  let q = '';
  q = is_user_in_chat
    ? query(
        messcollection,

        where(`createdAt`, '>=', last_enter),

        orderBy('createdAt', 'asc')
      )
    : query(
        messcollection,
        where('createdAt', '>=', join_time),
        where(`createdAt`, '>=', last_enter),

        orderBy('createdAt', 'asc')
      );

  return onSnapshot(q, (snapshot) => {
    let data = [];
    snapshot.docs.map((doc) => {
      if (doc.data().send_user.id !== user_id)
        data.push({ ...doc.data(), id: doc.id });
    });
    callback(data);
    data = [];
  });
}
export function user_seen_my_message(chat_id, user_id, callback) {
  // const messcollection = collection(doc(db, "chat", chat_id), "message");
  const userRef = doc(db, 'current_chat', user_id);

  // const q = query(messcollection, where("send_user.id", "==", user_id));
  onSnapshot(userRef, (snapshot) => {
    let data = [];
    if (snapshot.exists) {
      callback(snapshot.data());
    }
  });
}
export function get_user_last_enter(chat_id, user_id, callback) {
  const chatRef = collection(db, 'last_enter');

  const q = query(
    chatRef,
    where('user_id', '==', user_id),
    where('chat_id', '==', chat_id)
  );
  getDocs(q, chatRef)
    .then((snapshot) => {
      callback(snapshot.docs[0].data().last_enter);
    })
    .catch((err) => {
      console.log(err);
      callback([]);
    });
}

export function add_reply(post_id, comment_id, comment_data, callback) {
  const chatRef = doc(db, 'post', post_id);
  const messcollection = doc(chatRef, 'comment', comment_id);
  // updateDoc(messcollection, { replies: arrayUnion(comment_data) })
  updateDoc(messcollection, { replies: comment_data.replies })
    .then((snapshot) => {
      callback(200);
    })
    .catch((err) => {
      console.log(err);
      callback(404);
    });
}
export function set_message_to_seen(chat_id, message_id, callback) {
  const chatRef = doc(db, 'chat', chat_id);
  const messcollection = doc(chatRef, 'message', message_id);
  updateDoc(messcollection, { is_seen: true })
    .then((snapshot) => {
      console.log('is_seen');
      callback(200);
    })
    .catch((err) => {
      console.log(err);
      callback(404);
    });
}
export function add_comment(post_id, comment, callback) {
  const messcollection = collection(doc(db, 'post', post_id), 'comment');

  addDoc(messcollection, { ...comment, createdAt: serverTimestamp() })
    .then((data) => {
      callback(data.id);
    })
    .catch((err) => {
      console.log(err);
      callback(404);
    });
}
export function add_message(chat_id, message, callback) {
  const messcollection = collection(doc(db, 'chat', chat_id), 'message');

  addDoc(messcollection, { ...message, createdAt: serverTimestamp() })
    .then((data) => {
      callback(data.id);
    })
    .catch((err) => {
      console.log(err);
      callback(404);
    });
}
export function get_user_send_request(my_id, group_id, callback) {
  const collectionRef = collection(db, 'request');

  const q = query(
    collectionRef,
    where('request_user_id', '==', my_id),
    where('group_id', '==', group_id)
  );
  getDocs(q, collectionRef).then((snapshot) => {
    if (snapshot.docs.length > 0) callback(snapshot.docs[0].data());
    else callback({});
  });
}

export function delete_data(collection_name, object_id, callback) {
  const docRef = doc(db, collection_name, object_id);

  deleteDoc(docRef)
    .then(() => {
      callback(200);
    })
    .catch((err) => {
      console.log(err);
      callback(404);
    });
}
export function get_data_by_query(
  collection_name,
  colom_name,
  what_to_check,
  value,
  callback
) {
  let data = [];

  const collectionRef = collection(db, collection_name);
  const q = query(collectionRef, where(colom_name, what_to_check, value));
  getDocs(q, collectionRef)
    .then((snapshot) => {
      snapshot.docs.map((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });

      callback(data);
    })
    .catch((err) => {
      console.log(err);
      callback([]);
    });
}

export function firebase_search_function(
  collection_name,
  colom_name,
  value,
  callback
) {
  let data = [];

  const collectionRef = collection(db, collection_name);
  const q = query(
    collectionRef,
    where(colom_name, '>=', value),
    where(colom_name, '<=', `${value}\uf8ff`)
  );
  getDocs(q, collectionRef)
    .then((snapshot) => {
      snapshot.docs.map((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });

      callback(data);
    })
    .catch((err) => {
      console.log(err);
      callback([]);
    });
}

export function get_data_by_query_not_order(
  collection_name,
  colom_name,
  what_to_check,
  value,
  callback
) {
  let data = [];

  const collectionRef = collection(db, collection_name);
  const q = query(collectionRef, where(colom_name, what_to_check, value));
  getDocs(q, collectionRef)
    .then((snapshot) => {
      snapshot.docs.map((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });

      callback(data);
    })
    .catch((err) => {
      console.log(err);
      callback([]);
    });
}
export function get_all_chats(value, callback) {
  let data = [];
  const collectionRef = collection(db, 'chat');
  const q = query(
    collectionRef,
    where('chat_users', 'array-contains', value),
    orderBy('latest_mess', 'desc')
  );
  return onSnapshot(q, (snapshot) => {
    let data = [];

    snapshot.docs.map((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    callback(data);
    data = [];
  });
}
export function get_real_time_user_data(id, callback) {
  const docRef = doc(db, 'user', id);

  return onSnapshot(docRef, (snapshot) => {
    callback(snapshot.data());
  });
}
export function get_real_time_chat_data(id, callback) {
  const docRef = doc(db, 'chat', id);

  return onSnapshot(docRef, (snapshot) => {
    callback(snapshot.data());
  });
}
export function get_real_time_alerts(collection_name, id, callback) {
  const collRef = collection(db, collection_name);

  const q = query(
    collRef,
    where('recived_user_id', '==', id),
    where('is_seen', '==', false),
    orderBy('createdAt', 'desc')
  );
  let data = [];
  return onSnapshot(q, (snapshot) => {
    snapshot.docs.map((doc) => {
      data.push({ id: doc.id, ...doc.data() });
    });
    callback(data);
    data = [];
  });
}
export function get_all_alerts(collection_name, id, callback) {
  let data = [];

  const collectionRef = collection(db, collection_name);
  const q = query(
    collectionRef,
    where('recived_user_id', '==', id),
    where('is_seen', '==', true),
    orderBy('createdAt', 'desc')
  );
  getDocs(q, collectionRef)
    .then((snapshot) => {
      snapshot.docs.map((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });

      callback(data);
    })
    .catch((err) => {
      console.log(err);
      callback([]);
    });
}
export function get_real_time_data_by_query(
  collection_name,
  colom_name,
  value,
  callback
) {
  const collectionRef = collection(db, collection_name);
  let data = [];
  const q = query(
    collectionRef,
    where(colom_name, '==', value, orderBy('createdAt', 'desc'))
  );
  return onSnapshot(q, (snapshot) => {
    // const data = { ...snapshot.docs[0].data(), id: snapshot.docs[0].id };

    callback(snapshot.docs.length);
  });
}
export function get_real_time_data_by_id(
  collection_name,
  object_id,

  callback
) {
  const docRef = doc(db, collection_name, object_id);

  return onSnapshot(docRef, (snapshot) => {
    // const data = { ...snapshot.docs[0].data(), id: snapshot.docs[0].id };
    if (snapshot) callback(snapshot.data());
  });
}
export function set_data(
  collection_name,
  object_id,
  updete_values_as_object,
  callback
) {
  const docRef = doc(db, collection_name, object_id);

  updateDoc(docRef, updete_values_as_object)
    .then((snapshot) => {
      callback(200);
    })
    .catch((err) => {
      console.log(err);
      set_new_data(
        collection_name,
        object_id,
        updete_values_as_object,
        callback
      );
    });
}
export function edit_group(old_group_data, group_data, callback) {
  set_data('group', group_data.id, group_data, () => {
    set_data(
      'chat',
      group_data.id,
      {
        group_data: {
          id: group_data.id,
          name: group_data.group_name,
          profile_image: group_data.profile_image,
        },
      },
      () => {
        get_data_by_query_not_order(
          'post',
          'post_groups',
          'array-contains',
          old_group_data,
          (posts) => {
            posts.forEach((post) => {
              let new_array = post.post_groups.filter(
                (group) => group.id !== group_data.id
              );

              new_array.push({
                id: group_data.id,
                group_name: group_data.group_name,
                profile_image: group_data.profile_image,
              });

              set_data('post', post.id, { post_groups: new_array }, () => {});
            });
            callback(200);
          }
        );
      }
    );
  });
}

// export function edit_user(old_user_data, user_data, callback) {
//   get_data_by_query_not_order('chat',)
//   set_data("user", user_data.id, user_data, () => {
//     set_data(
//       "chat",
//       group_data.id,
//       {
//         group_data: {
//           id: group_data.id,
//           name: group_data.group_name,
//           profile_image: group_data.profile_image,
//         },
//       },
//       () => {
//         get_data_by_query_not_order(
//           "post",
//           "post_groups",
//           "array-contains",
//           old_group_data,
//           (posts) => {
//             posts.forEach((post) => {
//               let new_array = post.post_groups.filter(
//                 (group) => group.id !== group_data.id
//               );

//               new_array.push({
//                 id: group_data.id,
//                 group_name: group_data.group_name,
//                 profile_image: group_data.profile_image,
//               });

//               set_data("post", post.id, { post_groups: new_array }, () => {});
//             });
//             callback(200);
//           }
//         );
//       }
//     );
//   });
// }
export function set_new_data(
  collection_name,
  object_id,
  updete_values_as_object,
  callback
) {
  const docRef = doc(db, collection_name, object_id);
  setDoc(docRef, updete_values_as_object)
    .then((snapshot) => {
      callback(200);
    })
    .catch((err) => {
      console.log(err);
      callback(404);
    });
}
export function sign_in(user_data, callback) {
  // Attempt to sign in the user
  signInWithEmailAndPassword(auth, user_data.email, user_data.password)
    .then((cred) => {
      // Successful login
      callback({ status: true, user_id: cred.user.uid });
    })
    .catch((err) => {
      if (err.code === 'auth/invalid-credential') {
        // User not found, so create a new user
        createUserWithEmailAndPassword(
          auth,
          user_data.email,
          user_data.password
        )
          .then((cred) => {
            // Successful user creation
            callback({ status: true, user_id: cred.user.uid });
          })
          .catch((createErr) => {
            // Error creating user
            console.log(createErr);
            const result = { status: false, err: createErr.message };
            callback(result);
          });
      } else if (err.code === 'auth/wrong-password') {
        // Wrong password error
        console.log('Wrong password provided.');
        const result = { status: false, err: 'Incorrect password.' };
        callback(result);
      } else {
        // Other errors
        console.log(err);
        const result = { status: false, err: err.message };
        callback(result);
      }
    });
}
export function log_in(user_data, callback) {
  signInWithEmailAndPassword(auth, user_data.email, user_data.password)
    .then((cred) => {
      callback({ status: true, user_id: cred.user.uid });
    })
    .catch((err) => {
      console.log(err);
      callback({ status: false, err: err.message });
    });
}
export function getEmailFromId(userId, callback) {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      callback(user.email);
      // ...
    } else {
      callback();
    }
  });
}
export function check_user_log_in(callback) {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      callback(user.uid);
    } else callback(404);
  });
}
export function log_out(id, callback) {
  signOut(auth)
    .then(() => {
      set_data('user', id, { current_page: 'Home' }, () => {});
      callback(200);
    })
    .catch((err) => {
      console.log(err);
      callback(404);
    });
}
const getImageDimensions = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = () => resolve({ width: 220, height: 300 });
  });
};

export const upload_file = (image, path_name, callback) => {
  store_image(image, path_name, '').then((url) => {
    callback(url);
  });
};
const store_image_url = async (image, pathName, imgName, filter = 'none') => {
  try {
    let imgRef;
    if (imgName !== '') {
      imgRef = ref(imagedb, `${pathName}/${imgName}`);
    } else {
      imgRef = ref(imagedb, `${pathName}/${v4()}`);
    }
    const metadata = {
      contentType: 'image/webp', // Explicitly set MIME type for WebP
    };
    const uploadTask = uploadBytesResumable(imgRef, image, metadata);

    // Show progress indicator
    // uploadTask.on("state_changed", (snapshot) => {
    //   const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //   // console.log("Upload is " + progress + "% done");
    // });

    await new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        null,
        (error) => reject(error),
        () => resolve()
      );
    });

    const url = await getDownloadURL(imgRef);

    // const { width, height } = await getImageDimensions(url);

    // await updateMetadata(imgRef, {
    //   customMetadata: {
    //     width: width,
    //     height: height,
    //     filter: filter,
    //   },
    // });

    return url;
  } catch (error) {
    console.error(error);
    return 404;
  }
};
const store_image = async (image, pathName, imgName, filter = 'none') => {
  try {
    let imgRef;
    if (imgName !== '') {
      imgRef = ref(imagedb, `${pathName}/${imgName}`);
    } else {
      imgRef = ref(imagedb, `${pathName}/${v4()}`);
    }

    const uploadTask = uploadBytesResumable(imgRef, image);

    // Show progress indicator
    // uploadTask.on("state_changed", (snapshot) => {
    //   const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //   // console.log("Upload is " + progress + "% done");
    // });

    await new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        null,
        (error) => reject(error),
        () => resolve({ success: true, imgRef })
      );
    });

    // const url = await getDownloadURL(imgRef);

    // const { width, height } = await getImageDimensions(url);

    // await updateMetadata(imgRef, {
    //   customMetadata: {
    //     width: width,
    //     height: height,
    //     filter: filter,
    //   },
    // });

    // return url;
    return { success: true, imgRef };
  } catch (error) {
    console.error(error);
    return { success: false, error };
  }
};

// const store_image = (image, path_name, img_name, filter = "none") => {
//   return new Promise((res, rej) => {
//     let imgRef;
//     if (img_name !== "") imgRef = ref(imagedb, `${path_name}/${img_name}`);
//     else imgRef = ref(imagedb, `${path_name}/${v4()}`);
//     console.log(imgRef);
//     uploadBytes(imgRef, image).then((snapshot) => {
//       getDownloadURL(imgRef)
//         .then(async (url) => {
//           await getImageDimensions(url).then(({ width, height }) => {
//             updateMetadata(imgRef, {
//               customMetadata: {
//                 width: width,
//                 height: height,
//                 // filter: filter,
//               },
//             });
//           });

//           res(url);
//         })
//         .catch((err) => {
//           console.log(err);
//           res(404);
//         });
//     });
//   }).catch((err) => {
//     console.log(err);
//   });
// };
export const upload_profile_image = async (
  image,
  path_name,
  event_id,
  user_id,
  id,
  filter = 'none'
) => {
  console.log(path_name, event_id, user_id, id);
  return new Promise((res, rej) => {
    store_image_url(image, path_name, id + '_' + user_id, filter).then(
      async (url) => {
        const event_ref = doc(db, 'event', event_id);
        const event_collection = doc(event_ref, 'image', id + '_' + user_id);
        if (url !== 404)
          setDoc(event_collection, {
            src: url,
            upload_at: id,
            user: user_id,
          })
            .then((result) => {
              res({ success: true });
            })
            .catch((err) => {
              console.log(err);
              rej({ success: false });
            });
      }
    );
  });
};
// export const upload_profile_image = (image, path_name, id, filter = "none") => {
//   return new Promise((resolve, reject) => {
//     store_image(image, path_name, id, filter)
//       .then((result) => resolve(result))
//       .catch((error) => reject(error));
//   });
// };
export const upload_bg_image = (
  image,
  path_name,
  id,
  filter = 'none',

  callback
) => {
  store_image_url(image, path_name, id, filter).then((url) => {
    const data = url;

    callback(data);
  });
};
export const upload_group_profile_image = (image, path_name, id, callback) => {
  store_image(image, path_name, id).then((url) => {
    const data = url;
    callback(data);
  });
};
export const upload_group_bg_image = (image, path_name, callback) => {
  store_image(image, path_name, '').then((url) => {
    const data = url;
    callback(data);
  });
};

//////////////////////
////////////////
////////////
////////
///////
/////////////
///////////////
////////////////////
//////////////
//////////
//////////
////////
///////
//////
/////
////
///
//

export function get_appointments(date, tommorow, callback) {
  const collectionRef = collection(db, 'appointment');
  const q = query(
    collectionRef,
    where('day', '>', date),
    where('day', '<', tommorow),

    orderBy('day', 'asc')
  );
  return onSnapshot(q, (snapshot) => {
    let data = [];
    snapshot.docs.map((doc) => {
      data.push({
        ...doc.data(),
        day: new Date(doc.data().day.seconds * 1000),
        id: doc.id,
      });
    });

    callback(data);
  });
}

export function get_my_appointments(phone, callback) {
  // let data = [];

  const collectionRef = collection(db, 'appointment');
  const q = query(
    collectionRef,
    where('phone', '==', phone),

    orderBy('day', 'asc')
  );
  // getDocs(q)
  //   .then((snapshot) => {
  //     snapshot.docs.map((doc) => {
  //       data.push({
  //         ...doc.data(),
  //         day: new Date(doc.data().day.seconds * 1000),
  //         id: doc.id,
  //       });
  //     });
  //     callback(data);
  //   })
  //   .catch((err) => {
  //     callback(404);
  //   });
  // let data = [];

  return onSnapshot(q, (snapshot) => {
    let data = [];
    snapshot.docs.map((doc) => {
      data.push({
        ...doc.data(),
        day: new Date(doc.data().day.seconds * 1000),
        id: doc.id,
      });
    });

    callback(data);
  });
}
export function get_days_off(callback) {
  const docRef = doc(db, 'bar', 'days_off');
  getDoc(docRef)
    .then((snapshot) => {
      const data = snapshot.data();
      let days = [];
      data.days_off.map((day) => {
        days.push(new Date(day.seconds * 1000));
      });
      callback(days);
    })
    .catch((err) => {
      console.log(err);
      callback(404);
    });
}

export function add_appointment(app_object, callback) {
  add_data('appointment', app_object, () => {
    get_data_by_id(
      'popular_hours',
      `${app_object.day.getHours() + app_object.day.getMinutes() / 60}`,
      (data) => {
        set_new_data(
          'popular_hours',
          `${app_object.day.getHours() + app_object.day.getMinutes() / 60}`,
          { count: data ? data.count + 1 : 1 },
          () => {
            callback(200);
          }
        );
      }
    );
    get_data_by_id('popular_day', `${app_object.day.getDay()}`, (data) => {
      set_new_data(
        'popular_day',
        `${app_object.day.getDay()}`,
        { count: data ? data.count + 1 : 1 },
        () => {
          callback(200);
        }
      );
    });
    get_data_by_id(
      'profit',
      `${app_object.day.getMonth() + 1 + '.' + app_object.day.getFullYear()}`,
      (data) => {
        set_new_data(
          'profit',
          `${
            app_object.day.getMonth() + 1 + '.' + app_object.day.getFullYear()
          }`,
          { income: data ? data.income + app_object.price : app_object.price },
          () => {
            callback(200);
          }
        );
      }
    );
  });
}
export function cancle_appointment(app_object, callback) {
  delete_data('appointment', app_object.id, () => {
    get_data_by_id(
      'popular_hours',
      `${app_object.day.getHours() + app_object.day.getMinutes() / 60}`,
      (data) => {
        set_new_data(
          'popular_hours',
          `${app_object.day.getHours() + app_object.day.getMinutes() / 60}`,
          { count: data ? data.count - 1 : 0 },
          () => {
            callback(200);
          }
        );
      }
    );
    get_data_by_id('popular_day', `${app_object.day.getDay()}`, (data) => {
      set_new_data(
        'popular_day',
        `${app_object.day.getDay()}`,
        { count: data ? data.count - 1 : 0 },
        () => {
          callback(200);
        }
      );
    });
    get_data_by_id(
      'profit',
      `${app_object.day.getMonth() + 1 + '.' + app_object.day.getFullYear()}`,
      (data) => {
        set_new_data(
          'profit',
          `${
            app_object.day.getMonth() + 1 + '.' + app_object.day.getFullYear()
          }`,
          { income: data ? data.income - app_object.price : 0 },
          () => {
            callback(200);
          }
        );
      }
    );
    if (app_object.credit) {
      set_data(
        'user',
        app_object.phone,
        { credit: app_object.credit },
        () => {}
      );
    }
  });
}
export function appointment_didnt_happend(app_object, callback) {
  set_data(
    'appointment',
    app_object.id,
    { phone: app_object.phone.slice(0, 10) + '_not' },
    () => {
      get_data_by_id(
        'popular_hours',
        `${app_object.day.getHours() + app_object.day.getMinutes() / 60}`,
        (data) => {
          set_new_data(
            'popular_hours',
            `${app_object.day.getHours() + app_object.day.getMinutes() / 60}`,
            { count: data ? data.count - 1 : 0 },
            () => {
              callback(200);
            }
          );
        }
      );
      get_data_by_id('popular_day', `${app_object.day.getDay()}`, (data) => {
        set_new_data(
          'popular_day',
          `${app_object.day.getDay()}`,
          { count: data ? data.count - 1 : 0 },
          () => {
            callback(200);
          }
        );
      });
      get_data_by_id(
        'profit',
        `${app_object.day.getMonth() + 1 + '.' + app_object.day.getFullYear()}`,
        (data) => {
          set_new_data(
            'profit',
            `${
              app_object.day.getMonth() + 1 + '.' + app_object.day.getFullYear()
            }`,
            { income: data ? data.income - app_object.price : 0 },
            () => {
              callback(200);
            }
          );
          get_data_by_id('user', app_object.phone, (user) => {
            set_data(
              'user',
              app_object.phone,
              {
                credit: user.credit
                  ? app_object.credit
                    ? app_object.credit +
                      user.credit -
                      Math.ceil(app_object.price / 2)
                    : user.credit - Math.ceil(app_object.price / 2)
                  : -Math.ceil(app_object.price / 2),
              },
              () => {}
            );
          });
        }
      );
    }
  );
}
export function export_data() {
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  get_data_by_query(
    'appointment',
    'day',
    '<',
    Timestamp.fromDate(today),
    (data) => {
      console.log(data);
      if (data.length > 0) {
        const header = Object.keys(data[0]).join(',') + '\n';
        const body = data.map((row) => Object.values(row).join(',')).join('\n');
        console.log(header + body);
      }
    }
  );
}
export function get_token(callback) {
  ////// getToken(messaging, { vapidKey: pair_key }).then((currentToken) => {
  //   if (currentToken) {
  //     callback(currentToken);
  //   } else {
  //     console.log("Cannot get FCM token");
  //     callback("");
  //   }
  // });
}
export function check_first_appointment(phone, callback) {
  const collectionRef = collection(db, 'appointment');
  const q = query(collectionRef, where('phone', '==', phone), limit(1));
  getDocs(q, collectionRef)
    .then((snapshot) => {
      console.log(snapshot.docs);
      if (snapshot.docs.length > 0) callback(true);
      else callback(false);
    })
    .catch((err) => {
      callback();
    });
}
export function get_user_data(phone, callback) {
  // let data = [];

  const docRef = doc(db, 'user', phone);

  return onSnapshot(docRef, (snapshot) => {
    let data;
    data = snapshot.data();
    callback(data);
  });
}
export function get_config(callback) {
  // let data = [];

  const docRef = doc(db, 'bar', 'config');

  return onSnapshot(docRef, (snapshot) => {
    let data;
    data = snapshot.data();
    callback(data);
  });
}
export function get_day_hours(day, callback) {
  const docRef = doc(db, 'hours', day);

  return onSnapshot(docRef, (snapshot) => {
    let data;
    data = snapshot.data();
    callback(data);
  });
}
export function get_missed_appointment(phone, callback) {
  let data = [];

  const collectionRef = collection(db, 'appointment');
  const q = query(
    collectionRef,
    where('phone', '==', `${phone}_not`),
    orderBy('day', 'desc'),
    limit(1)
  );
  getDocs(q, collectionRef)
    .then((snapshot) => {
      snapshot.docs.map((doc) => {
        console.log(doc.data());

        data.push({
          ...doc.data(),
          day: new Date(doc.data().day.seconds * 1000),
          id: doc.id,
        });
      });

      callback(data);
    })
    .catch((err) => {
      console.log(err);
      callback([]);
    });
}
export function get_appointment_to_cancle(date, callback) {
  let data = [];

  const collectionRef = collection(db, 'appointment');
  const q = query(collectionRef, where('day', '==', date));
  getDocs(q, collectionRef)
    .then((snapshot) => {
      snapshot.docs.map((doc) => {
        const app = doc.data();
        if (app.phone)
          data.push({
            ...app,
            day: new Date(app.day.seconds * 1000),
            id: doc.id,
          });
      });

      callback(data);
    })
    .catch((err) => {
      console.log(err);
      callback([]);
    });
}

export function get_reviews(last, callback) {
  let data = [];

  const collectionRef = collection(db, 'review');
  const first = query(collectionRef, orderBy('stars', 'desc'), limit(4));
  const next = query(
    collectionRef,
    orderBy('stars', 'desc'),
    startAfter(last),
    limit(4)
  );
  const q = last === 0 ? first : next;
  getDocs(q, collectionRef)
    .then((snapshot) => {
      snapshot.docs.map((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      data.push(snapshot.docs[snapshot.docs.length - 1]);
      callback(data);
    })
    .catch((err) => {
      console.log(err);
      callback([]);
    });
}
// export function add_event(
//   user_id,

//   event_data,
//   callback
// ) {
//   console.log(user_id);
//   const user_ref = doc(db, "user", user_id);
//   const event_collection = collection(user_ref, "event_data");

//   addDoc(event_collection, event_data)
//     .then((result) => {
//       callback(result);
//     })
//     .catch((err) => {
//       console.log(err);
//       callback(err);
//     });
// }
export function set_event(user_id, event_id, event_data, callback) {
  console.log(user_id);
  const user_ref = doc(db, 'user', user_id);
  const event_collection = collection(user_ref, 'event_data');
  const event_doc = doc(event_collection, event_id);
  setDoc(event_doc, event_data)
    .then((result) => {
      callback(result);
    })
    .catch((err) => {
      console.log(err);
      callback(err);
    });
}
export function get_events(user_id, callback) {
  const event_collection = collection(doc(db, 'user', user_id), 'event_data');

  let data = [];

  getDocs(event_collection)
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        data.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      callback(data);
      data = [];
    })
    .catch((error) => {
      console.error('Error getting documents: ', error);
      callback([]); // Callback with empty array in case of error
    });
}
export function get_my_user_events(user_id, callback) {
  let data = [];

  const collectionRef = collection(db, 'event');
  const q = query(collectionRef, where('user_id', '==', user_id));
  getDocs(q, collectionRef)
    .then((snapshot) => {
      snapshot.docs.map((doc) => {
        const eventData = doc.data();
        data.push({
          ...eventData,
          id: doc.id,
          eventDate: new Date(eventData?.eventDate.seconds * 1000),
          createdAt: new Date(eventData?.createdAt.seconds * 1000),
          lastModify: eventData?.lastModify
            ? new Date(eventData?.lastModify.seconds * 1000)
            : new Date(eventData?.createdAt.seconds * 1000),
        });
      });

      callback(data);
    })
    .catch((err) => {
      console.log(err);
      callback([]);
    });
}

export function get_event_by_id(event_id, callback) {
  const event_doc_ref = doc(db, 'event', event_id);

  getDoc(event_doc_ref)
    .then((docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const eventData = {
          ...data,
          id: docSnapshot.id,
          config_data: {
            ...data.config_data,
          },
          eventDate: new Date(data.eventDate.seconds * 1000),
          createdAt: new Date(data.createdAt.seconds * 1000),
          lastModify: data.lastModify
            ? new Date(data.lastModify.seconds * 1000)
            : new Date(data.createdAt.seconds * 1000),
        };
        callback(eventData);
      } else {
        callback(); // Callback with null if document does not exist
      }
    })
    .catch((error) => {
      console.error('Error getting document: ', error);
      callback(); // Callback with null in case of error
    });
}
export async function getImagesInFolder_old(folderPath, pageToken, subfolder) {
  try {
    const folderRef = ref(imagedb, folderPath);
    const listed_folder = await listAll(folderRef);
    const all_sub_folders = listed_folder.prefixes;
    if (subfolder > all_sub_folders.length - 1)
      return { items: [], nextPageToken: null, subfolder: -1 };

    const fullPath = all_sub_folders[subfolder].fullPath;
    const fullfolderRef = ref(imagedb, fullPath);

    const pageOptions = { maxResults: 6, pageToken };
    const page = await list(fullfolderRef, pageOptions);

    // Get download URLs for each image in the current page
    const downloadURLs = await Promise.all(
      page.items.map(async (itemRef) => {
        const src = await getDownloadURL(itemRef);
        const { width, height } = (await getImageDimensions(src)) || {
          width: 220,
          height: 300,
        };

        return {
          name: itemRef.name,
          src,
          width,
          height,
          filter: 'none',
        };
      })
    );

    return {
      items: downloadURLs,
      nextPageToken: page.nextPageToken ? page.nextPageToken : null,
      subfolder: page.nextPageToken ? subfolder : subfolder + 1,
    };
  } catch (error) {
    console.error('Error getting images:', error);
    return { items: [], nextPageToken: null, subfolder: -1 };
  }
}
export async function delete_event_img(event_id, image_id, callback) {
  try {
    const event_ref = doc(db, 'event', event_id);
    const img_collection = collection(event_ref, 'image');
    const img_doc = doc(img_collection, image_id);
    await deleteDoc(img_doc);
    callback();
  } catch (error) {
    console.error('Error deleting image:', error);
    // Optionally, you can call the callback with an error
    callback(error);
  }
}
async function getImageById(event_id, doc_id) {
  const docRef = doc(db, 'event', event_id, 'image', doc_id);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap; // Return the DocumentSnapshot
  } else {
    throw new Error('No such document!');
  }
}
export async function getImagesInFolder(event_id, last, callback) {
  try {
    // let document = null;

    // // Fetch the document snapshot if `last` is not null
    // if (last !== null) {
    //   document = await getImageById(event_id, last); // Await the promise
    // }
    let images_array = [];
    const event_collection = collection(doc(db, 'event', event_id), 'image');

    const queryfirst = query(event_collection, orderBy('upload_at'), limit(10));
    const queryNext = query(
      event_collection,
      orderBy('upload_at'),
      startAfter(last),

      limit(10)
    );

    const q = last === null ? queryfirst : queryNext;

    const snapshot = await getDocs(q);

    const promises = snapshot.docs.map(async (doc) => {
      const img_data = doc.data();
      const { width, height } = (await getImageDimensions(img_data.src)) || {
        width: 220,
        height: 300,
      };

      return {
        ...img_data,
        width: width,
        height: height,
        name: doc.id,
        key: doc.id,
        id: doc.id,
      };
    });

    images_array = await Promise.all(promises);

    const next_token =
      snapshot.docs.length === 10
        ? snapshot.docs[snapshot.docs.length - 1]
        : null;
    callback({
      items: images_array,
      next_token: next_token,
    });
  } catch (error) {
    console.error('Error getting images:', error);
    callback({
      items: [],
      next_token: null,
    });
  }
}
export async function getMyImages(event_id, user_id, callback) {
  try {
    let images_array = [];
    const event_collection = collection(doc(db, 'event', event_id), 'image');
    const q = query(event_collection, where('user', '==', user_id));
    const snapshot = await getDocs(q);

    const promises = snapshot.docs.map(async (doc) => {
      const img_data = doc.data();
      const { width, height } = (await getImageDimensions(img_data.src)) || {
        width: 220,
        height: 300,
      };

      return {
        ...img_data,
        width: width,
        height: height,
        name: doc.id,
        key: doc.id,
        id: doc.id,
      };
    });

    images_array = await Promise.all(promises);
    const next_token =
      snapshot.docs.length > 0
        ? snapshot.docs[snapshot.docs.length - 1].id
        : null;

    callback({
      items: images_array,
      next_token: next_token,
    });
  } catch (error) {
    console.error('Error getting images:', error);
    callback({
      items: [],
      next_token: null,
    });
  }
}
export async function getMyImages_old(folderPath, user) {
  try {
    // Reference to the folder in Firebase Storage
    const folderRef = ref(imagedb, folderPath);

    // List all items in the folder
    const allItems = await listAll(folderRef);

    // Filter items that end with '1'
    const filteredItems = allItems.items.filter((itemRef) =>
      itemRef.name.endsWith(`_${user}`)
    );

    // Get download URLs and metadata for each filtered item
    const downloadURLs = await Promise.all(
      filteredItems.map(async (itemRef) => {
        const src = await getDownloadURL(itemRef);
        const { width, height } = (await getImageDimensions(src)) || {
          width: 220,
          height: 300,
        };

        return { name: itemRef.name, src, width, height, filter: 'none' };
      })
    );

    // Return the download URLs
    return { items: downloadURLs };
  } catch (error) {
    console.error('Error getting images:', error);
    return { items: [] };
  }
}
export async function deleteImage(folderPath, imageName) {
  try {
    // Reference to the specific image in Firebase Storage
    const imageRef = ref(imagedb, `${folderPath}/${imageName}`);

    // Delete the image
    await deleteObject(imageRef);

    // Return success message
    return {
      success: true,
      message: `Image ${imageName} deleted successfully`,
    };
  } catch (error) {
    console.error('Error deleting image:', error);
    return { success: false, message: `Failed to delete image ${imageName}` };
  }
}
async function deleteFolder(folderPath) {
  try {
    const folderRef = ref(imagedb, folderPath);
    const listResult = await listAll(folderRef);

    // Delete all files in the folder
    const deleteFilePromises = listResult.items.map((itemRef) => {
      return deleteObject(itemRef);
    });

    // Wait for all delete operations to complete
    await Promise.all(deleteFilePromises);

    console.log(`All files in the folder ${folderPath} have been deleted.`);
  } catch (error) {
    console.error('Error deleting folder:', error);
  }
}
export async function deleteEvent(event_id, img_url) {
  try {
    // Reference to the specific event document
    const urlParts = img_url.split('/o/'); // Split the URL to get the path
    const path = decodeURIComponent(urlParts[1].split('?')[0]);
    console.log(path);
    const fileRef = ref(imagedb, path);
    await deleteObject(fileRef);
    const eventRef = doc(db, 'event', event_id);
    const counterRef = doc(db, 'counters', event_id);

    // Reference to the images sub-collection
    const imagesCollectionRef = collection(db, 'event', event_id, 'image');

    // Get all documents in the 'image' sub-collection
    const imagesSnapshot = await getDocs(imagesCollectionRef);

    // Delete each document in the 'image' sub-collection
    const deleteImagePromises = imagesSnapshot.docs.map((doc) =>
      deleteDoc(doc.ref)
    );
    await Promise.all(deleteImagePromises);

    // Delete the event and counter documents
    await deleteDoc(eventRef);
    await deleteDoc(counterRef);

    // Delete the folder from storage
    await deleteFolder(event_id);

    // Return success message
    return {
      success: true,
    };
  } catch (error) {
    console.error('Error deleting event:', error);
    return { success: false };
  }
}
export async function incrementCounter(id, callback) {
  try {
    const counterDocRef = doc(db, 'counters', id);
    const docSnapshot = await getDoc(counterDocRef);

    if (docSnapshot.exists()) {
      const currentCount = docSnapshot.data().counter || 0;
      const updatedCount = currentCount + 1;

      // Set the updated count back to Firestore
      await setDoc(counterDocRef, { counter: updatedCount });

      callback(currentCount);
    } else {
      // Create a new document with counter set to 1
      await setDoc(counterDocRef, { counter: 1 });

      callback(0); // Since the document didn't exist previously, currentCount is considered 0
    }
  } catch (error) {
    console.error('Error incrementing counter:', error);
  }
}
