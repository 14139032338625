import React, { useRef, useState, useEffect } from 'react';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';

const ImageCropper = ({ imageSrc, setCroppedImage, image_change }) => {
  const imageRef = useRef(null);
  const [cropperInstance, setCropperInstance] = useState(null);

  useEffect(() => {
    if (cropperInstance) {
      cropperInstance.destroy();
    }

    // Wait for image to fully load before initializing cropper
    const initializeCropper = () => {
      const cropper = new Cropper(imageRef.current, {
        aspectRatio: 0.491, // Phone ratio 9:16
        viewMode: 0,
        autoCropArea: 1,
        scalable: false,
        zoomable: false,
        ready() {
          // Trigger crop once the image is ready
          const croppedCanvas = cropper.getCroppedCanvas();
          croppedCanvas.toBlob((blob) => {
            setCroppedImage(blob); // Pass the cropped Blob to the parent
          }, 'image/jpeg');
        },
      });
      setCropperInstance(cropper);
    };

    if (imageRef.current && imageSrc) {
      const imgElement = imageRef.current;
      imgElement.onload = initializeCropper;
      imgElement.src = imageSrc; // Set the src to trigger onload
    }

    return () => {
      if (cropperInstance) {
        cropperInstance.destroy();
      }
    };
  }, [image_change]);

  return (
    <div style={{ display: 'none' }}>
      {/* Hidden image used by cropper */}
      <img
        ref={imageRef}
        src={imageSrc}
        alt='Original'
        style={{ display: 'none' }}
      />

      {/* Display the cropped image
      {croppedImage && (
        <div
          style={{
            width: '3vw',
            position: 'fixed',
            left: '20px',
            scale: '0.1',
            top: '10px',
          }}
        >
          <h3>Cropped Image:</h3>
          <img src={croppedImage} alt='Cropped' />
        </div>
      )} */}
    </div>
  );
};

export default ImageCropper;
