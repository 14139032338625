import React, { useEffect, useRef } from 'react';
import './Section_1.css';

import Card from './Card';
import img1 from '../../../images/img1.jpg';
import wait from '../../../images/wait.jpg';
import event_qr from '../../../images/event_qr.jpg';
import img4 from '../../../images/img4.jpg';
import event_phone from '../../../images/event_phone.jpg';
import img6 from '../../../images/img6.jpg';
import img7 from '../../../images/img7.jpg';

import scan_me from '../../../images/סרקו_אותי.png';
import img9 from '../../../images/img9.jpg';
import phonee_bg from '../../../images/phone_gallery.jpg';

import FontPicker from 'font-picker-react';
import BackGround from '../BackGround';
import zIndex from '@mui/material/styles/zIndex';
import Image_card from './Image_card';
import Scene from '../../Phone_card/Scene';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import { Text, OrbitControls } from "@react-three/drei";
const Section_1 = (props) => {
  useEffect(() => {
    if (window.innerWidth >= 900) {
      AOS.init({
        duration: 1000, // Duration of the animations in milliseconds
      });
    }
  }, []);
  return (
    <div className='section_1'>
      <div className='section_1_home_page'>
        {window.innerWidth < 900 ? (
          <div className='section_1_header'>
            <span className='section_1_header_span'>
              השיגו{' '}
              <span style={{ fontWeight: '900', scale: '1.1' }}>יותר</span>
            </span>
            <span className='section_1_header_span'>
              {' '}
              <span style={{ fontWeight: '900' }}>מהאירוע</span> שלכם
            </span>
          </div>
        ) : (
          <div className='section_1_header'>
            <span className='section_1_header_span' data-aos='fade-left'>
              השיגו{' '}
              <span style={{ fontWeight: '900', scale: '1.1' }}>יותר</span>
            </span>
            <span
              className='section_1_header_span'
              data-aos-delay='400'
              data-aos='fade-left'
            >
              {' '}
              <span style={{ fontWeight: '900' }}>מהאירוע</span> שלכם
            </span>
          </div>
        )}
        <main className='section_1_main'>
          <p>
            באמצעות{' '}
            <span
              style={{
                fontFamily: 'Montserrat',
                fontSize: 'var(--small)',
                fontWeight: '600',
              }}
            >
              Moments
            </span>{' '}
            האורחים שלכם הופכים להיות חלק מהאירוע המיוחד והמרגש שלכם, ואתם זוכים
            באלבום תמונות מהמם מנקודת המבט שלהם!
          </p>
          <span className='more_event'>
            יותר רגעים <span style={{ fontWeight: '900' }}>מרגשים</span>
          </span>
        </main>
      </div>
    </div>
  );
};

export default Section_1;
