import React, { useEffect, useRef, useState } from 'react';
import './Small_Phone.css';
import blank_phone from '../../../images/phone.png';
import img_url from '../../../images/deafult.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import imageCompression from 'browser-image-compression';
import Draggable from 'react-draggable'; // The default
import html2canvas from 'html2canvas'; // Import html2canvas
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import * as ci from 'react-icons/ci';
import * as pi from 'react-icons/pi';
import * as go from 'react-icons/go';
import * as io from 'react-icons/io';
import {
  calculateFontSize,
  calculateScaledFontSize,
  convert_y_position,
  lastCodeModify,
} from '../../../Util/helpers';
import ImageCropper from '../../ImageCropper';

const Small_Phone = (props) => {
  const containerRef = useRef();
  const [bounds, setBounds] = useState({});
  const nameRef = useRef();
  const dateRef = useRef();
  const [nameHeight, setNameHeight] = useState(0);
  const [dateHeight, setDateHeight] = useState(0);
  const [nameBounds, setNameBounds] = useState({});
  const [dateBounds, setDateBounds] = useState({});
  const [containerHeight, setContainerHeight] = useState(0);
  const [croppedImage, setCroppedImage] = useState(null);
  const [image_change, set_image_change] = useState(false);
  useEffect(() => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.offsetHeight);
    }
  }, []);
  const [name_y, set_name_y] = useState(0);
  const [date_y, set_date_y] = useState(0);
  useEffect(() => {
    if (props.is_set_img > 0) bg_img_ref.current.click();
  }, [props.is_set_img]);

  useEffect(() => {
    if (containerRef.current) {
      const { offsetHeight } = containerRef.current;
      const nameFontSize =
        (calculateFontSize(
          props.event_name.font_size,
          window.innerWidth,
          window.innerHeight,
          props.phoneRef.current.offsetWidth,
          props.phoneRef.current.offsetHeight
        ) *
          window.innerWidth) /
        100;
      const dateFontSize =
        (calculateFontSize(
          props.date.font_size,
          window.innerWidth,
          window.innerHeight,
          props.phoneRef.current.offsetWidth,
          props.phoneRef.current.offsetHeight
        ) *
          window.innerWidth) /
        100;
      setNameHeight(nameFontSize);
      setDateHeight(dateFontSize);
      setNameBounds({
        top: 0,
        bottom: offsetHeight,
      });
      setDateBounds({
        top: 0,
        bottom: offsetHeight,
      });
    }
  }, [containerRef.current]);

  const [img_file, set_img_file] = useState(
    props.image_url ? props.image_url : null
  );
  const bg_img_ref = useRef(null);
  const handleselect = () => {
    bg_img_ref.current.click();
  };

  async function onSelectCoupon(e) {
    try {
      let file = e.target.files?.[0];

      if (!file) return;
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        const imgurl = reader.result?.toString() || '';
        set_img_file(imgurl);
        set_image_change(!image_change);
      });
      reader.readAsDataURL(file);
    } catch (error) {
      console.log(error);
    }
  }
  const cropImage = async (file) => {
    try {
      const options = {
        maxSizeMB: 2, // Max size of the compressed file
        maxWidthOrHeight: 3000, // Resize dimensions
        useWebWorker: true, // To speed up compression using web workers
      };

      const croppedFile = await imageCompression(file, options); // Compress the cropped image
      // Pass the compressed cropped file to the parent component if needed
      props.handle_bg_img(croppedFile);

      // Read the compressed cropped image file and update the preview
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const imgurl = reader.result?.toString() || '';
        set_img_file(imgurl); // Update the preview with cropped and compressed image
      });
      reader.readAsDataURL(croppedFile);
    } catch (error) {
      console.error('Error cropping the image:', error);
    }
  };
  const percentageToPixel = (
    percentage,
    containerHeight,
    myHeight,
    firstLine
  ) => {
    if (myHeight > 0) {
      let yPx = (1 - percentage / 100) * containerHeight - 0; // yPx += firstLine ? dateHeight : nameHeight;

      return yPx;
    } else {
      return 40; // Fallback if myHeight is not provided
    }
  };

  // Function to handle downloading the component as an image
  // useEffect(() => {
  //   if (props.handleCreateImages()) props.handleCreateImages();
  // }, [props.phoneRef]);
  return (
    <div className='small_phone_container'>
      <div
        className='add_event_small_phone_container'
        ref={props.phoneRef}
        style={{
          transform: `scale(${props.page < 2 ? 1.5 : 1})`,
          transition: 'transform 1s',
          transitionDelay: '0.2s',
          backgroundColor: 'transparent',
        }}
        onClick={() => {
          if (!props.isFilePage) bg_img_ref.current.click();
        }}
      >
        <LazyLoadImage
          className='add_event_small_phone_bg'
          alt='Gallery Phone'
          src={img_file || img_url}
        ></LazyLoadImage>
        <LazyLoadImage
          className='add_event_small_phone_case'
          alt='Gallery Phone'
          src={blank_phone}
          style={{ zIndex: 4, objectFit: 'cover' }}
        ></LazyLoadImage>
        <div style={{ height: '15%' }}></div>
        <main className='small_phone_main' ref={containerRef}>
          {containerRef.current ? (
            <div className='drag_sectio'>
              {/* Event name draggable section */}
              {props.event_name.name === '' ? (
                ''
              ) : props.isFilePage ? (
                <div
                  className={`event_obj_name_container`}
                  style={{
                    width: '100%',
                    top: `${100 - props.event_name.y * 0.96}%`,
                    color: props.event_name.font_color,
                    backgroundColor:
                      props.event_name.opacity === 100
                        ? props.event_name.font_bg_color
                        : props.event_name.font_bg_color +
                          '' +
                          props.event_name.opacity,
                    zIndex: '2',
                  }}
                >
                  {' '}
                  <span
                    className={`event_headers_span  ${props.event_name.font.name}`}
                    style={{
                      fontSize: calculateScaledFontSize(
                        props.event_name.font_size,
                        window.innerWidth,
                        window.innerHeight,
                        props.phoneRef.current.offsetWidth * 0.87,
                        props.phoneRef.current.offsetHeight * 0.87
                      ),
                    }}
                  >
                    {props.event_name.name}
                  </span>
                </div>
              ) : (
                <Draggable
                  disabled={props.dont_show_draggers}
                  axis='y'
                  position={{
                    x: 0,
                    y: percentageToPixel(
                      props.event_name.y,
                      containerRef.current.offsetHeight,
                      nameHeight,
                      true
                    ),
                  }}
                  defaultPosition={{
                    x: 0,
                    y: percentageToPixel(
                      props.event_name.y,
                      containerRef.current.offsetHeight,
                      nameHeight,
                      true
                    ),
                  }}
                  scale={1}
                  bounds={nameBounds}
                  onStop={(e, data) => {
                    if (containerRef.current) {
                      const containerHeight = containerRef.current.offsetHeight;
                      const percentageY =
                        (1 - data.lastY / containerHeight) * 100;

                      props.handle_event_data({
                        event_name: {
                          ...props.event_name,
                          y: percentageY,
                        },
                      });
                    }
                  }}
                >
                  <div
                    ref={nameRef}
                    className={`small_phone_name_container `}
                    style={{
                      color: props.event_name.font_color,
                      backgroundColor:
                        props.event_name.opacity === 100
                          ? props.event_name.font_bg_color
                          : props.event_name.font_bg_color +
                            '' +
                            props.event_name.opacity,
                    }}
                  >
                    {!props.dont_show_draggers && (
                      <div className='left_traingle'>
                        <io.IoIosMenu></io.IoIosMenu>
                      </div>
                    )}
                    <span
                      className={`event_headers_span ${props.event_name.font.name}`}
                      style={{
                        fontSize: calculateScaledFontSize(
                          props.event_name.font_size,
                          window.innerWidth,
                          window.innerHeight,
                          props.phoneRef.current.offsetWidth,
                          props.phoneRef.current.offsetHeight
                        ),
                      }}
                    >
                      {props.event_name.name}
                    </span>
                  </div>
                </Draggable>
              )}

              {/* Date draggable section */}
              {props.date.name === '' ? (
                ''
              ) : props.isFilePage ? (
                <div
                  className={`event_obj_name_container `}
                  style={{
                    width: '100%',
                    zIndex: '3',
                    top:
                      props.lastModify && props.lastModify > lastCodeModify
                        ? `${
                            100 -
                            props.date.y +
                            (props.date.y > 50
                              ? props.event_name.font_size / 4
                              : 10)
                          }%`
                        : props.date.y < 50
                        ? ''
                        : `${100 - props.date.y}%`,

                    bottom:
                      !(
                        props.lastModify && props.lastModify > lastCodeModify
                      ) && props.date.y >= 50
                        ? ''
                        : `${props.date.y}%`,
                    color: props.date.font_color,
                    backgroundColor:
                      props.date.font_bg_color + '' + props.date.opacity,
                  }}
                >
                  {' '}
                  <span
                    className={`event_headers_span ${props.date.font.name}`}
                    style={{
                      fontSize: calculateScaledFontSize(
                        props.date.font_size,
                        window.innerWidth,
                        window.innerHeight,
                        props.phoneRef.current.offsetWidth * 0.87,
                        props.phoneRef.current.offsetHeight * 0.87
                      ),
                    }}
                  >
                    {props.date.name}
                  </span>
                </div>
              ) : (
                <Draggable
                  disabled={props.dont_show_draggers}
                  axis='y'
                  defaultPosition={{
                    x: 0,
                    y: percentageToPixel(
                      props.date.y,
                      containerRef.current.offsetHeight,
                      dateHeight,
                      false
                    ),
                  }}
                  position={{
                    x: 0,
                    y: percentageToPixel(
                      props.date.y,
                      containerRef.current.offsetHeight,
                      dateHeight,
                      false
                    ),
                  }}
                  scale={1}
                  bounds={dateBounds}
                  onStop={(e, data) => {
                    if (containerRef.current) {
                      const containerHeight = containerRef.current.offsetHeight;
                      const percentageY =
                        (1 - data.lastY / containerHeight) * 100;
                      props.handle_event_data({
                        date: {
                          ...props.date,
                          y: percentageY,
                        },
                      });
                    }
                  }}
                >
                  <div
                    ref={dateRef}
                    className={`small_phone_name_container`}
                    style={{
                      top: `${100 - props.date.y}%`,
                      color: props.date.font_color,
                      backgroundColor:
                        props.date.font_bg_color + '' + props.date.opacity,
                    }}
                  >
                    {!props.dont_show_draggers && (
                      <div className='right_traingle'>
                        <io.IoIosMenu></io.IoIosMenu>
                      </div>
                    )}

                    <span
                      className={`event_headers_span  ${props.date.font.name}`}
                      style={{
                        fontSize: calculateScaledFontSize(
                          props.date.font_size,
                          window.innerWidth,
                          window.innerHeight,
                          props.phoneRef.current.offsetWidth,
                          props.phoneRef.current.offsetHeight
                        ),
                      }}
                    >
                      {props.date.name}
                    </span>
                  </div>
                </Draggable>
              )}
            </div>
          ) : (
            ''
          )}
        </main>
        {props.phoneRef.current && (
          <footer className='small_phone_footer'>
            {/* Footer with buttons */}
            <div
              className='small_phone_circle_3'
              style={{
                backgroundColor: props.button_color,
                color: props.is_dark_color ? 'white' : 'black',
                width: props.phoneRef.current.offsetWidth / 7,
                height: props.phoneRef.current.offsetWidth / 7,
              }}
            >
              <ci.CiImageOn className='small_icon'></ci.CiImageOn>
            </div>
            <div
              className='small_phone_circle_2'
              style={{
                backgroundColor: props.button_color,
                color: props.is_dark_color ? 'white' : 'black',
                width: props.phoneRef.current.offsetWidth / 6,
                height: props.phoneRef.current.offsetWidth / 6,
              }}
            >
              <pi.PiCameraLight className='small_icon'></pi.PiCameraLight>
            </div>

            <div
              className='small_phone_circle_1'
              style={{
                backgroundColor: props.button_color,
                color: props.is_dark_color ? 'white' : 'black',
                width: props.phoneRef.current.offsetWidth / 7,
                height: props.phoneRef.current.offsetWidth / 7,
              }}
            >
              <span
                style={{
                  fontSize: props.phoneRef.current.offsetWidth / 18 + 'px',
                }}
              >
                {props.photos}
              </span>
              <span
                style={{
                  marginTop: '-1px',
                  fontSize: props.phoneRef.current.offsetWidth / 30 + 'px',
                }}
              >
                {props.is_en ? 'Left' : 'נותרו'}
              </span>
            </div>
          </footer>
        )}
      </div>
      <input
        type='file'
        ref={bg_img_ref}
        onChange={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSelectCoupon(e);
        }}
        accept='image/*'
        style={{ display: 'none' }}
      />
      <ImageCropper
        imageSrc={img_file}
        setCroppedImage={(image) => cropImage(image)}
        image_change={image_change}
      ></ImageCropper>
      {/* Button to trigger download */}
    </div>
  );
};

export default Small_Phone;
