import React, { useEffect, useRef, useState } from "react";
import "./Logo_page.css";
import BackGround from "../Home_page/BackGround";
const Logo_page = () => {
  const [isHidden, setIsHidden] = useState(false);
  const logoPageRef = useRef(null);

  useEffect(() => {
    const handleAnimationEnd = () => {
      setIsHidden(true);
    };

    const logoPageElement = logoPageRef.current;
    logoPageElement.addEventListener("animationend", handleAnimationEnd);

    return () => {
      logoPageElement.removeEventListener("animationend", handleAnimationEnd);
    };
  }, []);
  return (
    <div>
      {!isHidden ? (
        <div className="logo_page" ref={logoPageRef}>
          <BackGround></BackGround>
          <div className="logo_page_logo_container">
            <span>Moments</span>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Logo_page;
