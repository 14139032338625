let db;

const openDatabase = (name) => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(name, 1); // Open or create a database named 'name'

    request.onupgradeneeded = (event) => {
      db = event.target.result;
      console.log('onupgradeneeded: Database version changed or created.');

      // Create an object store with auto-incremented key
      const objectStore = db.createObjectStore('myObjectStore', {
        autoIncrement: true,
      });

      // Create an index on the 'name' property
      objectStore.createIndex('nameIndex', 'name', { unique: false });
    };

    request.onsuccess = (event) => {
      db = event.target.result;
      console.log('onsuccess: Database opened successfully.');
      resolve(db); // Resolve the promise with the database instance
    };

    request.onerror = (event) => {
      console.error('onerror: Error opening database:', event.target.error);
      reject(event.target.error); // Reject the promise with the error
    };
  });
};

const addData = (data) => {
  const transaction = db.transaction(['myObjectStore'], 'readwrite');
  const objectStore = transaction.objectStore('myObjectStore');

  const request = objectStore.put(data, data.name); // Add data to the object store

  request.onsuccess = () => {
    console.log('Data added successfully');
  };

  request.onerror = (event) => {
    console.error('Error adding data:', event.target.error);
  };
};

const getData = (id) => {
  const transaction = db.transaction(['myObjectStore'], 'readonly');
  const objectStore = transaction.objectStore('myObjectStore');

  const request = objectStore.get(id); // Retrieve data by ID

  request.onsuccess = (event) => {
    console.log('Data retrieved:', event.target.result);
  };

  request.onerror = (event) => {
    console.error('Error retrieving data:', event.target.error);
  };
};

const deleteData = (id) => {
  return new Promise((resolve, reject) => {
    // Ensure db is open
    if (!db) {
      reject('Database is not open');
      return;
    }

    // Start a read-write transaction on the object store
    const transaction = db.transaction(['myObjectStore'], 'readwrite');
    const objectStore = transaction.objectStore('myObjectStore');

    // Delete the item by ID
    const request = objectStore.delete(id);

    request.onsuccess = () => {
      console.log(`Item with ID ${id} deleted successfully`);
      resolve(); // Resolve the promise when deletion is successful
    };

    request.onerror = (event) => {
      console.error('Error deleting data:', event.target.error);
      reject(event.target.error); // Reject the promise with the error
    };
  });
};
const getAllData = () => {
  return new Promise((resolve, reject) => {
    // Ensure db is open
    if (!db) {
      reject('Database is not open');
      return;
    }

    // Start a read-only transaction on the object store
    const transaction = db.transaction(['myObjectStore'], 'readonly');
    const objectStore = transaction.objectStore('myObjectStore');

    // Create an array to hold all the records
    const allRecords = [];

    // Open a cursor to iterate over all records
    const request = objectStore.openCursor();

    request.onsuccess = (event) => {
      const cursor = event.target.result;

      if (cursor) {
        allRecords.push(cursor.value); // Add the current record to the array
        cursor.continue(); // Move to the next record
      } else {
        // No more records
        resolve(allRecords); // Resolve the promise with the array of records
      }
    };

    request.onerror = (event) => {
      console.error('Error retrieving data:', event.target.error);
      reject(event.target.error); // Reject the promise with the error
    };
  });
};

export const addImageToDb = (name, data) => {
  openDatabase(name)
    .then(() => {
      addData(data);
    })
    .catch((error) => {
      console.error('Failed to open database:', error);
    });
};
export const getImageFromDb = (name) => {
  return openDatabase(name)
    .then(() => {
      return getAllData(); // Retrieve all data from the database
    })
    .then((allData) => {
      return allData; // Return the retrieved data
    })
    .catch((error) => {
      console.error('Failed to open database or retrieve data:', error);
      throw error; // Propagate the error
    });
};
export const deleteImageFromDb = (name, id) => {
  openDatabase(name)
    .then(() => {
      deleteData(id);
    })
    .catch((error) => {
      console.error('Failed to open database:', error);
    });
};
//////////////////////////////
///////////////
/////////
///////

export const addImagesToArray = (name, data) => {
  openDatabase(name)
    .then(() => {
      data.map((image) => {
        addData(image);
      });
    })
    .catch((error) => {
      console.error('Failed to open database:', error);
    });
};
