import React, { useEffect, useRef } from "react";
import "./Section_3.css";
import img1 from "../../../images/סרקו_אותי_ארוע.png";
import img2 from "../../../images/img2.jpg";
import phone_event from "../../../images/event_phone.jpg";
import Card from "../Section_1/Card";
import AOS from "aos";
import "aos/dist/aos.css";
const Section_3 = (props) => {
  const aref = useRef();
  useEffect(() => {
    if (window.innerWidth >= 900) {
      AOS.init({
        duration: 1000, // Duration of the animations in milliseconds
      });
    }
  }, []);
  return (
    <div className="section_1" ref={aref}>
      <div className="section_1_home_page">
        {window.innerWidth < 900 ? (
          <header className="section_1_header">
            <span className="section_1_header_span">
              <span style={{ fontWeight: "700" }}>כל</span> האירועים שלכם
            </span>
            <span className="section_1_header_span">
              במקום <span style={{ fontWeight: "700" }}>אחד</span>
            </span>
          </header>
        ) : (
          <header className="section_1_header">
            <span data-aos="fade-left" className="section_1_header_span">
              <span style={{ fontWeight: "700" }}>כל</span> האירועים שלכם
            </span>
            <span
              className="section_1_header_span"
              data-aos-delay="400"
              data-aos="fade-left"
            >
              במקום <span style={{ fontWeight: "700" }}>אחד</span>
            </span>
          </header>
        )}
        <main className="section_1_main">
          {" "}
          <p>
            באמצעות{" "}
            <span
              style={{
                fontFamily: "Montserrat",
                fontSize: "var(--small)",
                fontWeight: "700",
              }}
            >
              Moments
            </span>{" "}
            תוכלו לגשת אל כל האירועים שהייתם בהם בלחיצת כפתור מבלי לחפש בין מאות
            תמונות וקבצים בטלפון שלכם ולחוות כל{" "}
            <span style={{ fontSize: "var(--small)", fontWeight: "700" }}>
              רגע
            </span>{" "}
            מחדש
          </p>
          <p>
            כי מי לא אוהב לצפות באירועים משמחים מהעבר ולהתמלא בנוסטלגיה? הפכו כל
            חוויה לרגע נגיש וזמין בכל עת
          </p>
        </main>
      </div>
    </div>
  );
};

export default Section_3;
