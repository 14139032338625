export const filters = {
  none: "",
  beauty: "contrast(1.1) brightness(1.1) saturate(1.1)",
  inkwell: "sepia(0.3) contrast(1.1) brightness(1.1) grayscale(1)",
  moon: "contrast(1.1) brightness(1.1) grayscale(1)",

  eden: "contrast(0.9) brightness(1.2) hue-rotate(-20deg) saturate(0.85)",
  amero: "contrast(0.9) brightness(1.1) hue-rotate(-10deg) saturate(1.5)",
  brannan: "contrast(1.4) sepia(0.5)",
  clarendon: "contrast(1.2) saturate(1.35)",
  earlyBird: "contrast(0.9) sepia(0.2)",
  gingham: "brightness(1.05) hue-rotate(-10deg)",
  hudson: "contrast(0.9) brightness(1.2) saturate(1.1)",
  lark: "contrast(0.9)",
  lofi: "contrast(1.5) saturate(1.1)",
  mayfair: "contrast(1.1) saturate(1.1)",
  slumber: "saturate(0.66) brightness(1.05)",
  walden: "brightness(1.1) hue-rotate(-10deg) sepia(0.3) saturate(1.6)",
  willow: "contrast(0.95) brightness(0.9) grayscale(0.5)",
};
