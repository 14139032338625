import React, { useCallback, useEffect, useState } from 'react';
import { filters } from '../Camera/filters';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import * as go from 'react-icons/go';
import * as fi from 'react-icons/fi';

import './Gallery_page.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { BeatLoader, ClipLoader } from 'react-spinners';
import { deleteImage, delete_data, delete_event_img } from '../../firebase';
import Checkbox from '@mui/material/Checkbox';
import Spinner from 'react-bootstrap/esm/Spinner';
import { deleteImageFromDb } from '../../IndexDb';
const Image_compoent = ({
  index,
  user,
  photo,
  handle_img_clicked,
  folder,
  event_id,
  filter_image,
  is_download,
  is_selected,
  handle_selected_image,
  is_my,
  isLocal,
  localArray,
  handleRemoveImage,
  handleTryAgain,
  isFirstTime,
}) => {
  const [is_delete, set_is_delete] = useState(false);
  const [is_waiting, set_is_waiting] = useState(false);
  const [is_try_again, set_is_try_again] = useState(false);
  const [isLoading, setIsLoading] = useState(localArray.length > 0);
  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);
  return (
    <div className='gallery_image_cont' key={index}>
      {' '}
      {is_my && !isLocal ? (
        <div className='delete_image'>
          <go.GoTrash
            className='delete_image_icon'
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              set_is_delete(true);
            }}
          ></go.GoTrash>
        </div>
      ) : (
        ''
      )}
      {is_download ? (
        <div className='chech_box_container' style={{}}>
          <Checkbox
            checked={is_selected}

            // set_is_selected(!is_selected);
          />
        </div>
      ) : (
        ''
      )}
      <>
        <LazyLoadImage
          className={'renderd_image'}
          onClick={(e) => {
            e.preventDefault();
            {
              is_download
                ? handle_selected_image(index, !is_selected)
                : handle_img_clicked(index);
            }
          }}
          src={photo.src}
          style={{
            filter: filters[photo.filter],

            width: `calc(${photo.width ? photo.width : 220}px - 25px)`,
            height: `calc(${
              photo.width && photo.height ? photo.height : 300
            }px - 25px)`,
          }}
          effect='blur'
        ></LazyLoadImage>
        {isLocal && localArray.includes(photo.name) && (
          <div
            style={{
              position: 'absolute',
              width: `calc(${photo.width ? photo.width : 220}px - 25px)`,
              height: `calc(${
                photo.width && photo.height ? photo.height : 300
              }px - 25px)`,
              backgroundColor: 'white',
              opacity: 0.7,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ClipLoader color='black' size='40px' />{' '}
          </div>
        )}
        {!isFirstTime ||
          (isLocal && !isLoading && !localArray.includes(photo.name) && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                set_is_try_again(true);
              }}
              style={{
                position: 'absolute',
                width: `calc(${photo.width ? photo.width : 220}px - 25px)`,
                height: `calc(${
                  photo.width && photo.height ? photo.height : 300
                }px - 25px)`,
                backgroundColor: 'white',
                opacity: 0.7,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <fi.FiAlertOctagon
                style={{ color: 'red', fontSize: '50px' }}
              ></fi.FiAlertOctagon>
            </div>
          ))}
      </>
      {is_delete ? (
        <div
          className='delete_image_page'
          onClick={(e) => {
            set_is_delete(false);
            set_is_waiting(false);
          }}
        >
          <section
            className='selete_image_section'
            data-aos='zoom-out'
            data-aos-delay='400'
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <div className='delete_image_box'>
              <go.GoX
                className='close_delete_box'
                onClick={() => {
                  set_is_waiting(false);
                  set_is_delete(false);
                }}
              ></go.GoX>
              <go.GoTrash className='trash_icon'></go.GoTrash>
              <span className='delete_image_box_span'>
                אתם עומדים למחוק תמונה
              </span>
              <p>
                פעולה זו תמחק את התמונה מהגלרייה המשותפת של האירוע ולא תקבלו
                תמונה נוספת להעלות במקומה, האם אתם בטוחים?
              </p>
              <footer className='delete_image_box_footer'>
                <button
                  className='delete_image_box_footer_cancle'
                  onClick={() => {
                    set_is_waiting(false);
                    set_is_delete(false);
                  }}
                >
                  <span>ביטול</span>
                </button>
                <button className='delete_image_box_footer_delete'>
                  {' '}
                  {is_waiting ? (
                    <BeatLoader color='white' size='12' />
                  ) : (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        set_is_waiting(true);
                        deleteImage(folder, photo.name).then((result) => {
                          if (result.success) {
                            delete_event_img(event_id, photo.name, () => {
                              filter_image(photo.name);
                              set_is_waiting(false);
                              set_is_delete(false);
                            });
                          } else {
                            console.error(result.message);
                          }
                        });
                      }}
                    >
                      מחק תמונה
                    </span>
                  )}
                </button>
              </footer>
            </div>
            <img className='delete_image_img' src={photo.src}></img>
          </section>
        </div>
      ) : (
        ''
      )}
      {is_try_again ? (
        <div
          className='delete_image_page'
          onClick={(e) => {
            set_is_try_again(false);
            set_is_waiting(false);
          }}
        >
          <section
            className='selete_image_section'
            data-aos='zoom-out'
            data-aos-delay='400'
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <div className='delete_image_box'>
              <go.GoX
                className='close_delete_box'
                onClick={() => {
                  set_is_waiting(false);
                  set_is_try_again(false);
                }}
              ></go.GoX>
              <fi.FiWifiOff
                className='trash_icon'
                style={{ color: 'var(--blue)' }}
              ></fi.FiWifiOff>
              <span className='delete_image_box_span'>תמונה לא הועלתה </span>
              <p>
                התמונה לא הועלתה כתוצאה מאינטרנט חלש, נסו להעלות את התמונה שנית
                או הסירו אותה
              </p>
              <footer className='delete_image_box_footer'>
                <button
                  className='delete_image_box_footer_cancle'
                  onClick={() => {
                    handleRemoveImage(photo.name);
                    set_is_waiting(false);
                    set_is_try_again(false);
                  }}
                >
                  <span>הסרה</span>
                </button>
                <button
                  className='delete_image_box_footer_delete'
                  style={{ backgroundColor: 'var(--blue)' }}
                >
                  {' '}
                  {is_waiting ? (
                    <BeatLoader color='white' size='12' />
                  ) : (
                    <span
                      style={{ color: 'white' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleTryAgain(photo.name);
                        set_is_try_again(false);
                      }}
                    >
                      העלה תמונה
                    </span>
                  )}
                </button>
              </footer>
            </div>
            <img className='delete_image_img' src={photo.src}></img>
          </section>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Image_compoent;
