import React, { useEffect, useRef, useState } from 'react';
import './Choose_section.css';
import Edit_text from './Edit_text';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { addLocale } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import { combineDates } from '../../../../Util/helpers';
import imageCompression from 'browser-image-compression';

import * as ci from 'react-icons/ci';
const Choose_section = (props) => {
  const color_ref = useRef();
  useEffect(() => {
    AOS.init({
      duration: 800,
      delay: 300,
    });
  }, []);
  const bg_img_ref = useRef(null);

  const arr = ['כל האורחים', 'כל אחד שיש בידו סיסמא', 'רק אני'];
  addLocale('Is', {
    firstDayOfWeek: 1,
    showMonthAfterYear: true,
    dayNames: ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת'],
    dayNamesShort: ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש'],
    dayNamesMin: ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש'],
    monthNames: [
      'ינואר',
      'פברואר',
      'מרץ',
      'אפריל',
      'מאי',
      'יוני',
      'יולי',
      'אוגוסט',
      'ספטמבר',
      'אוקטובר',
      'נובמבר',
      'דצמבר',
    ],
    monthNamesShort: [
      'ינו',
      'פבר',
      'מרץ',
      'אפר',
      'מאי',
      'יונ',
      'יול',
      'אוג',
      'ספט',
      'אוק',
      'נוב',
      'דצמ',
    ],
    today: 'Hoy',
    clear: 'Limpiar',
  });

  return (
    <section className='choising_data_section'>
      <Edit_text
        is_event_name={true}
        text={props.event_name}
        handle_text={props.handle_event_data}
        fonts_array={props.fonts_array}
      ></Edit_text>
      <Edit_text
        is_event_name={false}
        text={props.date}
        handle_text={props.handle_event_data}
        fonts_array={props.fonts_array}
      ></Edit_text>
      <div className='button_config_section'>
        <div className='button_config bg_box'>
          <div
            className='font_color_button'
            style={{ backgroundColor: props.button_color }}
          >
            {' '}
            <input
              ref={color_ref}
              type='color'
              className='color_input'
              style={{
                position: 'absolute',
                opacity: 0,
                bottom: '0',
                left: '-20%',
                zIndex: '0',
                width: '10px',
              }}
              value={props.button_color}
              onChange={(e) => {
                const [r, g, b] = e.target.value
                  .match(/\w\w/g)
                  .map((hex) => parseInt(hex, 16));
                const brightness = (r * 299 + g * 587 + b * 114) / 1000;
                if (brightness < 128) props.set_is_dark_color(true);
                else props.set_is_dark_color(false);
                props.handle_event_data({ button_color: e.target.value });
              }}
            ></input>
          </div>

          <button
            onClick={() => {
              props.handle_event_data({ is_en: true });
            }}
            className={
              props.is_en
                ? 'lan_button bg_box lan_selected'
                : 'lan_button bg_box'
            }
          >
            <span>English</span>
          </button>
          <button
            onClick={() => {
              props.handle_event_data({ is_en: false });
            }}
            className={
              !props.is_en
                ? 'lan_button bg_box lan_selected'
                : 'lan_button bg_box'
            }
          >
            <span>עברית</span>
          </button>
          <span style={{ fontSize: 'var(--xsmall)' }}>עיצוב הכפתורים</span>
        </div>{' '}
        <div
          className='set_bg_img_input bg_box'
          onClick={() => {
            props.set_bg_img();
          }}
        >
          <span> תמונת רקע</span>
          <ci.CiImageOn style={{ fontSize: 'var(--medium)' }}></ci.CiImageOn>
        </div>
      </div>
      <span style={{ fontSize: 'var(--xsmall)' }}>ניתן לערוך לאחר רכישה</span>
    </section>
  );
};

export default Choose_section;
