import React from 'react';
import './Section_2.css';
import Config_event from './Config_data/Config_event';
const Section = (props) => {
  return (
    <section
      className={
        props.page === 2
          ? 'event_section_1_right fade_in'
          : 'event_section_1_right fade_out'
      }
    >
      <Config_event
        config_data={props.config_data}
        eventDate={props.eventDate}
        handle_event_data={props.handle_event_data}
      ></Config_event>
    </section>
  );
};

export default Section;
