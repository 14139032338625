import React, { useEffect, useState } from 'react';
import './Section_4.css';
import { PayPalButton } from 'react-paypal-button-v2';
import * as Iconstet from 'react-icons/fa';
import { add_event, get_data_by_id } from '../../../firebase';

const Section_4 = (props) => {
  const [coupon, set_coupon] = useState('');
  const [coupon_precentage, set_coupon_precentage] = useState(0);
  const [invalid, set_invalid] = useState(false);

  const [scale, setScale] = useState(1);
  useEffect(() => {
    const updateScale = () => {
      // Get the width of the viewport in vw units
      const viewportWidth = window.innerWidth / 700;
      // Update the scale based on the viewport width
      setScale(Math.min(viewportWidth, 1));
    };

    // Initial update
    updateScale();

    // Update the scale on resize
    window.addEventListener('resize', updateScale);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', updateScale);
  }, []);
  const initialOptions = {
    clientId: process.env.REACT_APP_API,
    currency: 'ILS',
    intent: 'capture',
    vault: true,
  };
  // const paypalSubscribe = (data, actions) => {
  //   console.log("ff");
  //   return actions.subscription.create({
  //     plan_id: "P-7YH953059D1248537MYX4VRA", // Replace with your PayPal plan ID
  //   });
  // };

  const onSuccess = (details, data) => {
    // console.log("Payment successful", details, data);
    console.log('success');
    props.handle_add_event(false);
  };

  const onCancel = (data) => {
    // Handle payment cancellation
    console.log('Payment cancelled', data);
  };

  const onError = (err) => {
    // Handle payment error
    console.error('Payment error', err);
  };
  return (
    <section
      className={
        localStorage.getItem('user_id') !== null
          ? props.page === 3
            ? 'event_section_1_left fade_in'
            : 'event_section_1_left fade_out'
          : props.page === 4
          ? 'event_section_1_right fade_in '
          : 'event_section_1_right fade_out '
      }
    >
      <div className='order_summery_page'>
        <main className='event_section_4_main'>
          <section className='order_summary_section '>
            <div className='order_summery_div bg_box'>
              <div>
                <span style={{ opacity: '0' }}>-</span>
                <span style={{ fontSize: 'var(--small)' }}>מה אתם מקבלים</span>
              </div>
              <div>
                <span>-</span>
                <span>
                  אירוע בעיצוב אישי עם גלריית תמונות מהממת שישארו זמינים לצפייה
                  עבורכם לאורך שנה שלמה!
                </span>
              </div>
              <div>
                <span>-</span>
                <span>
                  עד {props.event_data.config_data.guests} אורחים שיוכלו לשתף את
                  הרגעים שלהם מהאירוע
                </span>
              </div>
              <div>
                <span>-</span>
                <span>
                  עד {props.event_data.config_data.photos} תמונות שכל אורח יוכל
                  לשתף
                </span>
              </div>
              <div>
                <span>-</span>
                <span>
                  ישלח אליכם למייל ברקוד האירוע ופוסטר מעוצב עבורכם של האירוע
                  וכל מה שתצטרכו יהיה רק להדפיס אותו{' '}
                </span>
              </div>
            </div>
            <div className='total_price_container bg_box'>
              <span style={{ fontSize: 'var(--small)' }}>עלות</span>
              <span>
                {' '}
                <Iconstet.FaShekelSign
                  style={{
                    fontWeight: 300,
                    fontSize: '11px',
                    marginLeft: '3px',
                  }}
                ></Iconstet.FaShekelSign>
                {props.event_data.config_data.price}
              </span>
            </div>
          </section>
          <section className='coupon_section'>
            <div className='coupon_container bg_box'>
              <input
                className='coupon_input bg_box'
                placeholder='קוד קופון'
                value={coupon}
                onChange={(e) => {
                  set_coupon(e.target.value);
                  set_coupon_precentage(0);
                  props.handle_event_data({
                    discount: 50,
                  });
                }}
              ></input>

              <footer>
                <button
                  onClick={() => {
                    if (coupon === '') {
                      set_invalid(true);
                      return;
                    }
                    get_data_by_id('coupon', coupon, (data) => {
                      if (data) {
                        set_invalid(false);
                        set_coupon_precentage(data.prec);
                        props.handle_event_data({ discount: data.prec });
                        setTimeout(() => {
                          if (data.prec === 100) {
                            props.handle_add_event(true);
                          }
                        }, 500);
                      } else {
                        set_invalid(true);
                        alert('קופון זה שגוי או יצא משימוש, אנא נסו אחר');
                      }
                    });
                  }}
                  className='sign_in_continue_buttton'
                  style={{ fontSize: 'var(--xsmall)' }}
                >
                  הזן קופון
                </button>
              </footer>
            </div>
            <div className='total_price_container bg_box'>
              <span style={{ fontSize: 'var(--small)' }}>הנחה</span>
              <span>
                <span> {coupon_precentage}%</span>
              </span>
            </div>
          </section>
          <section className='payment_section'>
            <div className='paypal_container bg_box '>
              <span>לתשלום</span>
              <div className='paypal_div' style={{ scale: `${scale}` }}>
                <PayPalButton
                  amount={
                    Math.floor(
                      (props.event_data.config_data.price *
                        (100 - coupon_precentage)) /
                        100
                    ) + '.00'
                  }
                  // createSubscription={(data, details) => paypalSubscribe(data, details)}
                  createOrder={(data, actions) => {
                    return actions.order.create({
                      purchase_units: [
                        {
                          amount: {
                            value:
                              Math.floor(
                                (props.event_data.config_data.price *
                                  (100 - coupon_precentage)) /
                                  100
                              ) + '.00',
                          },
                        },
                      ],
                      application_context: {
                        shipping_preference: 'NO_SHIPPING', // This hides the shipping address section
                      },
                    });
                  }}
                  onSuccess={onSuccess}
                  onCancel={onCancel}
                  onError={onError}
                  options={initialOptions}
                  style={{
                    shape: 'pill',
                    color: 'blue',
                    layout: 'vertical',
                  }}
                />
              </div>
            </div>
            <div className='total_price_container bg_box'>
              <span style={{ fontSize: 'var(--small)' }}>עלות סופית</span>
              <span>
                {' '}
                <Iconstet.FaShekelSign
                  style={{
                    fontWeight: 300,
                    fontSize: '11px',
                    marginLeft: '3px',
                  }}
                ></Iconstet.FaShekelSign>
                {Math.floor(
                  (props.event_data.config_data.price *
                    (100 - coupon_precentage)) /
                    100
                )}
              </span>
              <span style={{ fontSize: 'var(--xsmall)' }}>כולל מע"מ</span>
            </div>
          </section>
        </main>
      </div>
    </section>
  );
};

export default Section_4;
