import React, { useEffect, useRef, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { Text, OrbitControls, Float } from '@react-three/drei';
import { Phone } from './phone';
import { Iphone } from './Iphone';
import Aos from 'aos';
import 'aos/dist/aos.css';

const Scene = (props) => {
  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);
  return (
    <div
      className='phone_card_container'
      data-aos='fade-out'
      data-aos-delay='400'
    >
      <Canvas>
        {props.unenable_controls ? (
          ''
        ) : (
          <OrbitControls
            enableZoom={false}
            // minPolarAngle={Math.PI / 4} // Minimum vertical angle (in radians)
            // maxPolarAngle={(1 * Math.PI) / 4} // Maximum vertical angle (in radians)
            minPolarAngle={Math.PI / 2 - 0.1}
            maxPolarAngle={Math.PI / 2 + 0.1}
          />
        )}

        <ambientLight intensity={5} />
        <directionalLight intensity={5} position={[0, 0, 5]} color={'white'} />
        <directionalLight
          intensity={10}
          position={[0, 0, -5]}
          color={'white'}
        />
        <Iphone
          is_small_screen={props.is_small_screen}
          url1={props.url1}
          url2={props.url2}
          url3={props.url3}
          url4={props.url4}
          url5={props.url5}
          url6={props.url6}
          aref={props.aref}
          position={props.is_small_screen ? [1, -0.6, -2] : [0, -0.5, 0]}
          rotation={props.is_small_screen ? [0, 6, 0] : [0, 0.2, 0]}
          how_it_works={props.how_it_works}
          old_section={props.old_section}
          canvasRef={props.canvasRef}
          set_texture={(index) => {}}
        ></Iphone>
      </Canvas>
    </div>
  );
};

export default Scene;
