import React, { useEffect, useLayoutEffect, useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import {
  Float,
  OrbitControls,
  ScrollControls,
  useScroll,
  useTexture,
} from "@react-three/drei";
import { motion } from "framer-motion";
import { ScrollTrigger } from "gsap/ScrollTrigger"; // Import ScrollTrigger
import AOS from "aos";
import "aos/dist/aos.css";
import gsap from "gsap";
import BackGround from "../BackGround";
gsap.registerPlugin(ScrollTrigger);

const Card = (props) => {
  return (
    <div className="card_page">
      <Canvas>
        <ambientLight intensity={4} />
        <directionalLight position={[0, 0, 10]} intensity={10} />
        {/* {props.unenable_controls ? "" : <OrbitControls enableZoom={false} />} */}
        {/* <ScrollControls pages={2} damping={0.25}></ScrollControls> */}
        <CardMesh
          aref={props.aref}
          index={props.index}
          url={props.url}
          position={props.position}
          rotation={props.rotation}
          args={props.args}
        />
      </Canvas>
    </div>
  );
};

const CardMesh = (props) => {
  const texture = useTexture(props.url);
  const ref = useRef();
  useFrame((state, delta) => {
    if (props.index === 2) {
    }
    if (props.index === 1) {
      {
        ref.current.position.z =
          props.position[2] + Math.sin(state.clock.elapsedTime / 1) / 10;
      }
    }
    if (props.index === 0) {
      {
        ref.current.rotation.z =
          props.rotation[2] + Math.sin(state.clock.elapsedTime / 1) / 30;
      }
    }
  });
  useLayoutEffect(() => {
    const param = 2;
    if (props.index === 2) {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: props.aref.current,
          start: "top center",
          end: "bottom center",
          scrub: true,
          onUpdate: (self) => {
            const scrollProgress = self.progress;
            let start;
            let end;
            if (scrollProgress < 0.2 / param) {
              const rotationRight = gsap.utils.mapRange(
                0.15 / param,
                0.2 / param,
                -20.5,
                10,
                scrollProgress
              );
              ref.current.position.x = rotationRight;
              const large = gsap.utils.mapRange(
                0.15 / param,
                0.2 / param,
                -50,
                -20,
                scrollProgress
              );
              ref.current.position.z = large;
              const spin = gsap.utils.mapRange(
                0.15 / param,
                0.2 / param,
                0,
                -2,
                scrollProgress
              );
              ref.current.rotation.y = spin;
            } else if (scrollProgress < 0.45 / param) {
              const rotationRight = gsap.utils.mapRange(
                0.2 / param,
                0.45 / param,
                10,
                1,
                scrollProgress
              );
              ref.current.position.x = rotationRight;
              const spin = gsap.utils.mapRange(
                0.2 / param,
                0.45 / param,
                -2,
                -4,
                scrollProgress
              );
              ref.current.rotation.y = spin;
              const large = gsap.utils.mapRange(
                0.2 / param,
                0.45 / param,
                -20,
                3.5,
                scrollProgress
              );
              ref.current.position.z = large;
              const move = gsap.utils.mapRange(
                0.2 / param,
                0.45 / param,
                5,
                -0.5,
                scrollProgress
              );
              ref.current.position.y = move;
            } else if (scrollProgress < 0.6 / param) {
              const rotationRight = gsap.utils.mapRange(
                0.45 / param,
                0.6 / param,
                1,
                -14,
                scrollProgress
              );
              ref.current.position.x = rotationRight;
              const spin = gsap.utils.mapRange(
                0.45 / param,
                0.6 / param,
                -4,
                -6.2,
                scrollProgress
              );
              ref.current.rotation.y = spin;
              const large = gsap.utils.mapRange(
                0.45 / param,
                0.6 / param,
                3.5,
                -8,
                scrollProgress
              );
              ref.current.position.z = large;

              const move = gsap.utils.mapRange(
                0.45 / param,
                0.6 / param,
                -0.5,
                0,
                scrollProgress
              );
              ref.current.position.y = move;
            } else if (
              scrollProgress > 0.8 / param &&
              scrollProgress < 1.05 / param
            ) {
              start = 0.8 / param;
              end = 1.05 / param;
              const rotationRight = gsap.utils.mapRange(
                start,
                end,
                -14,
                1,
                scrollProgress
              );
              ref.current.position.x = rotationRight;
              const spin = gsap.utils.mapRange(
                start,
                end,
                -6.2,
                -3,
                scrollProgress
              );
              ref.current.rotation.y = spin;
              const large = gsap.utils.mapRange(
                start,
                end,
                -8,
                3.5,
                scrollProgress
              );
              ref.current.position.z = large;

              const move = gsap.utils.mapRange(
                start,
                end,
                0,
                -0.5,
                scrollProgress
              );
              ref.current.position.y = move;
            } else if (
              scrollProgress > 0.6 / param &&
              scrollProgress < 0.8 / param
            ) {
            } else if (scrollProgress < 1.2 / param) {
              start = 1.05 / param;
              end = 1.2 / param;
              const rotationRight = gsap.utils.mapRange(
                start,
                end,
                1,
                16.5,
                scrollProgress
              );
              ref.current.position.x = rotationRight;
              const spin = gsap.utils.mapRange(
                start,
                end,
                -3,
                0.1,
                scrollProgress
              );
              ref.current.rotation.y = spin;
              const large = gsap.utils.mapRange(
                start,
                end,
                3.5,
                -8,
                scrollProgress
              );
              ref.current.position.z = large;
              const move = gsap.utils.mapRange(
                start,
                end,
                -0.5,
                -4.2,
                scrollProgress
              );
              ref.current.position.y = move;
              const turn = gsap.utils.mapRange(
                start,
                end,
                0,
                0.1,
                scrollProgress
              );
              ref.current.rotation.z = turn;
            } else if (
              scrollProgress > 1.2 / param &&
              scrollProgress < 1.35 / param
            ) {
            } else if (scrollProgress < 1.5 / param) {
              start = 1.35 / param;
              end = 1.5 / param;
              const rotationRight = gsap.utils.mapRange(
                start,
                end,
                16.5,
                1,
                scrollProgress
              );
              ref.current.position.x = rotationRight;
              const spin = gsap.utils.mapRange(
                start,
                end,
                0.1,
                -2,

                scrollProgress
              );
              ref.current.rotation.y = spin;
              const large = gsap.utils.mapRange(
                start,
                end,
                -8,
                3.5,

                scrollProgress
              );
              ref.current.position.z = large;
              const move = gsap.utils.mapRange(
                start,
                end,
                -2,
                -0.5,

                scrollProgress
              );
              ref.current.position.y = move;
              const turn = gsap.utils.mapRange(
                start,
                end,
                -0.1,
                0,

                scrollProgress
              );
              ref.current.rotation.z = turn;
            } else if (
              scrollProgress > 1.5 / param &&
              scrollProgress < 2 / param
            ) {
              start = 1.5 / param;
              end = 2 / param;
              const rotationRight = gsap.utils.mapRange(
                start,
                end,
                1,
                -8,

                scrollProgress
              );
              ref.current.position.x = rotationRight;
              const spin = gsap.utils.mapRange(
                start,
                end,
                -3,
                -6.2,

                scrollProgress
              );
              ref.current.rotation.y = spin;
              const large = gsap.utils.mapRange(
                start,
                end,
                3.5,
                -7,

                scrollProgress
              );
              ref.current.position.z = large;

              const move = gsap.utils.mapRange(
                start,
                end,
                -0.5,
                -2,

                scrollProgress
              );
              ref.current.position.y = move;
            }
          },
        },
      });

      return () => {
        tl.kill();
      };
    }
  }, [props.aref]);
  return (
    <group ref={ref} position={props.position} rotation={props.rotation}>
      <mesh>
        <boxGeometry args={props.args ? props.args : [4, 4, 0.02]} />
        <meshStandardMaterial />
      </mesh>
      <mesh position={[0, 0, 0.02]}>
        <boxGeometry
          attach="geometry"
          args={
            props.args
              ? [props.args[0] - 0.3, props.args[1] - 0.3, 0]
              : [3.7, 3.7, 0]
          }
        />
        <meshBasicMaterial map={texture} />
      </mesh>
    </group>
  );
};

export default Card;
