import React, { useRef, useEffect, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { motion } from "framer-motion";

const Jox = motion(
  React.forwardRef((props, ref) => (
    <mesh {...props} ref={ref}>
      <boxGeometry args={[1, 1, 1]} />
      <meshNormalMaterial color="blue" />
    </mesh>
  ))
);
const Box = () => {
  const boxRef = useRef();
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolling(true);
      setTimeout(() => {
        setIsScrolling(false);
      }, 300); // Adjust the duration as needed
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useFrame(({ clock }) => {
    const time = clock.getElapsedTime();
    if (boxRef.current && isScrolling) {
      boxRef.current.position.y += clock.elapsedTime / 100;
    }
  });

  return (
    <Jox
      ref={boxRef}
      position={[0, 0, 0]}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      animate={{ position: [0, 0, -3], scale: 2 }}
    />
  );
};

export default Box;
