import React, { useEffect, useRef, useState } from 'react';
import './Event.css';
import blank_iphone from '../../../images/phone.png';
import * as Iconstet from 'react-icons/fa';
import * as sl from 'react-icons/sl';
import * as gr from 'react-icons/gr';
import * as hi2 from 'react-icons/hi2';
import QRCode from 'react-qr-code';
import { RWebShare } from 'react-web-share';
import { useHistory } from 'react-router-dom';
import Aos from 'aos';
import * as ci from 'react-icons/ci';
import * as pi from 'react-icons/pi';
import * as go from 'react-icons/go';
import * as io from 'react-icons/io';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'aos/dist/aos.css';
import { BeatLoader } from 'react-spinners';
import { deleteEvent } from '../../../firebase';
import { calculateScaledFontSize, lastCodeModify } from '../../../Util/helpers';
const Event = ({
  user_id,
  id,
  button_color,
  config_data,
  event_name,
  date,
  img_url,
  my_events,
  lastModify,
  filter_event,
}) => {
  const [is_delete, set_is_delete] = useState(false);
  const [is_waiting, set_is_waiting] = useState(false);
  const [is_remove, set_is_remove] = useState(false);
  useEffect(() => {
    Aos.init({ duration: 800 });
  }, []);

  const iphone_ref = useRef();
  const history = useHistory();
  const [barcode_clicked, set_barcode_clicked] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [headerwidth, setHeaderwidth] = useState(0);

  useEffect(() => {
    const updateHeaderHeight = () => {
      if (iphone_ref.current) {
        setHeaderHeight(iphone_ref.current.offsetHeight - 1);
        setHeaderwidth(iphone_ref.current.offsetWidth - 1);
      }
    };
    // Set initial height
    updateHeaderHeight();

    // Update height on window resize
    window.addEventListener('resize', updateHeaderHeight);

    return () => {
      window.removeEventListener('resize', updateHeaderHeight);
    };
  }, [iphone_ref]);
  return (
    <div className='eventContainer'>
      <div className='event_object'>
        <section className='event_iphone_section'>
          <div
            className='event_image_section'
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              history.push({
                pathname: `/event/${id}`,
              });
            }}
          >
            <div
              ref={iphone_ref}
              className='event_phone_container'
              style={{ backgroundImage: `url(${blank_iphone})` }}
            >
              <img
                className='event_bg_image_phone'
                alt='Gallery Phone'
                src={img_url}
                style={{
                  height: headerHeight,
                  width: headerwidth,
                }}
              ></img>
              <main className='event_span_container'>
                <div
                  className={`event_obj_name_container ${event_name.font.name}`}
                  style={{
                    top: event_name.y < 50 ? '' : `${100 - event_name.y}%`,
                    bottom: event_name.y >= 50 ? '' : `${event_name.y}%`,
                    color: event_name.font_color,
                    backgroundColor:
                      event_name.font_bg_color + event_name.opacity,
                  }}
                >
                  <span
                    className='event_text_span'
                    style={{
                      // fontSize: `clamp(${(event_name.font_size / 10) * 5}px,${
                      //   (event_name.font_size / 10) * 2.2
                      // }vw,${(event_name.font_size / 10) * 12}px)`,
                      fontSize: calculateScaledFontSize(
                        event_name.font_size,
                        window.innerWidth,
                        window.innerHeight,
                        Math.min(100, window.innerWidth * 0.18),
                        Math.min(205, window.innerWidth * 0.38)
                      ),
                    }}
                  >
                    {event_name.name}
                  </span>
                </div>
                <div
                  className={`event_obj_name_container ${date.font.name}`}
                  style={{
                    top:
                      lastModify && lastModify > lastCodeModify
                        ? `${
                            100 -
                            date.y +
                            (date.y > 50 ? event_name.font_size / 4 : 0)
                          }%`
                        : date.y < 50
                        ? ''
                        : `${100 - date.y}%`,

                    bottom:
                      !(lastModify && lastModify > lastCodeModify) &&
                      date.y >= 50
                        ? ''
                        : `${date.y}%`,
                    color: date.font_color,
                    backgroundColor: date.font_bg_color + date.opacity,
                  }}
                >
                  <span
                    className='event_text_span'
                    style={{
                      fontSize: calculateScaledFontSize(
                        date.font_size,
                        window.innerWidth,
                        window.innerHeight,
                        Math.min(100, window.innerWidth * 0.18),
                        Math.min(205, window.innerWidth * 0.38)
                      ),
                    }}
                  >
                    {date.name}
                  </span>
                </div>
              </main>
              <footer className='event_phone_container_footer'>
                <div
                  className='event_circle_3'
                  style={{ backgroundColor: button_color }}
                >
                  <ci.CiImageOn></ci.CiImageOn>
                </div>
                <div
                  className='event_circle_2'
                  style={{ backgroundColor: button_color }}
                >
                  <pi.PiCameraLight></pi.PiCameraLight>
                </div>

                <div
                  className='event_circle_1'
                  style={{
                    backgroundColor: button_color,
                  }}
                >
                  <span>{config_data.photos}</span>
                  <span>Left</span>
                </div>
              </footer>
            </div>
          </div>
        </section>
        <section className='event_data_sectoin'>
          <header className='event_data_sectoin_header'>
            <span
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                history.push({
                  pathname: `/event/${id}`,
                });
              }}
              className={`${event_name.font.name}`}
            >
              {event_name.name}
            </span>
            <div
              className='event_data_sectoin_footer_icon'
              style={{ backgroundColor: 'transparent', color: 'red' }}
              onClick={(e) => {
                e.stopPropagation();
                set_is_remove(true);
              }}
            >
              <io.IoMdCloseCircleOutline></io.IoMdCloseCircleOutline>
            </div>
          </header>
          <main
            className='event_data_sectoin_main'
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              history.push({
                pathname: `/event/${id}`,
              });
            }}
          >
            <span className={`${date.font.name}`}>{date.name}</span>
          </main>
          <footer className='event_data_sectoin_footer'>
            {my_events ? (
              <div
                className='event_data_sectoin_footer_icon'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  history.push({
                    pathname: `/Edit_event`,
                    state: { user_id: user_id, event_id: id },
                  });
                }}
                // onClick={(e) => {
                //   e.preventDefault();
                //   e.stopPropagation();
                //   history.push({
                //     pathname: `/eventFiles/${id}`,
                //   });
                // }}
              >
                <sl.SlPencil></sl.SlPencil>
              </div>
            ) : (
              ''
            )}
            {!my_events && (
              <div
                className='event_data_sectoin_footer_icon'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  history.push({
                    pathname: `/gallery/${id}`,
                  });
                }}
              >
                <gr.GrGallery></gr.GrGallery>
              </div>
            )}
            {!my_events && (
              <div className='event_data_sectoin_footer_icon'>
                <RWebShare
                  data={{
                    text: 'שתף את החוויה האישית שלך מהאירוע',
                    url: `https://our-moments.web.app/event//${id}`,
                    title: 'Moments',
                  }}
                >
                  <hi2.HiOutlineShare></hi2.HiOutlineShare>
                </RWebShare>
              </div>
            )}
            {my_events ? (
              <div
                onClick={() => {
                  set_is_delete(true);
                }}
                className='event_data_sectoin_footer_icon'
                style={{ color: 'red' }}
              >
                <go.GoTrash></go.GoTrash>
              </div>
            ) : (
              ''
            )}
            {my_events && (
              <div>
                <button
                  onClick={() => {
                    history.push({
                      pathname: `/eventFiles/${id}`,
                      state: { user_id: user_id },
                    });
                  }}
                  className='files_page_button'
                >
                  {' '}
                  עיצובים
                </button>
              </div>
            )}
          </footer>
        </section>
        <section
          className='barcode_section'
          onClick={() => {
            set_barcode_clicked(true);
          }}
        >
          {' '}
          <QRCode
            value={`https://our-moments.web.app/event/${id}`}
            bgColor='#FFFFFF00'
            style={{ width: '75%', height: '75%' }}
            // value={`https://our-moments.web.app/event/${location.state.user_id}/${event.id}`}
          ></QRCode>
        </section>
        {barcode_clicked ? (
          <div
            className='barcode_page'
            onClick={() => {
              set_barcode_clicked(false);
            }}
          >
            <div
              data-aos='zoom-out'
              data-aos-delay='400'
              className='barcode_container'
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <QRCode
                style={{
                  width: '45vw',
                  height: '45vw',
                  maxHeight: '45vh',
                  maxWidth: '45vh',
                }}
                value={`https://our-moments.web.app/event/${id}`}
                bgColor='#FFFFFF00'

                // value={`https://our-moments.web.app/event/${location.state.user_id}/${event.id}`}
              ></QRCode>
              <RWebShare
                data={{
                  text: `שתפו את ה"רגעים" שלכם מהאירוע`,
                  url: `https://our-moments.web.app/event/${id}`,
                  title: 'Moments',
                }}
              >
                <div className='share_barcode_container'>
                  <span>SHARE</span> <hi2.HiOutlineShare></hi2.HiOutlineShare>
                </div>
              </RWebShare>
            </div>
          </div>
        ) : (
          ''
        )}
        {is_delete ? (
          <div
            className='delete_image_page'
            onClick={(e) => {
              set_is_delete(false);
              set_is_waiting(false);
            }}
          >
            <section
              className='selete_image_section'
              data-aos='zoom-out'
              data-aos-delay='400'
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <div
                className='delete_image_box'
                style={{ borderRadius: '10px' }}
              >
                <go.GoX
                  className='close_delete_box'
                  onClick={() => {
                    set_is_waiting(false);
                    set_is_delete(false);
                  }}
                ></go.GoX>
                <go.GoTrash className='trash_icon'></go.GoTrash>
                <span className='delete_image_box_span'>
                  אתם עומדים למחוק אירוע
                </span>
                <p>
                  פעולה זו תמחק את האירוע ואת כל הגלרייה שלו, האם אתם בטוחים?
                </p>
                <footer className='delete_image_box_footer'>
                  <button
                    className='delete_image_box_footer_cancle'
                    onClick={() => {
                      set_is_waiting(false);
                      set_is_delete(false);
                    }}
                  >
                    <span>ביטול</span>
                  </button>
                  <button className='delete_image_box_footer_delete'>
                    {' '}
                    {is_waiting ? (
                      <BeatLoader color='white' size='12' />
                    ) : (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          set_is_waiting(true);
                          deleteEvent(id, img_url).then((result) => {
                            filter_event(id);

                            set_is_waiting(false);
                            set_is_delete(false);
                          });
                        }}
                      >
                        מחק אירוע
                      </span>
                    )}
                  </button>
                </footer>
              </div>
            </section>
          </div>
        ) : (
          ''
        )}
        {is_remove ? (
          <div
            className='delete_image_page'
            onClick={(e) => {
              set_is_remove(false);
              set_is_waiting(false);
            }}
          >
            <section
              className='selete_image_section'
              data-aos='zoom-out'
              data-aos-delay='400'
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <div
                className='delete_image_box'
                style={{ borderRadius: '10px' }}
              >
                <go.GoX
                  className='close_delete_box'
                  onClick={() => {
                    set_is_waiting(false);
                    set_is_remove(false);
                  }}
                ></go.GoX>
                <go.GoTrash className='trash_icon'></go.GoTrash>
                <span className='delete_image_box_span'>
                  אתם עומדים להסיר אירוע
                </span>
                <p>
                  פעולה זו תסיר את האירוע מהאזור האישי שלכם, האם אתם בטוחים?
                </p>
                <footer className='delete_image_box_footer'>
                  <button
                    className='delete_image_box_footer_cancle'
                    onClick={() => {
                      set_is_waiting(false);
                      set_is_remove(false);
                    }}
                  >
                    <span>ביטול</span>
                  </button>
                  <button className='delete_image_box_footer_delete'>
                    {' '}
                    {is_waiting ? (
                      <BeatLoader color='white' size='12' />
                    ) : (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          set_is_waiting(true);
                          localStorage.removeItem(`${id}_left`);
                          localStorage.removeItem(`${id}_user`);

                          filter_event(id);

                          set_is_waiting(false);
                          set_is_remove(false);
                        }}
                      >
                        הסר אירוע
                      </span>
                    )}
                  </button>
                </footer>
              </div>
            </section>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default Event;
