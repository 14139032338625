import React from 'react';
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
} from 'react-scroll';
import './Nav_bar.css';
const Left_bar = (props) => {
  return (
    <div className='left_bar_container'>
      <Link
        to='section_1'
        smooth={true}
        duration={Math.abs(props.section_index - 1) * 1500}
        // activeClass="active"
        offset={-50}
        delay={500}
        spy={true}
      >
        <button
          onClick={() => {
            props.set_section_index(1);
          }}
          className={
            props.section_index === 1
              ? 'left_bar_section_button active'
              : 'left_bar_section_button'
          }
        >
          <div className='left_bar_section_button_cntainer'>
            <div
              style={{
                transitionDelay: Math.abs(props.section_index - 1) * 1.2,
              }}
              className={props.section_index === 1 ? 'active_ball' : ''}
            ></div>
          </div>
        </button>
      </Link>
      <Link
        to='section_2'
        smooth={true}
        duration={Math.abs(props.section_index - 2) * 1500}
        // activeClass="active"
        spy={true}
        offset={-50}
        delay={500}
      >
        <button
          onClick={() => {
            props.set_section_index(2);
          }}
          className={
            props.section_index === 2
              ? 'left_bar_section_button active'
              : 'left_bar_section_button'
          }
        >
          {' '}
          <div className='left_bar_section_button_cntainer'>
            <div
              style={{
                transitionDelay: Math.abs(props.section_index - 2) * 1.2,
              }}
              className={props.section_index === 2 ? 'active_ball' : ''}
            ></div>
          </div>
        </button>{' '}
      </Link>
      <Link
        to='section_3'
        smooth={true}
        duration={Math.abs(props.section_index - 3) * 1500}
        // activeClass="active"
        spy={true}
        offset={-50}
        delay={500}
      >
        <button
          onClick={() => {
            props.set_section_index(3);
          }}
          className={
            props.section_index === 3
              ? 'left_bar_section_button active'
              : 'left_bar_section_button'
          }
        >
          {' '}
          <div className='left_bar_section_button_cntainer'>
            <div
              style={{
                transitionDelay: Math.abs(props.section_index - 3) * 1.2,
              }}
              className={props.section_index === 3 ? 'active_ball' : ''}
            ></div>
          </div>
        </button>{' '}
      </Link>
      <Link
        to='section_4'
        smooth={true}
        duration={Math.abs(props.section_index - 4) * 1500}
        // activeClass="active"
        spy={true}
        offset={-50}
        delay={500}
      >
        <button
          onClick={() => {
            props.set_section_index(4);
          }}
          className={
            props.section_index === 4
              ? 'left_bar_section_button active'
              : 'left_bar_section_button'
          }
        >
          {' '}
          <div className='left_bar_section_button_cntainer'>
            <div
              style={{
                transitionDelay: `${Math.abs(props.section_index - 4 * 1.2)}s`,
              }}
              className={props.section_index === 4 ? 'active_ball' : ''}
            ></div>
          </div>
        </button>{' '}
      </Link>
    </div>
  );
};

export default Left_bar;
